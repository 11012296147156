import styled from 'styled-components'
import Button from './Button'
import { BaseButtonProps, PolymorphicComponent } from './types'

const IconButton: PolymorphicComponent<BaseButtonProps, 'button'> = styled(Button)<BaseButtonProps & any>`
  padding: 0;
  width: ${({ scale }) => (scale === 'sm' ? '32px' : '48px')};
  -webkit-tap-highlight-color: transparent;
  pointer-events: ${({ disabled }) => {
    if (disabled) {
      return 'none'
    }
    return 'default'
  }};
`

export default IconButton
