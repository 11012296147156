import React from 'react'
import styled from 'styled-components'
import { ChevronRightIcon, Button as UIKitButton, AutoRenewIcon, Box, Flex } from 'uikit'
import { useTranslation } from 'react-i18next'
import { AnimationButton } from './Button'

export enum ButtonArrangement {
  ROW = 'row',
  SEQUENTIAL = 'sequential',
}

interface ApproveConfirmButtonsProps {
  isApproveDisabled: boolean
  isApproving: boolean
  isConfirming: boolean
  isConfirmDisabled: boolean
  onApprove: () => void
  onConfirm: () => void
  buttonArrangement?: ButtonArrangement
  confirmLabel?: string
  confirmId?: string
  width?: string
}

const StyledApproveConfirmButtonRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 32px;
    margin-bottom: 0px;
  }
`

const Button = styled(UIKitButton)`
  width: 140px;
  border-radius: 24px;
  ${({ theme }) => theme.mediaQueries.md} {
    min-width: 140px;
  }
`

const iconAttrs = { width: '24px', color: 'textDisabled' }

const ChevronRight = styled(ChevronRightIcon).attrs(iconAttrs)`
  display: block;

  ${({ theme }) => theme.mediaQueries.md} {
    display: block;
  }
`

const spinnerIcon = <AutoRenewIcon spin color="currentColor" />

const ApproveConfirmButtons: React.FC<ApproveConfirmButtonsProps> = ({
  isApproveDisabled,
  isApproving,
  isConfirming,
  isConfirmDisabled,
  onApprove,
  onConfirm,
  buttonArrangement = ButtonArrangement.ROW,
  confirmLabel,
  confirmId,
  width,
}) => {
  const { t } = useTranslation()
  const confirmButtonText = confirmLabel ?? t('COMPONENTS_166')

  const ApproveConfirmRow = () => {
    return (
      <StyledApproveConfirmButtonRow>
        <AnimationButton
          style={{
            color: '#fff',
            height: '40px',
            background: isApproveDisabled ? '#C9C9C9' : 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
            width: width ?? '',
          }}
          disabled={isApproveDisabled}
          onClick={onApprove}
          endIcon={isApproving ? spinnerIcon : undefined}
          isLoading={isApproving}
        >
          {t('COMPONENTS_155')}
        </AnimationButton>
        <Flex justifyContent="center">
          <ChevronRight />
          {/* <ChevronBottom /> */}
        </Flex>
        <AnimationButton
          style={{
            color: '#fff',
            height: '40px',
            background: isConfirmDisabled ? '#C9C9C9' : 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
            width: width ?? '',
          }}
          id={confirmId}
          onClick={onConfirm}
          disabled={isConfirmDisabled}
          isLoading={isConfirming}
          endIcon={isConfirming ? spinnerIcon : undefined}
        >
          {isConfirming ? t('COMPONENTS_156') : confirmButtonText}
        </AnimationButton>
      </StyledApproveConfirmButtonRow>
    )
  }

  const ApproveConfirmSequential = () => {
    return (
      <>
        {isApproveDisabled ? (
          <Box>
            <Button
              id={confirmId}
              onClick={onConfirm}
              disabled={isConfirmDisabled}
              isLoading={isConfirming}
              endIcon={isConfirming ? spinnerIcon : undefined}
            >
              {confirmButtonText}
            </Button>
          </Box>
        ) : (
          <Box>
            <Button onClick={onApprove} endIcon={isApproving ? spinnerIcon : undefined} isLoading={isApproving}>
              {t('COMPONENTS_155')}
            </Button>
          </Box>
        )}
      </>
    )
  }

  return buttonArrangement === ButtonArrangement.ROW ? ApproveConfirmRow() : ApproveConfirmSequential()
}

export default ApproveConfirmButtons
