import React from 'react'
import styled from 'styled-components'
import { Image } from 'components'

interface ItemProps {
  account: string
  connectWallet?: () => void
  presentInvite?: () => void
}

const Imgs = {
  people: require('assets/images/referral/people.svg').default,
}

const InviteBtn = styled.div`
  max-width: 310px;
  height: 48px;
  background: #ffc5301a;
  border-radius: 40px;
  padding: 0 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  color: #ffc530;
  cursor: pointer;
  @media (max-width: 768px) {
    padding: 0 15px;
  }
`

const Index: React.FC<ItemProps> = ({ account, connectWallet, presentInvite }) => {
  return (
    <InviteBtn
      onClick={() => {
        if (!account) {
          connectWallet()
        } else {
          presentInvite()
        }
      }}
    >
      <Image src={Imgs.people} style={{ marginRight: '13px' }} />
      Invite to earn more points
    </InviteBtn>
  )
}

export default Index
