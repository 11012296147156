import React, { useMemo } from 'react'
import { ETHER, Pair } from 'shardex-sdk'
import styled from 'styled-components'
import { CardBody, Text, Image } from '../../uikit'
import Question from 'components/QuestionHelper'
import FullPositionCard from 'components/PositionCard'
import { useTokenBalancesWithLoadingIndicator } from 'state/wallet/hooks'
import { LightCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import Container from 'components/Container'

import { useActiveWeb3React } from 'hooks'
import { usePairs } from 'data/Reserves'
import { toV2LiquidityToken, useTrackedTokenPairs } from 'state/user/hooks'
import { Dots } from 'components/swap/styleds'
import useI18n from 'hooks/useI18n'
import PageHeader from 'components/PageHeader'
import { Link, useHistory } from 'react-router-dom'
import AppBody from '../AppBody'
import NoLiquidity from './NoLiquidity'
import Row from 'components/Row'
import Meta from 'components/Meta'
import SelectTab from 'components/PageHeader/SelectTab'
import { AnimationButton } from 'components/Button'

import { useTranslation } from 'react-i18next'
import useTheme from 'hooks/useTheme'
import { isMobile } from 'react-device-detect'

const LinkText = styled(Text)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

export default function Pool() {
  const { theme } = useTheme()
  const history = useHistory()
  const { account, chainId } = useActiveWeb3React()
  const TranslateString = useI18n()
  const { t } = useTranslation()

  // fetch the user's balances of all tracked V2 LP tokens
  const trackedTokenPairs = useTrackedTokenPairs()
  const tokenPairsWithLiquidityTokens = useMemo(
    () => trackedTokenPairs.map((tokens) => ({ liquidityToken: toV2LiquidityToken(tokens, chainId as any), tokens })),
    [trackedTokenPairs, chainId]
  )
  const liquidityTokens = useMemo(
    () => tokenPairsWithLiquidityTokens.map((tpwlt) => tpwlt.liquidityToken),
    [tokenPairsWithLiquidityTokens]
  )
  const [v2PairsBalances, fetchingV2PairBalances] = useTokenBalancesWithLoadingIndicator(
    account ?? undefined,
    liquidityTokens
  )

  // fetch the reserves for all V2 pools in which the user has a balance
  const liquidityTokensWithBalances = useMemo(
    () =>
      tokenPairsWithLiquidityTokens.filter(({ liquidityToken }) =>
        v2PairsBalances[liquidityToken.address]?.greaterThan('0')
      ),
    [tokenPairsWithLiquidityTokens, v2PairsBalances]
  )

  const v2Pairs = usePairs(liquidityTokensWithBalances.map(({ tokens }) => tokens))
  const v2IsLoading =
    fetchingV2PairBalances || v2Pairs?.length < liquidityTokensWithBalances.length || v2Pairs?.some((V2Pair) => !V2Pair)

  const allV2PairsWithLiquidity = v2Pairs.map(([, pair]) => pair).filter((v2Pair): v2Pair is Pair => Boolean(v2Pair))

  return (
    <Container>
      <Meta title="Liquidity | Shardex" />
      {/* <CardNav activeIndex={1} /> */}
      <Text
        color="white"
        textAlign="center"
        fontSize={isMobile ? '24px' : '32px'}
        bold
        mt={isMobile ? '80px' : '128px'}
        mb="40px"
      >
        One-Stop-Solution For Decentralized Trading
      </Text>
      <AppBody>
        <SelectTab />
        <PageHeader title={t('POOL_1')} description={t('POOL_2')} />
        <AutoColumn gap="lg" justify="center" style={{ width: '100%' }}>
          <CardBody style={{ width: '100%', padding: '0 32px 32px' }}>
            <AutoColumn gap="12px" style={{ width: '100%' }}>
              <Row align="center" justifyContent="flex-start">
                <Text color={theme.colors.text}>{t('POOL_3')}</Text>
                <Question text={t('POOL_4')} infoStyles={{ width: '16px', height: '16px' }} />
              </Row>

              {!account ? (
                <LightCard
                  padding="20px"
                  style={{
                    background: '#fff',
                    height: '120px',
                    borderRadius: '12px',
                    marginTop: '20px',
                    marginBottom: '20px',
                    border: 'none',
                  }}
                >
                  <Image
                    src={require('assets/images/wallet.svg').default}
                    width={64}
                    height={64}
                    style={{ margin: '0 auto' }}
                  />
                  <Text color="#666" fontWeight={400} fontSize="14px" textAlign="center" style={{ lineHeight: '30px' }}>
                    {t('POOL_5')}
                  </Text>
                </LightCard>
              ) : v2IsLoading ? (
                <LightCard padding="20px">
                  <Text color="textDisabled" textAlign="center">
                    <Dots>{t('POOL_6')}</Dots>
                  </Text>
                </LightCard>
              ) : allV2PairsWithLiquidity?.length > 0 ? (
                <LightCard padding="0px" style={{ border: 'none' }}>
                  {allV2PairsWithLiquidity.map((v2Pair) => (
                    <FullPositionCard key={v2Pair.liquidityToken.address} pair={v2Pair} />
                  ))}
                </LightCard>
              ) : (
                // no liquidity found
                <LightCard style={{ border: 'none' }}>
                  <NoLiquidity TranslateString={TranslateString} />
                </LightCard>
              )}
              {/* <Line /> */}
            </AutoColumn>
          </CardBody>
        </AutoColumn>
        <Text color="white">
          <AnimationButton
            id="join-pool-button"
            as={Link}
            to={`/add/${ETHER.name}`}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '-10px 32px 0',
              borderRadius: '22px',
              height: '48px',
              fontSize: '16px',
              background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
              fontWeight: 500,
            }}
          >
            {t('POOL_9')}
          </AnimationButton>
        </Text>
        <Row
          ml="32px"
          mt="10px"
          mb="20px"
          flexDirection={isMobile ? 'column' : 'row'}
          align={isMobile ? 'flex-start' : 'center'}
        >
          <Text color="textRemark" fontSize="14px" style={{ padding: '.5rem 0 .5rem 0' }}>
            {t('POOL_7')}
          </Text>
          <LinkText
            fontSize="14px"
            style={{ marginLeft: isMobile ? '0' : '7px' }}
            color="primary"
            onClick={() => {
              history.push('/find')
            }}
          >
            {t('POOL_8')}
          </LinkText>
        </Row>
      </AppBody>
    </Container>
  )
}
