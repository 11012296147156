import { ChainId, WETH } from 'shardex-sdk'
import tokens from 'constants/tokens'

export default {
  [ChainId.MAINNET]: {
    name: 'Shardex Default Mainnet List',
    timestamp: '2021-08-14 02:26:00',
    version: {
      major: 2,
      minor: 9,
      patch: 0,
    },
    tags: {},
    logoURI: '',
    keywords: ['default'],
    tokens: [],
  },

  [ChainId.TESTNET]: {
    name: 'Shardex Default Testnet List',
    timestamp: '2021-08-14 02:26:00',
    version: {
      major: 2,
      minor: 9,
      patch: 0,
    },
    tags: {},
    logoURI: '',
    keywords: ['default'],
    tokens: [
      {
        name: WETH[ChainId.TESTNET].name,
        symbol: WETH[ChainId.TESTNET].symbol,
        address: tokens.wchain.address[ChainId.TESTNET],
        chainId: ChainId.TESTNET,
        decimals: 18,
      },
      {
        name: 'SDT',
        symbol: 'SDT',
        address: tokens.sdt.address[ChainId.TESTNET],
        chainId: ChainId.TESTNET,
        decimals: 18,
      },
      {
        name: 'USDT',
        symbol: 'USDT',
        address: tokens.usdt.address[ChainId.TESTNET],
        chainId: ChainId.TESTNET,
        decimals: 18,
      },
    ],
  },
}
