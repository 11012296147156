import React from 'react'
import styled from 'styled-components'
import { Text, useWalletModal } from 'uikit'
import { AnimationButton } from 'components/Button'
import copy from 'copy-to-clipboard'
import { useToast } from 'state/hooks'
import { useTranslation } from 'react-i18next'
import useAuth from 'hooks/useAuth'

interface CopyItemProps {
  title: string
  data: string
  isMobile: boolean
  account?: string
}

const Container = styled.div`
  position: relative;
  width: 504px;
  height: 48px;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 24px;
  margin-top: 10px;
  color: white;
  line-height: 48px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const CopyButton = styled(AnimationButton)`
  width: 120px;
  height: 48px;
  border-radius: 40px;
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 768px) {
    width: 80px;
  }
`

const CopyItem: React.FC<CopyItemProps> = ({ title, data = '-', isMobile, account }) => {
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, account as any)
  const { toastSuccess } = useToast()
  const { t } = useTranslation()

  const formatUrl = (url: string) => {
    if (isMobile) return url.length > 20 ? `${url.substr(0, 13)}...${url.substr(-7)}` : url
    return url.length > 20 ? `${url.substr(0, 30)}...${url.substr(-10)}` : url
  }

  return (
    <>
      <Text fontSize="20px" color="white" bold mt="20px" textAlign={isMobile ? 'center' : 'left'}>
        {title}
      </Text>
      <Container>
        {account ? formatUrl(data) : '-'}
        <CopyButton
          onClick={() => {
            if (!account) {
              onPresentConnectModal()
              return
            }
            copy(data)
            toastSuccess(t('REFERRAL_8'))
          }}
        >
          {account ? 'Copy' : 'Connect'}
        </CopyButton>
      </Container>
    </>
  )
}

export default CopyItem
