import { Token } from 'shardex-sdk'
import tokens from './tokens'

export const projectToken = new Token(
  Number(process.env.REACT_APP_CHAIN_ID),
  tokens.sdt.address[Number(process.env.REACT_APP_CHAIN_ID)],
  18,
  'SDT',
  'SDT'
)

export const BRIDGE = 'https://bridge.shardex.org'
// valut abi

//
