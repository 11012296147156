/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-expressions */
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { useResponsive } from 'hooks/useResponse'
import { PublicIfoData, WalletIfoData } from 'pages/Ifos/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'
import { Flex, Text } from 'uikit'
import Col from '../../../../components/Column'
import Row, { RowBetween } from '../../../../components/Row/index'
import { Ifo, PoolIds } from '../../../../constants/types'
import ClaimButton from '../IfoFoldableCard/IfoPoolCard/ClaimButton'
import ClaimButtonWl from '../IfoFoldableCard/IfoPoolCard/ClaimButtonWl'
import ContributeButton from '../IfoFoldableCard/IfoPoolCard/ContributeButton'
import Timer from '../IfoFoldableCard/Timer'

interface Props {
  ifo: Ifo
  publicIfoData: PublicIfoData
  walletIfoData: WalletIfoData
  poolId?: PoolIds
}

const Card = styled.div`
  width: 100%;
  height: auto;
  border-radius: 12px;
  background: #737e8d29;
  padding: 32px 24px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 534px;
  }
`

const ButtonWrapper = styled.div`
  width: 150px;
`

const ImgNoJoin = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const NoSBTBg = styled(Flex)`
  height: 68px;
  width: 100%;
  border-radius: 20px;
  background: rgba(245, 69, 91, 0.1);
  padding: 20px;
  align-items: center;
  margin-top: 20px;
  @media (max-width: 768px) {
    height: auto;
  }
`

const ImgNoSBT = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 16px;
`

const DetailActionCard: React.FC<Props> = ({ ifo, publicIfoData, walletIfoData, poolId }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { account } = useWeb3React()
  const { isMobile } = useResponsive()
  const userPoolCharacteristics = walletIfoData[poolId ?? PoolIds.poolBasic]
  const userPoolUnlimitedCharacteristics = walletIfoData[PoolIds.poolUnlimited]
  const { amountTokenCommittedInLP, offeringAmountInToken, refundingAmountInLP, hasClaimed, hasSBT } =
    userPoolCharacteristics
  const {
    amountTokenCommittedInLP: unlimitedAmountTokenCommittedInLP,
    offeringAmountInToken: unlimitedOfferingAmountInToken,
    refundingAmountInLP: unlimitedRefundingAmountInLP,
  } = userPoolUnlimitedCharacteristics
  const totalOfferAmount = offeringAmountInToken.plus(unlimitedOfferingAmountInToken).div(new BigNumber(10).pow(18))
  const totalRefundAmount = refundingAmountInLP.plus(unlimitedRefundingAmountInLP).div(new BigNumber(10).pow(18))
  const isFinished = publicIfoData[poolId ?? PoolIds.poolBasic].status === 'finished'
  const isUnlimitedFinished = publicIfoData[PoolIds.poolUnlimited].status === 'finished'
  const isWhiteList = ifo[poolId ?? PoolIds.poolBasic].whitelist
  const userHasWhitelist = hasSBT
  const totalCommitted = new BigNumber(0).plus(amountTokenCommittedInLP).plus(unlimitedAmountTokenCommittedInLP)

  const styleTime = { color: '#FF3093', fontSize: '20px', fontWeight: 700, margin: '0 8px' }
  const isCurrencyLpToken = React.useMemo(() => {
    if (ifo.currency.symbol.includes('LP')) {
      return true
    }
    return false
  }, [ifo.currency])

  const renderInWhiteList = () => {
    return null
  }

  const renderNoWhiteList = () => {
    return (
      <>
        <Card>
          <Timer publicIfoData={publicIfoData} poolId={poolId} isDetail={true} />
          <RowBetween
            style={{
              marginTop: '8px',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
            }}
          >
            <Col style={{ marginBottom: isMobile ? '20px' : '0' }}>
              <Text fontSize="14px" color="white">
                Price:{' '}
                <span style={{ fontSize: '20px', color: theme.colors.primary, marginRight: '4px', fontWeight: '600' }}>
                  {ifo.tokenOfferingPrice}
                </span>{' '}
                {ifo.currency.symbol} / {ifo.token.symbol}
              </Text>
            </Col>
          </RowBetween>
          <NoSBTBg>
            <ImgNoSBT src={require('assets/images/launchpad/sad.png').default} />
            <Text color="#F5455B" fontSize="14px">
              Sorry, You can't participate in this sale since you don't hold SBT, please join the sale for public.
            </Text>
          </NoSBTBg>
        </Card>
      </>
    )
  }

  if (!poolId) {
    return (
      <Card style={{ width: isMobile ? '100%' : '800px' }}>
        <Text fontSize="14px" mb="2px" color="#B4B7C1">
          Staked {ifo.currency.symbol} amount
        </Text>
        <Text fontSize="24px" fontWeight="700" color={totalCommitted.gt(0) ? 'white' : '#B4B7C1'}>
          {isFinished && isUnlimitedFinished ? totalCommitted.div(new BigNumber(10).pow(18)).toFixed(2, 1) : '-'}
        </Text>
        {/* <RowBetween
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          <Col>
            <Text fontSize="16px" color={"white"}>
              {isCurrencyLpToken
                ? t('LAUNCHPAD_4', { asset: ifo.currency.symbol.split(' LP')[0] })
                : t('LAUNCHPAD_2', { asset: ifo.currency.symbol.split(' LP')[0] })}
            </Text>
            <Text fontSize="24px" bold color={refundingAmountInLP.gt(0) ? 'white' : '#B4B7C1'}>
              {isFinished ? refundingAmountInLP.div(new BigNumber(10).pow(18)).toFixed(2, 1) : '-'}
            </Text>
          </Col>
          <Col style={{ marginRight: isMobile ? '0' : '80px', marginTop: isMobile ? '20px' : '0' }}>
            <Text fontSize="16px" color={"white"}>
              {t('LAUNCHPAD_2', { asset: ifo.id.toUpperCase() })}
            </Text>
            <Text fontSize="24px" bold color={offeringAmountInToken.gt(0) ? 'white' : '#B4B7C1'}>
              {isFinished ? offeringAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1) : '-'}
            </Text>
          </Col>
        </RowBetween>
        <Text fontSize="18px" mt="35px" mb="10px">
          {t('LAUNCHPAD_5')}
        </Text>
        <RowBetween
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            gridTemplateColumns: '1fr 1fr 1fr',
          }}
        >
          <Col>
            <Text fontSize="16px" color={"white"}>
              {isCurrencyLpToken
                ? t('LAUNCHPAD_4', { asset: ifo.currency.symbol.split(' LP')[0] })
                : t('LAUNCHPAD_2', { asset: ifo.currency.symbol.split(' LP')[0] })}
            </Text>
            <Text fontSize="24px" bold color={unlimitedRefundingAmountInLP.gt(0) ? 'white' : '#B4B7C1'}>
              {isUnlimitedFinished ? unlimitedRefundingAmountInLP.div(new BigNumber(10).pow(18)).toFixed(2, 1) : '-'}
            </Text>
          </Col>
          <Col style={{ marginRight: isMobile ? '0' : '80px', marginTop: isMobile ? '20px' : '0' }}>
            <Text fontSize="16px" color={"white"}>
              {t('LAUNCHPAD_2', { asset: ifo.id.toUpperCase() })}
            </Text>
            <Text fontSize="24px" bold color={unlimitedOfferingAmountInToken.gt(0) ? 'white' : '#B4B7C1'}>
              {isUnlimitedFinished ? unlimitedOfferingAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1) : '-'}
            </Text>
          </Col>
        </RowBetween> */}
        <RowBetween style={{ marginTop: '32px' }}>
          {!account ? (
            <ConnectWalletButton width="200px" padding="0px" style={{ fontWeight: '500', height: '40px' }} />
          ) : (
            <ClaimButtonWl
              ifo={ifo}
              publicIfoData={publicIfoData}
              ifoVersion={ifo.version}
              walletIfoData={walletIfoData}
              canClaim={totalOfferAmount.gt(0) || totalRefundAmount.gt(0)}
              isCurrencyLpToken={isCurrencyLpToken}
              account={account}
            />
          )}
        </RowBetween>
      </Card>
    )
  }

  if (isWhiteList && account && !userHasWhitelist) {
    return renderNoWhiteList()
  }
  if (isFinished && amountTokenCommittedInLP.gt(0)) {
    return (
      <Card>
        {isWhiteList &&
          account &&
          (userHasWhitelist ? (
            renderInWhiteList()
          ) : (
            <Text color="white" fontSize="14px">
              {' '}
              {t('LAUNCHPAD_73')}{' '}
            </Text>
          ))}
        <Text color="white" fontSize="14px">
          {t('LAUNCHPAD_6')}{' '}
          <span style={styleTime}>{amountTokenCommittedInLP.div(new BigNumber(10).pow(18)).toFixed(2, 1)}</span>{' '}
          {ifo.currency.symbol}
        </Text>
        {/* {
          i18next.language === 'en' ?
          <Text color={"white"} fontSize="14px" mt={isMobile ? '20px' : '0'}>
            The amount of {ifo.id.toUpperCase()} you {hasClaimed ? '' : 'can'} receive{hasClaimed ? 'd' : ''}: <span style={styleTime}>{offeringAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1)}</span>
          </Text>
          :
          <Text color={"white"} fontSize="14px" mt={isMobile ? '20px' : '0'}>
            {t('LAUNCHPAD_7', {asset: ifo.id.toUpperCase()})} <span style={styleTime}>{offeringAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1)}</span>
          </Text>
        } */}
        <Text color="white" fontSize="14px" mt={isMobile ? '20px' : '0'}>
          The amount of {ifo.id.toUpperCase()} you {hasClaimed ? '' : 'can'} receive{hasClaimed ? 'd' : ''}:{' '}
          <span style={styleTime}>{offeringAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1)}</span>
        </Text>
      </Card>
    )
  }

  return (
    <Card>
      {isWhiteList &&
        account &&
        (userHasWhitelist ? (
          renderInWhiteList()
        ) : (
          <Text color="white" mb="25px" fontSize="14px">
            {' '}
            {t('LAUNCHPAD_73')}{' '}
          </Text>
        ))}
      <Timer publicIfoData={publicIfoData} poolId={poolId} isDetail={true} />
      <RowBetween
        style={{
          marginTop: '8px',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'flex-start' : 'flex-end',
        }}
      >
        <Col style={{ marginBottom: isMobile ? '20px' : '0' }}>
          <Text fontSize="14px" color="white">
            Price:{' '}
            <span style={{ fontSize: '20px', color: theme.colors.primary, marginRight: '4px', fontWeight: '600' }}>
              {ifo[poolId].tokenOfferingPrice}
            </span>{' '}
            {ifo.currency.symbol} / {ifo.token.symbol}
          </Text>
          {account ? (
            <>
              <Text fontSize="14px" color="white" mt="25px">
                {ifo.currency.symbol} {t('LAUNCHPAD_8')}
              </Text>
              <Text fontSize="24px" bold color="#B4B7C1">
                {amountTokenCommittedInLP.div(new BigNumber(10).pow(18)).toFixed(2, 1)}
              </Text>
            </>
          ) : null}
          {account ? null : (
            <ConnectWalletButton
              width="200px"
              padding="0px"
              style={{ fontWeight: '500', marginTop: '20px', height: '40px' }}
            />
          )}
        </Col>
        {!account ? null : (
          <ButtonWrapper>
            <ContributeButton poolId={poolId} ifo={ifo} publicIfoData={publicIfoData} walletIfoData={walletIfoData} />
          </ButtonWrapper>
        )}
      </RowBetween>
    </Card>
  )
}

export default DetailActionCard
