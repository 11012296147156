import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading, Text } from 'uikit'
import { useHarvest } from 'hooks/useHarvest'
import { getBalanceNumber } from 'utils/formatBalance'
import { useWeb3React } from '@web3-react/core'
import { useProjectTokenUSDT } from 'state/hooks'
import CardBusdValue from 'components/CardBusdValue'
import Column from 'components/Column'
import styled from 'styled-components'
import CurrencyLogo from 'components/CurrencyLogo'
import tokens from 'constants/tokens'
import { useTokenUsdtPrice } from 'utils/useTokenUsdtPrice'
import { getAddress } from 'utils/addressHelpers'
import { useTranslation } from 'react-i18next'
import { projectToken } from 'constants/migrate'

interface FarmCardActionsProps {
  earnings: BigNumber
  doubleDigEarnings: BigNumber
  pid: number
  version?: number
  doubleDigToken: any
}

const HarvestButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 40px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#B4B7C1'
    }
    return '#FF3093'
  }};
  color: #fff;
  &:hover {
    opacity: 0.7;
  }
`

const HarvestAction: React.FC<FarmCardActionsProps> = ({ earnings, doubleDigEarnings, pid, doubleDigToken }) => {
  const { account } = useWeb3React()
  const [pendingTx, setPendingTx] = useState(false)
  const { onReward } = useHarvest(pid)
  const projectTokenPrice = useProjectTokenUSDT()
  const { t } = useTranslation()
  const rawEarningsBalance = account ? getBalanceNumber(earnings) : 0
  const displayBalance = rawEarningsBalance.toLocaleString()
  const earningsBusd = rawEarningsBalance
    ? new BigNumber(rawEarningsBalance).multipliedBy(projectTokenPrice).toNumber()
    : 0

  const rawRewardEarningsBalance = account ? getBalanceNumber(doubleDigEarnings) : 0
  const rewardDisplayBalance = rawRewardEarningsBalance.toLocaleString()
  const rewardTokenPrice = useTokenUsdtPrice(getAddress(doubleDigToken?.address ?? tokens.usdt.address))
  const rewardEarningsUsdt = rawRewardEarningsBalance
    ? new BigNumber(rawRewardEarningsBalance).multipliedBy(rewardTokenPrice).toNumber()
    : 0

  return (
    <Flex mb="8px" justifyContent="space-between" alignItems="center">
      <Column>
        <Flex flexDirection="row">
          {doubleDigToken && <CurrencyLogo size="24px" currency={tokens.sdt} style={{ marginRight: '8px' }} />}
          <Column>
            <Flex>
              <Text textTransform="uppercase" color="primary" fontSize="16px" pr="3px" fontWeight={500}>
                {projectToken.symbol.toUpperCase()}
              </Text>
              <Text color="textSubtle" fontSize="16px" fontWeight={500}>
                {t('FARM_32')}
              </Text>
            </Flex>
            <Heading color={rawEarningsBalance === 0 ? 'textDisabled' : 'text'} style={{ textAlign: 'left' }}>
              <Text color="#01142A" fontSize="24px" fontWeight={700}>
                {displayBalance}
                {earningsBusd > 0 && <CardBusdValue value={earningsBusd} />}
              </Text>
            </Heading>
          </Column>
        </Flex>
        {doubleDigToken ? (
          <>
            <Flex mt="24px">
              <CurrencyLogo size="24px" currency={doubleDigToken} style={{ marginRight: '8px' }} />
              <Column>
                <Flex>
                  <Text textTransform="uppercase" color="primary" fontSize="16px" pr="3px" fontWeight={500}>
                    {doubleDigToken.symbol}
                  </Text>
                  <Text textTransform="uppercase" color="textSubtle" fontSize="16px" fontWeight={500}>
                    {t('FARM_32')}
                  </Text>
                </Flex>
                <Heading color={rawRewardEarningsBalance === 0 ? 'textDisabled' : 'text'} style={{ textAlign: 'left' }}>
                  <Text color="#01142A" fontSize="24px" fontWeight={700}>
                    {rewardDisplayBalance}
                    {rewardEarningsUsdt > 0 && <CardBusdValue value={rewardEarningsUsdt} />}
                  </Text>
                </Heading>
              </Column>
            </Flex>
          </>
        ) : null}
      </Column>
      <HarvestButton
        disabled={rawEarningsBalance === 0 || pendingTx}
        onClick={async () => {
          setPendingTx(true)
          try {
            await onReward()
          } finally {
            setPendingTx(false)
          }
        }}
      >
        {pendingTx ? t('FARM_65') : t('FARM_66')}
      </HarvestButton>
    </Flex>
  )
}

export default HarvestAction
