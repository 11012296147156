import BigNumber from 'bignumber.js'
import ifoV2Abi from 'constants/abis/ifoV2.json'
import { Ifo, PoolIds } from 'constants/types'
import { useActiveWeb3React } from 'hooks'
import { useERC20, useIfoV2Contract } from 'hooks/useContract'
import { useCallback, useState } from 'react'
import { BIG_ZERO } from 'utils/bigNumber'
import { getIfoV2ReleaserContract, getNFTContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import { WalletIfoData, WalletIfoState } from '../../types'
import useIfoAllowance from '../useIfoAllowance'

const initialState = {
  isInitialized: false,
  poolBasic: {
    amountTokenCommittedInLP: BIG_ZERO,
    offeringAmountInToken: BIG_ZERO,
    refundingAmountInLP: BIG_ZERO,
    taxAmountInLP: BIG_ZERO,
    hasClaimed: false,
    hasWhiteListClaimed: false,
    isPendingTx: false,
    allowance: BIG_ZERO,
    contract: '',
    isPendingVesterTx1: false,
    isPendingVesterTx2: false,
    hasSBT: false,
  },
  poolUnlimited: {
    amountTokenCommittedInLP: BIG_ZERO,
    offeringAmountInToken: BIG_ZERO,
    refundingAmountInLP: BIG_ZERO,
    taxAmountInLP: BIG_ZERO,
    hasClaimed: false,
    hasWhiteListClaimed: false,
    isPendingTx: false,
    allowance: BIG_ZERO,
    contract: '',
    isPendingVesterTx1: false,
    isPendingVesterTx2: false,
    releaserInfo: {
      epochClaimAmountList: [], // epoch claim amount
      claimInfo: [], // epoch claim status
    },
  },
}

/**
 * Gets all data from an IFO related to a wallet
 */
const useGetWalletIfoData = (ifo: Ifo): WalletIfoData => {
  const [state, setState] = useState<WalletIfoState>(initialState)

  const { address, currency, unlimitedAddress } = ifo

  const { account, library } = useActiveWeb3React()
  const contractBasic = useIfoV2Contract(address)
  const contractUnlimited = useIfoV2Contract(unlimitedAddress)

  const currencyContract = useERC20(currency.address)
  const allowanceBasic = useIfoAllowance(currencyContract as any, address)
  const allowanceUnlimited = useIfoAllowance(currencyContract as any, unlimitedAddress)

  const setPendingTx = (status: boolean, poolId: PoolIds) =>
    setState((prevState) => ({
      ...prevState,
      [poolId]: {
        ...prevState[poolId],
        isPendingTx: status,
      },
    }))

  const setVesterPendingTx1 = (status: boolean, poolId: PoolIds) =>
    setState((prevState) => ({
      ...prevState,
      [poolId]: {
        ...prevState[poolId],
        isPendingVesterTx1: status,
      },
    }))

  const setVesterPendingTx2 = (status: boolean, poolId: PoolIds) =>
    setState((prevState) => ({
      ...prevState,
      [poolId]: {
        ...prevState[poolId],
        isPendingVesterTx2: status,
      },
    }))

  const setIsClaimed = (poolId: PoolIds) => {
    setState((prevState) => ({
      ...prevState,
      [poolId]: {
        ...prevState[poolId],
        hasClaimed: true,
      },
    }))
  }

  const setWhiteListIsClaimed = (poolId: PoolIds) => {
    setState((prevState) => ({
      ...prevState,
      [poolId]: {
        ...prevState[poolId],
        hasWhiteListClaimed: true,
      },
    }))
  }

  const fetchIfoData = useCallback(async () => {
    const ifoCalls = ['viewUserInfo', 'viewUserOfferingAndRefundingAmountsForPools'].map((method) => ({
      address,
      name: method,
      params: [account, [0, 1]],
    }))

    const unlimitedIfoCalls = ['viewUserInfo', 'viewUserOfferingAndRefundingAmountsForPools'].map((method) => ({
      address: unlimitedAddress,
      name: method,
      params: [account, [0, 1]],
    }))

    const [userInfo, amounts] = await multicall(ifoV2Abi, ifoCalls)
    const [userInfoUnlimited, amountsUnlimited] = await multicall(ifoV2Abi, unlimitedIfoCalls)

    const contractSBT = getNFTContract(ifo.poolBasic.NFT, library)
    const contractUnlimitedReleaser = ifo.poolUnlimited?.releaser
      ? getIfoV2ReleaserContract(ifo.poolUnlimited?.releaser, library)
      : ''

    const releaserInfo = {
      epochClaimAmountList: [],
      claimInfo: [],
    }

    if (contractUnlimitedReleaser) {
      // todo
      const releaserClaimInfo = []
      const releaserUserInfo = await contractUnlimitedReleaser.viewUserEpochAmounts(account)
      for (let i = 0; i < releaserUserInfo.length; i++) {
        const claimEpochInfo = await contractUnlimitedReleaser.claimInfo(account, i)
        releaserClaimInfo.push(claimEpochInfo)
      }

      releaserInfo.epochClaimAmountList = releaserUserInfo
      releaserInfo.claimInfo = releaserClaimInfo
      // releaserInfo.amount = releaserUserInfo.amount.toString()
    }
    let isBasicPoolWhiteList = false
    const hasSBT = await contractSBT.balanceOf(account)
    isBasicPoolWhiteList = Number(hasSBT?.toString()) > 0
    // console.log('isBasicPoolWhiteList =', isBasicPoolWhiteList, 'nft balance:', Number(hasSBT?.toString()))

    setState((prevState) => ({
      ...prevState,
      isInitialized: true,
      poolBasic: {
        ...prevState.poolBasic,
        amountTokenCommittedInLP: new BigNumber(userInfo[0][0].toString()),
        offeringAmountInToken: new BigNumber(amounts[0][0][0].toString()),
        refundingAmountInLP: new BigNumber(amounts[0][0][1].toString()),
        taxAmountInLP: new BigNumber(amounts[0][0][2].toString()),
        hasClaimed: userInfo[1][0],
        allowance: allowanceBasic,
        contract: contractBasic,
        hasSBT: isBasicPoolWhiteList,
      },
      poolUnlimited: {
        ...prevState.poolUnlimited,
        amountTokenCommittedInLP: new BigNumber(userInfoUnlimited[0][0].toString()),
        offeringAmountInToken: new BigNumber(amountsUnlimited[0][0][0].toString()),
        refundingAmountInLP: new BigNumber(amountsUnlimited[0][0][1].toString()),
        taxAmountInLP: new BigNumber(amountsUnlimited[0][0][2].toString()),
        hasClaimed: userInfoUnlimited[1][0],
        allowance: allowanceUnlimited,
        contract: contractUnlimited,
        contractVester: contractUnlimitedReleaser,
        releaserInfo,
      },
    }))
  }, [
    ifo.poolBasic.NFT,
    ifo.poolUnlimited?.releaser,
    library,
    account,
    address,
    unlimitedAddress,
    allowanceBasic,
    contractBasic,
    allowanceUnlimited,
    contractUnlimited,
  ])

  const resetIfoData = useCallback(() => {
    setState({ ...initialState })
  }, [])

  return {
    ...state,
    setPendingTx,
    setIsClaimed,
    fetchIfoData,
    resetIfoData,
    setWhiteListIsClaimed,
    setVesterPendingTx1,
    setVesterPendingTx2,
  }
}

export default useGetWalletIfoData
