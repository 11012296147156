import React from 'react'
import { useTranslation } from 'react-i18next'
import { Trade, TradeType } from 'shardex-sdk'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { Card, CardBody, Text } from '../../uikit'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import QuestionHelper from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import SwapRoute from './SwapRoute'

function TradeSummary({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { t } = useTranslation()

  return (
    <Card style={{ borderRadius: '16px', boxShadow: 'none' }}>
      <CardBody>
        <RowBetween>
          <RowFixed>
            <Text fontSize="14px" color="textRemark">
              {isExactIn ? t('COMPONENTS_109') : t('COMPONENTS_110')}
            </Text>
            <QuestionHelper text={t('COMPONENTS_112')} infoStyles={{ width: '16px', height: '16px' }} />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="textRemark">
              {t('COMPONENTS_182')}
            </Text>
            <QuestionHelper text={t('COMPONENTS_113')} infoStyles={{ width: '16px', height: '16px' }} />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween mt="5px">
          <RowFixed>
            <Text fontSize="14px" color="textRemark">
              {t('COMPONENTS_122')}
            </Text>
            <QuestionHelper text={t('COMPONENTS_114')} infoStyles={{ width: '16px', height: '16px' }} />
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
      </CardBody>
    </Card>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
}

export function AdvancedSwapDetails({ trade }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()
  const showRoute = Boolean(trade && trade.route.path.length > 2)
  const { t } = useTranslation()
  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary trade={trade} allowedSlippage={allowedSlippage} />
          {showRoute && (
            <>
              {/* <SectionBreak /> */}
              <AutoColumn style={{ padding: '12px 24px', background: '#fff', borderRadius: '16px' }}>
                <RowFixed>
                  <Text fontSize="14px">{t('COMPONENTS_203')}</Text>
                  <QuestionHelper text={t('COMPONENTS_115')} />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
