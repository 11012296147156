/* eslint-disable @typescript-eslint/no-unused-vars */
import Container from 'components/Container'
import every from 'lodash/every'
import useGetPublicIfoV2Data from 'pages/Ifos/hooks/v2/useGetPublicIfoData'
import useGetWalletIfoV2Data from 'pages/Ifos/hooks/v2/useGetWalletIfoData'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { useBlockNumber } from 'state/application/hooks'
import styled, { useTheme } from 'styled-components'
import { Flex, Text } from 'uikit'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import { ifosConfig } from '../../constants'
import { PoolIds } from '../../constants/types'
// import { nftsBaseUrl } from 'pages/Nft/market/constants'
import { useWeb3React } from '@web3-react/core'
import Col from 'components/Column'
import Row from 'components/Row'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import useRefresh from 'hooks/useRefresh'
import DetailActionCard from './components/IfoDetail/DetailActionCard'
// import { useProfile } from 'state/profile/hooks'
import { useResponsive } from 'hooks/useResponse'
import { useTranslation } from 'react-i18next'
import DetailInfo from './components/IfoDetail/DetailInfo'
import { Stepper } from './components/IfoDetail/Stepper'

// tips button enable status
const StyleImgLink = styled.img`
  width: 24px;
  height: 24px;
`
const Imgs = {
  bg: require('assets/images/launchpad/bg.jpg').default,
  bgH5: require('assets/images/launchpad/bg-h5.jpg').default,
  house: require('assets/images/launchpad/house.png').default,
  swap: require('assets/images/launchpad/swap.svg').default,
  bridge: require('assets/images/launchpad/bridge.svg').default,
  rightSwap: require('assets/images/launchpad/right-swap.svg').default,
  rightBridge: require('assets/images/launchpad/right-bridge.svg').default,
}

const StyledBg = styled.div`
  background: url(${Imgs.bg}) top center no-repeat, #000;
  background-size: cover;
  max-width: 1920px;
  width: 100%;
  height: 800px;
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: 768px) {
    min-height: 100vh;
    width: 100vw;
    height: 824px;
    background: url(${Imgs.bgH5}) top center no-repeat, #000;
    background-size: cover;
  }
`

const StyleContentContainer = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  border-radius: 20px;
  padding: 48px 16px;
  position: relative;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 72px 24px;
    max-width: 1200px;
  }
`

const StyleStep = styled.div`
  width: 100%;
  margin-bottom: 50px;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 720px;
  }
`
const StyleStepContainer = styled.div`
  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: 40px;
  }
`

const StyleImgHouse = styled.img`
  width: 325px;
  height: 258px;
`

const StyleTop = styled(Flex)`
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin-top: 85px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const StyledLinkContainer = styled(Flex)<{ isSwap: boolean }>`
  background: ${({ isSwap }) => (isSwap ? '#14D89D1A' : '#FFC5301A')};
  width: 262px;
  height: 54px;
  border-radius: 20px;
  padding: 0 18px;
  align-items: center;
  justify-content: space-between;
  margin-right: ${({ isSwap }) => (isSwap ? '10px' : '0')};
  @media (max-width: 768px) {
    width: 100%;
    margin-top: ${({ isSwap }) => (isSwap ? '' : '16px')};
  }
`

const SubText = styled(Text)`
  color: white;
  margin-top: 10px;
  @media (max-width: 768px) {
    max-width: 200px;
    text-align: center;
    margin: 25px auto 18px;
    line-height: 2;
  }
`

export interface InfoCellProps {
  label: string
  value: string | number
  isH5: boolean
  width?: string
}

const InfoCell: React.FC<InfoCellProps> = ({ label, value, width, isH5 }) => {
  return (
    <Col style={{ width: width ?? '180px', marginBottom: isH5 ? '30px' : '0' }}>
      <Text small color="textSubtle" fontSize="14px">
        {label}
      </Text>
      <Text bold fontSize="20px" mt="5px">
        {value}
      </Text>
    </Col>
  )
}

const IfoDetail = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const currentBlock = useBlockNumber()
  const [isVisible, setIsVisible] = useState(true)
  const { fastRefresh } = useRefresh()
  const { isMobile } = useResponsive()
  const isWindowVisible = useIsWindowVisible()
  const { account } = useWeb3React()
  const { projectId } = useParams<{ projectId: string }>()
  const targetIfo = ifosConfig.find((ifo) => ifo.id === projectId)
  // const { hasProfile, profile } = useProfile()
  const publicIfoData = useGetPublicIfoV2Data(targetIfo)
  const walletIfoData = useGetWalletIfoV2Data(targetIfo)
  const {
    fetchIfoData: fetchPublicIfoData,
    isInitialized: isPublicIfoDataInitialized,
    poolBasic,
    poolUnlimited,
  } = publicIfoData
  const {
    poolBasic: walletBasic,
    poolUnlimited: walletUnlimited,
    fetchIfoData: fetchWalletIfoData,
    resetIfoData: resetWalletIfoData,
    isInitialized: isWalletDataInitialized,
  } = walletIfoData

  const basicPoolCharacteristic = publicIfoData[PoolIds.poolBasic]
  const unlimitedPoolCharacteristic = publicIfoData[PoolIds.poolUnlimited]
  const { currencyPriceInUSD } = publicIfoData
  const totalLPCommitted =
    getBalanceNumber(basicPoolCharacteristic.totalAmountPool, targetIfo.currency.decimals) +
    getBalanceNumber(unlimitedPoolCharacteristic.totalAmountPool, targetIfo.currency.decimals)
  const totalLPCommittedInUSD = currencyPriceInUSD.times(totalLPCommitted)
  const totalCommitted = `~$${formatNumber(totalLPCommittedInUSD.toNumber(), 0, 0)}`
  const projectFinish = publicIfoData[PoolIds.poolUnlimited].status === 'finished'
  // basic pool price with fee
  const sumTaxesOverflow = basicPoolCharacteristic.totalAmountPool.times(basicPoolCharacteristic.taxRate).times(0.01)
  const pricePerTokenWithFeeToOriginalRatio = sumTaxesOverflow
    .plus(basicPoolCharacteristic.raisingAmountPool)
    .div(basicPoolCharacteristic.offeringAmountPool)
    .div(basicPoolCharacteristic.raisingAmountPool.div(basicPoolCharacteristic.offeringAmountPool))
  const pricePerTokenWithFee = `~$${formatNumber(
    pricePerTokenWithFeeToOriginalRatio.times(targetIfo.tokenOfferingPrice).toNumber(),
    0,
    3
  )}`

  // unlimited pool price with fee
  const sumTaxesOverflowUnlimited = unlimitedPoolCharacteristic.totalAmountPool
    .times(unlimitedPoolCharacteristic.taxRate)
    .times(0.01)
  const pricePerTokenWithFeeToOriginalRatioUnlimited = sumTaxesOverflowUnlimited
    .plus(unlimitedPoolCharacteristic.raisingAmountPool)
    .div(unlimitedPoolCharacteristic.offeringAmountPool)
    .div(unlimitedPoolCharacteristic.raisingAmountPool.div(unlimitedPoolCharacteristic.offeringAmountPool))
  const pricePerTokenWithFeeUnlimited = `~$${formatNumber(
    pricePerTokenWithFeeToOriginalRatioUnlimited.times(targetIfo.tokenOfferingPrice).toNumber(),
    0,
    3
  )}`

  const isRecentlyActive =
    (publicIfoData.poolUnlimited.status !== 'finished' ||
      (publicIfoData.poolUnlimited.status === 'finished' && poolUnlimited.secondsUntilEnd >= -120)) &&
    targetIfo.isActive
  const stepsValidationStatus = [
    // (hasProfile && profile?.isActive )|| (publicIfoData.poolUnlimited.status === 'finished'),
    // publicIfoData.poolUnlimited.status === 'finished',
    publicIfoData.poolBasic.status === 'finished',
    publicIfoData.poolUnlimited.status === 'finished',
    walletIfoData.poolBasic.hasClaimed || walletIfoData.poolUnlimited.hasClaimed,
  ]

  const isCurrencyLpToken = React.useMemo(() => {
    if (targetIfo.currency.symbol.includes('LP')) {
      return true
    }
    return false
  }, [targetIfo])

  const getStatusProp = (index: number) => {
    const arePreviousValid = index === 0 ? true : every(stepsValidationStatus.slice(0, index), Boolean)
    if (stepsValidationStatus[index]) {
      return arePreviousValid ? 'past' : 'future'
    }
    return arePreviousValid ? 'current' : 'future'
  }

  const renderCardBody = (step: number) => {
    switch (step) {
      // case 0:
      //   return (
      //     <StyleStep style={{ marginBottom: isMobile ? '70px' : '100px' }}>
      //       <Text fontSize={isMobile ? '14px' : '16px'} mb="5px">
      //         {t('LAUNCHPAD_55')}
      //       </Text>
      //       {!account ? (
      //         <ConnectWalletButton width="150px" height="41px" marginTop="5px" padding="0px" />
      //       ) : (
      //         // hasProfile && profile?.isActive ?
      //         // <Text fontSize={isMobile ? '14px' : '16px'} color="##B4B7C1">{t('LAUNCHPAD_56')}<span style={{color: theme.colors.primary, fontWeight: 'bold', fontSize: '14px'}}>{profile?.team?.name ?? '--'} {t('LAUNCHPAD_57')}</span></Text>
      //         // :
      //         // <Button as={Link} to={`${nftsBaseUrl}/profile/${account.toLowerCase()}`} width="150px" height="41px" style={{marginTop: '5px'}}>
      //         //   {t('LAUNCHPAD_58')}
      //         // </Button>
      //         'no profile'
      //       )}
      //     </StyleStep>
      //   )
      case 0:
        return (
          <StyleStep>
            <Text color="white" mb="5px">
              Limited Sale for SBT holders
            </Text>
            <Text fontSize="14px" color="#B4B7C1" mb="20px">
              To participate in this round of the {targetIfo.token.symbol} sale, you must hold an SBT and stake{' '}
              {targetIfo.currency.symbol.split(' LP')[0]}.
            </Text>
            <DetailActionCard
              poolId={PoolIds.poolBasic}
              ifo={targetIfo}
              publicIfoData={publicIfoData}
              walletIfoData={walletIfoData}
            />
          </StyleStep>
        )
      case 1:
        return (
          <StyleStep>
            <Text color="white" mb="5px">
              Limited Sale for Public
            </Text>
            <Text fontSize="14px" color="#B4B7C1" mb="20px">
              To participate in limited sale, stake your {targetIfo.currency.symbol.split(' LP')[0]} and claim your{' '}
              {targetIfo.token.symbol} at Step3.
            </Text>
            <DetailActionCard
              poolId={PoolIds.poolUnlimited}
              ifo={targetIfo}
              publicIfoData={publicIfoData}
              walletIfoData={walletIfoData}
            />
            <Flex
              alignItems={isMobile ? 'flex-start' : 'center'}
              mt={isMobile ? '24px' : '12px'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <Link to="/swap" style={{ width: isMobile ? '100%' : '262px', marginRight: isMobile ? '0' : '12px' }}>
                <StyledLinkContainer isSwap={true}>
                  <Flex alignItems="center">
                    <StyleImgLink src={Imgs.swap} />
                    <Text color="#14D89D" ml="8px">
                      Buy USDT
                    </Text>
                  </Flex>
                  <StyleImgLink src={Imgs.rightSwap} />
                </StyledLinkContainer>
              </Link>
              <StyledLinkContainer isSwap={false}>
                <Flex alignItems="center">
                  <StyleImgLink src={Imgs.bridge} />
                  <Text color="#FFC530" ml="8px">
                    Bridge USDT
                  </Text>
                </Flex>
                <StyleImgLink src={Imgs.rightBridge} />
              </StyledLinkContainer>
            </Flex>
          </StyleStep>
        )
      case 2:
        return (
          <StyleStep>
            <Text color="white" mb="20px">
              Claim your {targetIfo.currency.symbol} and {targetIfo.id.toUpperCase()} token
            </Text>
            <DetailActionCard ifo={targetIfo} publicIfoData={publicIfoData} walletIfoData={walletIfoData} />
          </StyleStep>
        )
      default:
        return null
    }
  }

  useEffect(() => {
    if (isVisible && (isRecentlyActive || !isPublicIfoDataInitialized)) {
      fetchPublicIfoData(currentBlock)
    }
  }, [isVisible, isRecentlyActive, isPublicIfoDataInitialized, fetchPublicIfoData, currentBlock])

  useEffect(() => {
    if (isWindowVisible && isVisible) {
      if (account) {
        fetchWalletIfoData()
      }
    }

    if (!account && isWalletDataInitialized) {
      resetWalletIfoData()
    }
  }, [
    isVisible,
    isWindowVisible,
    account,
    isRecentlyActive,
    isWalletDataInitialized,
    fetchWalletIfoData,
    resetWalletIfoData,
    fastRefresh,
  ])
  return (
    <>
      <Container style={{ padding: isMobile ? '20px 12px' : '50px 36px', background: '#000' }}>
        <StyledBg />
        <StyleTop>
          <Flex flexDirection="column" maxWidth="600px">
            <Text fontSize="48px" bold color="white" textAlign={isMobile ? 'center' : 'left'}>
              Become a Holder in the SharDex Community
            </Text>
            <SubText>Start the first IDO journey on Shardeum Chain</SubText>
          </Flex>
          <StyleImgHouse src={Imgs.house} />
        </StyleTop>
        <StyleContentContainer>
          <StyleStepContainer>
            {stepsValidationStatus.map((_, index) => (
              <Stepper
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                index={index}
                statusFirstPart={getStatusProp(index)}
                numberOfSteps={3}
              >
                {renderCardBody(index)}
              </Stepper>
            ))}
          </StyleStepContainer>
        </StyleContentContainer>
        <DetailInfo ifo={targetIfo} publicIfoData={publicIfoData} />
      </Container>
    </>
  )
}

export default IfoDetail
