import React from 'react'
import { useTranslation } from 'react-i18next'
import useHover from 'react-use-hover'
import styled, { keyframes } from 'styled-components'
import { Text } from 'uikit'
import clickEvent from 'utils/gtag'
import { useWalletModal } from '../../WalletModal'
import BetaAccountModal from '../../WalletModal/BetaAccountModal'
import { Login } from '../../WalletModal/types'

interface Props {
  account?: string
  login: Login
  logout: () => void
  pendingCount: number
}

const ConnectButton = styled.div<{ pendingCount?: number; account?: string }>`
  align-items: center;
  position: relative;
  border-radius: 18px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  margin: 0px;
  margin-left: 18px;
  min-width: 36px;
  width: ${({ pendingCount }) => {
    if (pendingCount && pendingCount > 0) {
      return '280px'
    }
    return '165px'
  }};
  outline: none;
  white-space: nowrap;
  background: ${({ account }) => (account ? 'rgba(255, 255, 255, 0.1)' : '#ff3093')};
  color: #fff;
  z-index: 0;
  @media (max-width: 768px) {
    width: ${({ pendingCount }) => {
      if (pendingCount && pendingCount > 0) {
        return '280px'
      }
      return '165px'
    }};
    padding: 5px 3px;
  }
`

const RotateAll = keyframes`
  0% {
    transform:rotate(0deg);
  }

  100% {
     transform:rotate(360deg);
  }
`

const LoadingImage = styled.img`
  width: 20px;
  height: 20px;
  animation: ${RotateAll} linear 1s infinite;
`

const StyledImgWallet = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
`

const StyleMask = styled.div<{ visible: boolean; pendingCount?: number }>`
  width: ${({ pendingCount }) => {
    if (pendingCount && pendingCount > 0) {
      return '280px'
    }
    return '165px'
  }};
  @media (max-width: 768px) {
    width: ${({ pendingCount }) => {
      if (pendingCount && pendingCount > 0) {
        return '280px'
      }
      return '165px'
    }};
  }
  height: 36px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  background: #ffffff33;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  border-radius: 18px;
`

const Imgs = {
  wallet: require('assets/images/home/wallet.svg').default,
  arrow: require('assets/images/home/menuArrow.svg').default,
  red: require('assets/images/home/redPoint.svg').default,
}

const UserBlock: React.FC<Props> = ({ account, login, logout, pendingCount }) => {
  const { onPresentConnectModal } = useWalletModal(login, logout, account)
  const accountEllipsis = account ? `${account.substring(0, 4)}...${account.substring(account.length - 4)}` : null
  const [isHovering, hoverProps] = useHover()
  const { t } = useTranslation()

  return (
    <>
      {account ? (
        <ConnectButton
          // pendingCount={pendingCount}
          account={account}
          onClick={() => {
            clickEvent('page_bar_checkwallet')
            // onPresentAccountModal()
          }}
          aria-describedby="overlay"
          {...hoverProps}
        >
          {/* <StyledImgWallet src={hasProfile ? profile?.nft?.image?.thumbnail ?? Imgs.wallet : Imgs.wallet} /> */}
          <StyledImgWallet src={Imgs.wallet} />
          <Text color="#fff" fontWeight={500} fontSize="14px" fontFamily="Poppins">
            {pendingCount === 0 ? accountEllipsis : `${pendingCount} pending`}
          </Text>
          {/* {pendingCount === 0 && !hasProfile && !isLoading && !isHovering ? <StyledImgPoint src={Imgs.red} /> : null} */}

          {pendingCount ? (
            <LoadingImage
              style={{ marginLeft: '10px' }}
              src={require('../../../../assets/images/loading.svg').default}
            />
          ) : (
            ''
          )}
          <BetaAccountModal account={account || ''} logout={logout} visible={isHovering} />
          <StyleMask visible={isHovering} pendingCount={pendingCount} />
        </ConnectButton>
      ) : (
        <ConnectButton
          onClick={() => {
            clickEvent('page_bar_connectwallet')
            onPresentConnectModal()
          }}
        >
          <Text color="#fff" fontWeight={500} fontSize="14px">
            {t('REFERRAL_11')}
          </Text>
        </ConnectButton>
      )}
    </>
  )
}

export default React.memo(
  UserBlock,
  (prevProps, nextProps) =>
    prevProps.account === nextProps.account &&
    prevProps.login === nextProps.login &&
    prevProps.logout === nextProps.logout &&
    prevProps.pendingCount === nextProps.pendingCount
)
