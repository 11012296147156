import React, { CSSProperties, useCallback, useState } from 'react'
import { AlertCircle as Info } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Currency, Token, WETH } from 'shardex-sdk'
import styled from 'styled-components'
import { LinkExternal, MetamaskIcon, Placement } from 'uikit'
import { getKCCExplorerLink } from 'utils'
import { addTokenToWallet } from '../../utils/wallet'
import Tooltip from '../Tooltip'

const StyledArrowLeft = styled(Info)<{ color: string | undefined }>`
  color: ${({ color }) => {
    if (color) return color
    return '#666'
  }};
  cursor: pointer;
`

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  // background-color: ${({ theme }) => theme.colors.invertedContrast};
  // color: ${({ theme }) => theme.colors.textSubtle};

  :hover,
  :focus {
    // opacity: 0.7;
    color: ${({ theme }) => theme.colors.textSubtle};
  }
`

const Content = styled.div<{ show: boolean }>`
  position: relative;
  z-index: 1199;
  width: auto;
  height: 35px;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  display: ${({ show }) => {
    if (show) {
      return 'flex'
    }
    return 'none'
  }};
`

export default function ManualQuestionHelper({
  size,
  color,
  placement,
  styles,
  token,
}: {
  color?: string
  placement?: Placement
  size?: number
  styles?: CSSProperties
  token: Currency
}) {
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()

  const close = useCallback(
    (e: any) => {
      e?.stopPropagation()
      setShow(false)
    },
    [setShow]
  )

  const toggle = useCallback(
    (e: any) => {
      e?.stopPropagation()
      setShow(() => !show)
    },
    [setShow, show]
  )

  // return (
  //   <Wrap>
  //     <Content show={true}>
  //       <LinkExternal href="./" />
  //     </Content>
  //     <StyledArrowLeft size={size ?? 16} color={color} onClick={open} />
  //   </Wrap>
  // )

  const addToken = async (e) => {
    e?.stopPropagation()
    if (token instanceof Token) {
      await addTokenToWallet(token.address, token.symbol as string, token.decimals)
    }
  }

  const tokenExplorerUrl = React.useMemo(() => {
    const chainId = Number(process.env.REACT_APP_CHAIN_ID)
    return getKCCExplorerLink(chainId, token instanceof Token ? token.address : WETH[chainId], 'token')
  }, [token])

  const AddTokenPanel = (
    <Content show={show} onMouseLeave={close}>
      <LinkExternal color="#666" style={{ fontSize: '14px', fontWeight: 400 }} href={tokenExplorerUrl}>
        {t('COMPONENTS_71')}
      </LinkExternal>
      <div style={{ width: '1px', background: '#EFEFF1', height: '50%', margin: '0px 10px' }} />
      <MetamaskIcon style={{ cursor: 'pointer' }} onClick={addToken} />
    </Content>
  )

  return (
    <span style={{ marginLeft: 4, pointerEvents: 'all', padding: '0', ...styles }}>
      <Tooltip text={AddTokenPanel} show={show} placement={placement ?? 'top'}>
        <QuestionWrapper>
          <StyledArrowLeft onClick={toggle} size={size ?? 16} color={color} />
        </QuestionWrapper>
      </Tooltip>
    </span>
  )
}
