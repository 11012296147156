import farmList from 'constants/farmsV2'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ChainId } from 'shardex-sdk'
import styled from 'styled-components'
import { LinkExternal, Text } from '../../uikit'
import { getKCCExplorerLink } from '../../utils'
import { SwapButton } from '../Button'
import { AutoColumn } from '../Column'
import { ConfirmedIcon, ContentHeader, Section, Wrapper } from './helpers'

type TransactionSubmittedContentProps = {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  pid?: number
}

const SuccessImg = styled.img`
  height: 100px;
  width: 100px;
`

const ImgSuccess = require('assets/images/icon/success.png').default

const TransactionSubmittedContent = ({ onDismiss, chainId, hash, pid }: TransactionSubmittedContentProps) => {
  const { t } = useTranslation()

  const targetPair = pid ? farmList.filter((item) => item.pid === pid && item.torchesSupport) : []

  return (
    <Wrapper>
      <Section>
        <ContentHeader onDismiss={onDismiss}>{t('COMPONENTS_158')}</ContentHeader>
        <ConfirmedIcon>
          <SuccessImg src={ImgSuccess} />
          {/* <ArrowUpCircle strokeWidth={1} size={70} color={theme.colors.primary} /> */}
        </ConfirmedIcon>
        <AutoColumn gap="8px" justify="center">
          {chainId && hash && (
            <Text color="primary">
              <LinkExternal href={getKCCExplorerLink(chainId, hash, 'transaction')}>{t('COMPONENTS_159')}</LinkExternal>
            </Text>
          )}
          {targetPair.length ? null : (
            <SwapButton style={{ height: '42px' }} onClick={onDismiss} mt="20px">
              <Text color="#fff" fontSize="16px" fontWeight={500}>
                {t('COMPONENTS_50')}
              </Text>
            </SwapButton>
          )}
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export default TransactionSubmittedContent
