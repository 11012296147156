import React from 'react'
import styled from 'styled-components'
import { Tag as UTag, CommunityIcon } from 'uikit'
import { useTranslation } from 'react-i18next'
import { ETHER } from 'shardex-sdk'
import useTheme from 'hooks/useTheme'

const Tag = styled(UTag)`
  border: none;
  font-size: 14px;
  height: 25px;
  padding: 0 14px;
`

const CoreTag = (props) => {
  const { t } = useTranslation()
  return (
    <Tag style={{ color: '#5C95E8', background: '#E3ECFC' }} outline {...props}>
      {t('COMPONENTS_169')}
    </Tag>
  )
}

const CommunityTag = (props) => {
  const { t } = useTranslation()
  return (
    <Tag variant="textSubtle" outline startIcon={<CommunityIcon color="secondary" />} {...props}>
      {t('COMPONENTS_170')}
    </Tag>
  )
}

const KcsTag = (props) => {
  return (
    <Tag style={{ color: '#FF3093', background: '#D7F8EE' }} outline {...props}>
      {ETHER.symbol}
    </Tag>
  )
}

const AutoTag = (props) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  return (
    <Tag style={{ color: '#fff', background: theme.colors.primary }} outline {...props}>
      {t('COMPONENTS_172')}
    </Tag>
  )
}

const ManualTag = (props) => {
  const { t } = useTranslation()
  return (
    <Tag outline {...props} style={{ color: '#5C95E8', background: '#E3ECFC' }}>
      {t('COMPONENTS_173')}
    </Tag>
  )
}

export { CoreTag, CommunityTag, KcsTag, AutoTag, ManualTag }
