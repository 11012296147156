/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import BigNumber from 'bignumber.js'
import useToast from 'hooks/useToast'
import useTokenBalance from 'hooks/useTokenBalance'
import { PublicIfoData, WalletIfoData } from 'pages/Ifos/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBlockNumber } from 'state/application/hooks'
import { Button, useModal } from 'uikit'
import { Ifo, PoolIds } from '../../../../../constants/types'
// import { ToastDescriptionWithTx } from 'components/Toast'
import ContributeModal from './ContributeModal'
import GetTokenModal from './GetTokenModal'
// import { useProfile } from 'state/profile/hooks'

interface Props {
  poolId: PoolIds
  ifo: Ifo
  publicIfoData: PublicIfoData
  walletIfoData: WalletIfoData
}
const ContributeButton: React.FC<Props> = ({ poolId, ifo, publicIfoData, walletIfoData }) => {
  const publicPoolCharacteristics = publicIfoData[poolId]
  const userPoolCharacteristics = walletIfoData[poolId]
  const isLived = publicIfoData[poolId].status === 'live'
  const { isPendingTx, amountTokenCommittedInLP } = userPoolCharacteristics
  const { limitPerUserInLP } = publicPoolCharacteristics
  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const currentBlock = useBlockNumber()
  const { balance: userCurrencyBalance } = useTokenBalance(ifo.currency.address)
  // const { hasProfile, profile } = useProfile()

  // Refetch all the data, and display a message when fetching is done
  const handleContributeSuccess = async (amount: BigNumber, txHash: string) => {
    try {
      await Promise.all([publicIfoData.fetchIfoData(currentBlock), walletIfoData.fetchIfoData()])
    } catch (e) {
      console.log('fetch info error =', e)
    }
    toastSuccess(
      t('LAUNCHPAD_21')
      // <ToastDescriptionWithTx txHash={txHash}>
      //   {t('You have contributed %amount% CAKE to this IFO!', {
      //     amount: getBalanceNumber(amount),
      //   })}
      // </ToastDescriptionWithTx>,
    )
  }
  const [onPresentContributeModal] = useModal(
    <ContributeModal
      poolId={poolId}
      ifo={ifo}
      publicIfoData={publicIfoData}
      walletIfoData={walletIfoData}
      onSuccess={handleContributeSuccess}
      userCurrencyBalance={userCurrencyBalance}
    />,
    false
  )

  const [onPresentGetTokenModal] = useModal(<GetTokenModal currency={ifo.currency} />, false)

  const isDisabled =
    isPendingTx ||
    (limitPerUserInLP.isGreaterThan(0) && amountTokenCommittedInLP.isGreaterThanOrEqualTo(limitPerUserInLP)) ||
    !isLived
  // (limitPerUserInLP.isGreaterThan(0) && amountTokenCommittedInLP.isGreaterThanOrEqualTo(limitPerUserInLP)) || !isLived || !hasProfile || !profile?.isActive

  return (
    <Button
      onClick={userCurrencyBalance.isEqualTo(0) ? onPresentGetTokenModal : onPresentContributeModal}
      width="100%"
      height="41px"
      disabled={isDisabled}
    >
      {isDisabled ? (!isLived ? t('LAUNCHPAD_22') : t('LAUNCHPAD_23')) : t('LAUNCHPAD_22')}
      {/* {isDisabled ?  (!isLived || !hasProfile || !profile?.isActive ? t('LAUNCHPAD_22') : t('LAUNCHPAD_23')) : t('LAUNCHPAD_22')} */}
    </Button>
  )
}

export default ContributeButton
