import { useContext } from 'react'
import { ThemeContext as StyledThemeCopntext } from 'styled-components'
import { ThemeContext } from '../ThemeContext'
import { PancakeTheme } from '../uikit/theme/index'

// export function useThemeManager(): [boolean, () => void] {
//   const dispatch = useDispatch<AppDispatch>()
//   const isDark = useSelector<AppState, AppState['user']['isDark']>((state) => state.user.isDark)

//   const toggleTheme = useCallback(() => {
//     dispatch(toggleThemeAction())
//   }, [dispatch])

//   return [isDark, toggleTheme]
// }

const useTheme = () => {
  const { isDark, toggleTheme } = useContext(ThemeContext)
  const theme: PancakeTheme = useContext(StyledThemeCopntext as any)
  return { isDark, toggleTheme, theme }
}

export default useTheme
