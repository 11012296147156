import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Link, Modal } from 'uikit'
import ModalActions from 'components/ModalActions'
import TokenInput from 'components/TokenInput'
import { getFullDisplayBalance } from 'utils/formatBalance'
import styled from 'styled-components'
import BN from 'bignumber.js'
import { Text } from 'uikit'
import { useResponsive } from '../../../hooks/useResponse'
import { useTranslation } from 'react-i18next'
import { AnimationButton } from 'components/Button'
import AmountButtonList from '../../Farms/components/AmountButtonList'

interface WithdrawModalProps {
  max: BigNumber
  onConfirm: (amount: string, decimals: number) => void
  onDismiss?: () => void
  tokenName?: string
  stakingTokenDecimals?: number
}

const StyledErrorMessage = styled(Text)`
  position: relative;
  margin-top: 10px;
  padding-left: 10px;
  a {
    display: inline;
  }
`

const ModalActionButton = styled(AnimationButton)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 100%;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  font-weight: 500;
  margin-top: 17px;
`

const WithdrawModal: React.FC<WithdrawModalProps> = ({
  onConfirm,
  max,
  tokenName = '',
  stakingTokenDecimals = 18,
  ...props
}) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const { isMobile } = useResponsive()

  const isBalanceZero = !max

  /* const fullBalance = useMemo(() => {
    console.log('max=======', max.toString())
    const displayBalance = new BigNumber(max).dividedBy(10 ** stakingTokenDecimals)

    if (displayBalance.gt(0) && displayBalance.lte(0.01)) {
      return new BigNumber(displayBalance).toString()
    }
    return new BigNumber(new BigNumber(displayBalance).toFixed(2, 1)).toString()
  }, [max, stakingTokenDecimals]) */

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal]
  )

  const fullBalance = useMemo(() => {
    // console.log('max', getFullDisplayBalance(max))
    return getFullDisplayBalance(max)
  }, [max])

  const isValidAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  return (
    <Modal
      title={t(`Withdraw ${tokenName}`)}
      onDismiss={props?.onDismiss}
      style={{ width: isMobile ? '96%' : '440px' }}
    >
      <TokenInput
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
      />

      {isBalanceZero && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('POOL_38')}:
          <Link fontSize="14px" bold={false} href="/swap" external color="failure">
            {t('POOL_39')} {tokenName}
          </Link>
        </StyledErrorMessage>
      )}

      {!isValidAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('POOL_40')}
        </StyledErrorMessage>
      )}

      <AmountButtonList max={fullBalance} setValue={setVal} styles={{ marginTop: '16px' }} />

      <ModalActions>
        <ModalActionButton
          disabled={pendingTx || !isValidAmmount || !val || new BN(val).lte(0)}
          onClick={async () => {
            setPendingTx(true)
            try {
              await onConfirm(val, stakingTokenDecimals)
              // @ts-ignore
              props?.onDismiss()
            } finally {
              setPendingTx(false)
            }
          }}
        >
          {pendingTx ? t('POOL_57') : t('POOL_35')}
        </ModalActionButton>
      </ModalActions>
    </Modal>
  )
}

export default WithdrawModal
