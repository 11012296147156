import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { provider as ProviderType } from 'web3-core'
import { getAddress } from 'utils/addressHelpers'
import { getLpContract } from 'utils/contractHelpers'
import { Button, Flex, Text } from 'uikit'
import { Farm } from 'state/types'
import { useFarmFromSymbol, useFarmUser } from 'state/hooks'
import useWeb3 from 'hooks/useWeb3'
import { useApprove } from 'hooks/useApprove'
import UnlockButton from 'components/UnlockButton'
import StakeAction from './StakeAction'
import HarvestAction from './HarvestAction'
import Elipisis from 'components/Elipisis/Elipisis'
import { useTranslation } from 'react-i18next'

const Action = styled.div`
  padding-top: 16px;
`

const UnlockColorButton = styled(UnlockButton)`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  height: 48px;
  border-radius: 30px;
  margin-top: 16px;
  box-shadow: none;
`
export interface FarmWithStakedValue extends Farm {
  apr?: number
}

interface FarmCardActionsProps {
  farm: FarmWithStakedValue
  provider?: ProviderType
  account?: string
  addLiquidityUrl?: string
}

const FarmButton = styled(Button)<{ disabled: boolean }>`
  background: ${({ disabled }) => {
    if (disabled) {
      return '#E5E7E8'
    }
    return 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)'
  }};
  height: 48px;
  border-radius: 30px;
  margin-top: 16px;
  box-shadow: none;
  color: #fff;
`

const CardActions: React.FC<FarmCardActionsProps> = ({ farm, account, addLiquidityUrl }) => {
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { pid, lpAddresses } = useFarmFromSymbol(farm.lpSymbol, farm?.version)
  const { allowance, tokenBalance, stakedBalance, earnings, doubleDigEarnings } = useFarmUser(pid, farm?.version)
  const lpAddress = getAddress(lpAddresses)
  const lpName = farm.lpSymbol.toUpperCase()
  const isApproved = account && allowance && allowance.isGreaterThan(0)
  const { t } = useTranslation()

  const web3 = useWeb3()

  const lpContract = getLpContract(lpAddress, web3)

  const { onApprove } = useApprove(lpContract)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApprove])

  const renderApprovalOrStakeButton = () => {
    return isApproved ? (
      <StakeAction
        stakedBalance={stakedBalance}
        tokenBalance={tokenBalance}
        tokenName={lpName}
        pid={pid}
        addLiquidityUrl={addLiquidityUrl}
        version={farm?.version ?? 1}
      />
    ) : (
      <FarmButton mt="16px" width="100%" disabled={requestedApproval || farm?.hasMove} onClick={handleApprove}>
        <Text color="#fff" style={{ fontWeight: '500' }}>
          {t('FARM_56')}
        </Text>
        {requestedApproval && <Elipisis styles={{ marginLeft: '4px', color: '#fff' }} />}
      </FarmButton>
    )
  }

  return (
    <Action>
      <HarvestAction
        earnings={earnings}
        doubleDigEarnings={doubleDigEarnings}
        pid={pid}
        version={farm?.version ?? 1}
        doubleDigToken={farm?.doubleDigToken}
      />
      <Flex style={{ marginTop: '20px' }}>
        <Text textTransform="uppercase" color="primary" fontSize="16px" pr="3px" fontWeight={500}>
          {lpName}
        </Text>
        <Text color="textSubtle" fontSize="16px" fontWeight={500}>
          {t('FARM_35')}
        </Text>
      </Flex>
      {!account ? <UnlockColorButton mt="0px" width="100%" /> : renderApprovalOrStakeButton()}
    </Action>
  )
}

export default CardActions
