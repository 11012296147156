import React, { useMemo, useState, useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import usePersistState from 'hooks/usePersistState'
import { usePools } from 'state/hooks'
import { useBlockNumber } from 'state/application/hooks'
import FlexLayout from 'components/layout/Flex'
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'
import { useLocation } from 'react-router'

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

export const FilterSizeContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const SyrupV2: React.FC = () => {
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  const { account } = useWeb3React()
  const { poolsV2: pools } = usePools(account)
  const currentBlock = useBlockNumber()
  const [stakedOnly, setStakedOnly] = usePersistState(false, 'mojito_pool_staked')
  const [autoPoolRewardPercent, setAutoPercent] = useState(0.5)

  const [finishedPools, openPools] = useMemo(
    () => partition(pools, (pool: any) => pool.isFinished || (currentBlock ?? 0) > pool?.endBlock),
    [currentBlock, pools]
  )
  const stakedOnlyPools = useMemo(
    () => openPools.filter((pool) => pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)),
    [openPools]
  )

  useEffect(() => {
    if (openPools.length >= 2) {
      const autoPool = openPools.filter((item) => item.poolCategory === 'Auto')
      const manualPool = openPools.filter((item) => item.poolCategory === 'Manual')
      const autoPercent = new BigNumber(autoPool[0].totalStaked ?? 0)
        .dividedBy(new BigNumber(manualPool[0].totalStaked ?? 0))
        .toFixed(4, 1)
      setAutoPercent(Number(autoPercent))
    }
  }, [openPools])

  return (
    <>
      {/* <Text fontSize="28px" bold mt="48px" mb="20px" style={{ marginLeft: isMobile ? '12px' : '0' }}>
        {t('POOL_68')}
      </Text> */}

      <PoolTabButtons stakedOnly={stakedOnly} setStakedOnly={setStakedOnly} version={2} />
      <Container>
        <FlexLayout style={{ justifyContent: 'center' }}>
          <Route path={`${path}`}>
            <>
              {pathname.includes('V2')
                ? null
                : stakedOnly
                ? orderBy(stakedOnlyPools, ['sortOrder']).map((pool) => {
                    const finished =
                      pool.sousId === 0 || !pool?.endBlock ? false : (currentBlock ?? 0) > (pool?.endBlock ?? 0)
                    return (
                      <PoolCard
                        key={pool.sortOrder}
                        pool={pool}
                        finished={finished}
                        autoPoolRewardPercent={autoPoolRewardPercent}
                      />
                    )
                  })
                : orderBy(openPools, ['sortOrder']).map((pool) => {
                    const finished =
                      pool.sousId === 0 || !pool?.endBlock ? false : (currentBlock ?? 0) > (pool?.endBlock ?? 0)
                    return (
                      <PoolCard
                        key={pool.sortOrder}
                        pool={pool}
                        finished={finished}
                        autoPoolRewardPercent={autoPoolRewardPercent}
                      />
                    )
                  })}
            </>
          </Route>
          <Route exact path={`${path}/historyV2`}>
            {orderBy(finishedPools, ['sortOrder']).map((pool) => {
              const finished =
                pool.sousId === 0 || !pool?.endBlock ? false : (currentBlock ?? 0) > (pool?.endBlock ?? 0)
              return (
                <PoolCard
                  key={pool.sortOrder}
                  pool={pool}
                  finished={finished}
                  autoPoolRewardPercent={autoPoolRewardPercent}
                />
              )
            })}
          </Route>
        </FlexLayout>
      </Container>
    </>
  )
}

export default SyrupV2
