import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import BN from 'bignumber.js'

const AmountButtonListWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Button = styled.div<{ isActive: boolean }>`
  width: 72px;
  height: 36px;
  cursor: pointer;
  background: ${({ isActive, theme }) => {
    if (isActive) {
      return theme.colors.primary
    }
    return '#efeff2'
  }};
  color: ${({ isActive }) => {
    if (isActive) {
      return '#fff'
    }
    return '#040A2D'
  }};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.md} {
    width: 92px;
  } ;
`

const list = [
  {
    multiplier: 0.25,
  },
  {
    multiplier: 0.5,
  },
  {
    multiplier: 0.75,
  },
  {
    multiplier: 1,
  },
]

type Props = {
  max: string
  setValue: any
  styles: CSSProperties
}

const AmountButtonList: React.FC<Props> = ({ max, setValue, styles }) => {
  const [active, setActive] = React.useState<number>(-1)

  const validMax = React.useMemo(() => {
    if (Number.isNaN(Number(max)) || Number(max) <= 0) {
      return false
    }
    return true
  }, [max])

  const handleSelect = React.useCallback(
    (index: number) => {
      setActive(() => index)
      if (validMax) {
        if (index !== 3) {
          setValue(() => new BN(new BN(max).multipliedBy(list[index].multiplier).toFixed(18, 1)).toNumber().toString())
        } else {
          setValue(() => max)
        }
      } else {
        setValue(() => '0')
      }
    },
    [max, setValue, validMax]
  )

  return (
    <AmountButtonListWrap style={styles}>
      {list.map((l, index) => {
        return (
          <Button key={index} onClick={() => handleSelect(index)} isActive={active === index}>
            {l.multiplier * 100}%
          </Button>
        )
      })}
    </AmountButtonListWrap>
  )
}

export default AmountButtonList
