import styled, { DefaultTheme } from 'styled-components'
import { space, typography, layout } from 'styled-system'
import getThemeValue from '../../util/getThemeValue'
import { TextProps } from './types'

interface ThemedProps extends TextProps {
  theme: DefaultTheme
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(`colors.${color}`, color)(theme)
}

const Text = styled.div<TextProps>`
  color: ${getColor};
  font-size: 16px;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  line-height: 1.5;
  font-family: 'Poppins';
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  ${({ ellipsis }) =>
    ellipsis &&
    `white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}

  ${({ lines }) =>
    `-webkit-line-clamp: ${lines ?? 0};
    line-clamp: ${lines ?? 0};
    display: -webkit-box;
    white-space: ${lines ? 'normal' : 'none'};
    -webkit-box-orient: vertical;`}

  ${space}
  ${typography}
  ${layout}
`

Text.defaultProps = {
  color: 'text',
  small: false,
  ellipsis: false,
}

export default Text
