import { Token, ChainId } from 'shardex-sdk'

import tokens from './tokens'

import { Ifo } from './types'

import i18next from '../i18n'

// testnet config
// export const sdtTestToken = new Token(ChainId.TESTNET, '0x18290CF820b0C9b6468B7C315cA57c3608C06732', 18, 'SDT')

// mainnet config
export const swapUsdtLpToken = new Token(
  ChainId.MAINNET,
  '0x4A880121604db56e64985314c5B45A5FE0717651',
  18,
  'SWAP/USDT LP'
)

export const swapUsdtToken = new Token(ChainId.MAINNET, tokens.usdt.address[ChainId.MAINNET], 18, 'USDT')
export const swapLpToken = new Token(ChainId.MAINNET, tokens.lp.address[ChainId.MAINNET], 18, 'LP')

const ifos: Ifo[] = [
  {
    id: 'sdt',
    address: '0x80F26B3F580ef2632A95c0313E224d64baC2Affc',
    unlimitedAddress: '0x7d3bA5569298c70Fa5e2fd630E5c3c73333b892E',
    isActive: true,
    name: 'LAUNCHPAD Coin (SDT)',
    poolBasic: {
      saleAmount: '450,000',
      raiseAmount: '$45,000',
      SDTToBurn: '$22，500',
      distributionRatio: 0.5,
      whitelist: true,
      tokenOfferingPrice: 0.1,
      NFT: '0xF9E109bFE4BBfb5D1090728C3b095761f0890bD9',
    },
    poolUnlimited: {
      saleAmount: '50,000',
      raiseAmount: '$5,000',
      SDTToBurn: '$2,500',
      distributionRatio: 0.5,
      whitelist: false,
      tokenOfferingPrice: 0.2,
      releaser: '0xED2E5A3E374F00A06D22e677522aC3E97D587986',
      claimEpoch1: 126261,
      claimEpoch2: 126461,
    },
    vesterTime: 1651120200000,
    totalSale: '500,000',
    targetRaise: '$50,000',
    lpToken: '/USDT',
    currency: swapLpToken,
    token: tokens.sdt,
    releaseBlockNumber: 125661,
    endBlockNumber: 126061,
    campaignId: '1',
    tokenOfferingPrice: 0.1,
    version: 2,
    logo: require('assets/images/launchpad/pikaster-logo.png').default,
    banner: require('assets/images/launchpad/pikaster-banner.jpg').default,
    description:
      "Pikaster is a card battle game featuring Pikaster (NFT). It's the first Game-Fi project created by Metaland.",
    twitterUrl: 'https://twitter.com/PikasterNFT',
    telegramUrl: 'https://t.me/Pikasterofficialchat',
    discordUrl: 'https://discord.gg/xWCZTU8v7K',
    facebookUrl: '',
    websiteUrl: 'https://www.pikaster.com/',
    qaUrl: '',
    introduction:
      'Pikaster is a community-driven metaverse created by Metaland, aiming to create diverse scenarios such as various games, social, entertainment, sports, etc to enable players to participate in and manage the ecology together and earn great financial rewards while gaining new experiences. Pikaster was founded in 2021 by a team with previous core members from Blizzard, and Supercell, and received strategic investment from KuCoin and many other investors.',
    highlight: '',
    totalSupply: '200,000,000',
    initialSupply: '500,000',
    userCap: '100',
    tokenVestingPeriod: '',
    tokenDistribution: '',
  },
]

const ifosPro: Ifo[] = []

export const ifoIntro = {
  step: [
    {
      title: i18next.t('LAUNCHPAD_108'),
      content: i18next.t('LAUNCHPAD_109'),
      statusColor: '#14D89D',
      imgNumber: require('../assets/images/invite/1.svg').default,
      imgBg: require('../assets/images/home/green.svg').default,
    },
    {
      title: i18next.t('LAUNCHPAD_110'),
      content: i18next.t('LAUNCHPAD_111'),
      statusColor: '#FFA952',
      imgNumber: require('../assets/images/invite/2.svg').default,
      imgBg: require('../assets/images/home/orange.svg').default,
    },
    {
      title: i18next.t('LAUNCHPAD_112'),
      content: i18next.t('LAUNCHPAD_113'),
      statusColor: '#39D9FF',
      imgNumber: require('../assets/images/invite/3.svg').default,
      imgBg: require('../assets/images/home/blue.svg').default,
    },
  ],
}

const mainnetEnvName = ['production-mainnet', 'preview']

const isMainnet = mainnetEnvName.includes(process.env.REACT_APP_ENV_NAME ?? '')

export default isMainnet ? ifosPro : ifos
