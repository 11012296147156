import React from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import clickEvent from 'utils/gtag'
import Accordion from './Accordion'
import { MenuEntry, LinkLabel } from './MenuEntry'
import MenuLink from './MenuLink'
import { PanelProps, PushedProps } from '../types'
import { MenuArrow } from '../../../components/Svg'
import { Flex } from 'uikit/components/Box'

interface Props extends PanelProps, PushedProps {
  isMobile: boolean
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  width: 100vw;
  padding-top: 20px;
`

const ImgArrow = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`

const MenuImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: brightness(0) invert(1);
`

const PanelBody: React.FC<Props> = ({ isPushed, pushNav, isMobile, links }) => {
  const location = useLocation()

  // Close the menu when a user clicks a link on mobile
  const handleClick = (item) => {
    clickEvent(item?.ga)
    if (isMobile) {
      pushNav(false)
    }
  }

  return (
    <Container>
      {links.map((entry: any) => {
        const isSubLink =
          entry?.href === location.pathname ||
          (entry?.href !== '' &&
            entry?.href !== '/' &&
            entry?.href === '/nfts/profile' &&
            location.pathname.includes(entry?.href))
        // const iconElement = <Icon mr="8px" isActive={isSubLink} />
        const calloutClass = entry.calloutClass ? entry.calloutClass : undefined

        // dropdown
        if (entry.items) {
          const itemsMatchIndex = entry.items.findIndex((item) => item?.href === location.pathname)
          const initialOpenState = entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0

          return (
            <Accordion
              key={entry.label}
              isPushed={isPushed}
              pushNav={pushNav}
              label={entry.label === '...' ? 'More' : entry.label}
              initialOpenState={initialOpenState}
              className={calloutClass}
              isActive={false}
            >
              {isPushed &&
                entry.items.map((item) => (
                  <MenuEntry
                    key={item.href}
                    secondary
                    isSecond={true}
                    onClick={() => {
                      handleClick(item)
                    }}
                    canClick={true}
                    style={{ padding: '0 16px' }}
                  >
                    <MenuLink href={entry?.disabled !== true ? item.href : ''}>
                      <Flex alignItems="center" style={{ width: '100%' }}>
                        {item.icon ? <MenuImg src={item.icon} /> : null}
                        <LinkLabel
                          isSecond={true}
                          isPushed={isPushed}
                          style={{ fontSize: '16px', whiteSpace: 'pre-line' }}
                        >
                          {item.label}
                        </LinkLabel>
                      </Flex>
                      <MenuArrow />
                    </MenuLink>
                  </MenuEntry>
                ))}
            </Accordion>
          )
        }

        const canClick = entry.href !== '' && entry.disabled !== true

        if (entry.label === 'Reward') {
          return (
            <MenuEntry key={entry.label} canClick={canClick} isActive={isSubLink} className={calloutClass}>
              <MenuLink href={entry?.disabled !== true ? entry.href : ''} onClick={() => handleClick(entry)}>
                <Flex style={{ width: '100%' }}>
                  <ImgArrow src={require('assets/images/icon/reward.svg').default} />
                  <LinkLabel isPushed={isPushed} style={{ color: '#FFC530' }}>
                    {entry.label}
                  </LinkLabel>
                </Flex>
                <MenuArrow color="#FFC530" />
              </MenuLink>
            </MenuEntry>
          )
        }

        return (
          <MenuEntry key={entry.label} canClick={canClick} isActive={isSubLink} className={calloutClass}>
            <MenuLink href={entry?.disabled !== true ? entry.href : ''} onClick={() => handleClick(entry)}>
              <LinkLabel isPushed={isPushed}>{entry.label}</LinkLabel>
            </MenuLink>
          </MenuEntry>
        )
      })}
    </Container>
  )
}

export default PanelBody
