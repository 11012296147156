import { Currency, ETHER, JSBI, TokenAmount } from 'shardex-sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, ChevronDownIcon, CardBody, Text } from '../../uikit'
import { LightCard } from 'components/Card'
import { AutoColumn, ColumnCenter } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { FindPoolTabs } from 'components/NavigationTabs'
import { MinimalPositionCard } from 'components/PositionCard'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { usePairAdder } from 'state/user/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { StyledInternalLink } from 'components/Shared'
import Container from 'components/Container'
import { currencyId } from 'utils/currencyId'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import SelectTab from 'components/PageHeader/SelectTab'
import AddIcon from 'components/AddIcon'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

const SwapButton = styled(Button)`
  height: 52px;
  font-size: 16px;
  font-weight: 500;
  background: #efeff2;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.text};
`

export default function PoolFinder() {
  const { account } = useActiveWeb3React()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  const addPair = usePairAdder()

  const { t } = useTranslation()

  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text style={{ textAlign: 'center' }} color="#7F8393" fontSize="14px">
        {!account ? t('POOL_11') : t('POOL_12')}
      </Text>
    </LightCard>
  )

  return (
    <Container>
      {/* <CardNav activeIndex={1} /> */}
      <Text color="white" fontSize="32px" bold mt="128px" mb="40px">
        One-Stop-Solution For Decentralized Trading
      </Text>
      <AppBody>
        <SelectTab />
        <FindPoolTabs />
        <CardBody>
          <AutoColumn gap="md">
            <SwapButton
              onClick={() => {
                setShowSearch(true)
                setActiveField(Fields.TOKEN0)
              }}
              startIcon={currency0 ? <CurrencyLogo currency={currency0} style={{ marginRight: '.5rem' }} /> : null}
              endIcon={<ChevronDownIcon width="24px" color="textRemark" />}
              // width="100%"
            >
              {currency0 ? currency0.symbol : t('POOL_13')}
            </SwapButton>

            <ColumnCenter>
              <AddIcon />
            </ColumnCenter>

            <SwapButton
              onClick={() => {
                setShowSearch(true)
                setActiveField(Fields.TOKEN1)
              }}
              startIcon={currency1 ? <CurrencyLogo currency={currency1} style={{ marginRight: '.5rem' }} /> : null}
              endIcon={<ChevronDownIcon width="24px" color="textRemark" />}
              // width="100%"
            >
              {currency1 ? currency1.symbol : t('POOL_13')}
            </SwapButton>

            {hasPosition && (
              <ColumnCenter
                style={{ justifyItems: 'center', backgroundColor: '', padding: '12px 0px', borderRadius: '12px' }}
              >
                <Text style={{ textAlign: 'center' }}>{t('POOL_14')}</Text>
              </ColumnCenter>
            )}

            {currency0 && currency1 ? (
              pairState === PairState.EXISTS ? (
                hasPosition && pair ? (
                  <MinimalPositionCard pair={pair} width="auto" />
                ) : (
                  <LightCard padding="20px 10px">
                    <AutoColumn gap="sm" justify="center">
                      <Text color="#7F8393" style={{ textAlign: 'center' }}>
                        {t('POOL_15')}
                      </Text>
                      <Text color="#7F8393" style={{ textAlign: 'center' }}>
                        {t('POOL_16')}
                      </Text>
                    </AutoColumn>
                  </LightCard>
                )
              ) : validPairNoLiquidity ? (
                <LightCard padding="20px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text color="#7F8393" style={{ textAlign: 'center' }}>
                      {t('POOL_17')}
                    </Text>
                    <StyledInternalLink to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}>
                      {t('POOL_18')}
                    </StyledInternalLink>
                  </AutoColumn>
                </LightCard>
              ) : pairState === PairState.INVALID ? (
                <LightCard padding="20px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text color="#7F8393" style={{ textAlign: 'center' }}>
                      {t('POOL_19')}
                    </Text>
                  </AutoColumn>
                </LightCard>
              ) : pairState === PairState.LOADING ? (
                <LightCard padding="20px 10px">
                  <AutoColumn gap="sm" justify="center">
                    <Text color="#7F8393" style={{ textAlign: 'center' }}>
                      {t('POOL_6')}
                      <Dots />
                    </Text>
                  </AutoColumn>
                </LightCard>
              ) : null
            ) : (
              prerequisiteMessage
            )}
          </AutoColumn>

          <CurrencySearchModal
            isOpen={showSearch}
            onCurrencySelect={handleCurrencySelect}
            onDismiss={handleSearchDismiss}
            showCommonBases
            selectedCurrency={(activeField === Fields.TOKEN0 ? currency1 : currency0) ?? undefined}
          />
        </CardBody>
      </AppBody>
    </Container>
  )
}
