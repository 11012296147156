import React, { useCallback, useMemo, useState } from 'react'
import { Modal, LinkExternal, Text } from 'uikit'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { getFullDisplayBalance } from 'utils/formatBalance'
import styled from 'styled-components'
import BN from 'bignumber.js'
import { useResponsive } from '../../../hooks/useResponse'
import { useTranslation } from 'react-i18next'
import AmountButtonList from './AmountButtonList'
import { AnimationButton } from 'components/Button'
import Column from 'components/Column'
import useToast from 'hooks/useToast'

const StyledErrorMessage = styled(Text)`
  position: relative;
  margin: 4px 0 0px 0;
  a {
    display: inline;
  }
`

const ModalActionButton = styled(AnimationButton)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 100%;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  font-weight: 500;
  margin-top: 17px;
`

const DepositModal: React.FC<any> = ({ max, onConfirm, onDismiss, tokenName = '', addLiquidityUrl, onRouter }) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const { toastError } = useToast()
  const { isMobile } = useResponsive()
  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setVal(e.currentTarget.value)
  }, [])

  const isValidAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    if (!/^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$/.test(val) && !/^[1-9]\d*$/.test(val)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const insufficentAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  return (
    <Modal title={t('FARM_73')} onDismiss={onDismiss} style={{ width: isMobile ? '96%' : '440px' }}>
      <ModalInput
        isValidAmmount={isValidAmmount}
        value={val}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        max={fullBalance}
        symbol={tokenName}
        addLiquidityUrl={addLiquidityUrl}
        inputTitle={t('FARM_71')}
        styles={{ background: '#fff', borderRadius: '8px' }}
        inputWidth={isMobile ? '110px' : 'auto'}
      />
      {!insufficentAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('FARM_15')}
        </StyledErrorMessage>
      )}
      <AmountButtonList max={fullBalance} setValue={setVal} styles={{ marginTop: '16px' }} />
      <div>
        <ModalActions>
          {/* <ModalActionCancelButton onClick={onDismiss} width="100%" disabled={false}>
            {t('FARM_16')}
          </ModalActionCancelButton> */}
          <ModalActionButton
            width="100%"
            disabled={pendingTx || fullBalance === '0' || val === '0' || new BN(val).eq(0) || !val || !isValidAmmount}
            onClick={async () => {
              const Reg = /^([1-9][0-9]*|[0])(\.[0-9]{1,18})?$/
              if (!Reg.test(val)) {
                toastError('Invalid format', 'Please enter a valid number')
                return
              }
              setPendingTx(true)
              try {
                await onConfirm(val)
                onDismiss()
                if (onRouter) onRouter()
              } finally {
                setPendingTx(false)
              }
            }}
          >
            {pendingTx ? t('FARM_17') : t('FARM_18')}
          </ModalActionButton>
        </ModalActions>
      </div>

      <Column style={{ columnGap: '20px', justifyContent: 'center', width: '100%' }}>
        <LinkExternal href={addLiquidityUrl} style={{ alignSelf: 'center' }} color="#FF3093">
          <Text color="#FF3093">{t('FARM_72', { asset: tokenName })}</Text>
        </LinkExternal>
        {/* <LinkExternal href="/rolling" style={{ alignSelf: 'center' }} color="#FF3093">
          <Text color="#FF3093">{t('FARM_97')}</Text>
        </LinkExternal> */}
      </Column>
    </Modal>
  )
}

export default DepositModal
