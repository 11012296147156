import MenuList from 'components/Menu/config'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import 'rsuite/dist/rsuite.min.css'
import Dropdown from 'rsuite/Dropdown'
import styled from 'styled-components'
import { MenuEntry } from 'uikit'
import clickEvent from 'utils/gtag'
import { RowFixed } from '../../../components/Row'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import ProductIcon from './icons/Product'
import Panel from './components/Panel'
import { Text } from 'uikit/components/Text'
import useHover from 'react-use-hover'

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`

const StyledNav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: top 0.1s;
  width: 100%;
  z-index: 20;
  transform: translate3d(0, 0, 0);
  height: 80px;
  list-style: none;
  .rs-dropdown-toggle,
  .rs-dropdown-toggle.rs-btn {
    background: transparent;
    color: white;
    font-size: 16px;
    margin: 0 24px;
    padding: 0px;
    display: flex;
    padding-bottom: 10px;
    :hover {
      &.rs-btn-disabled {
        color: white;
        opacity: 0.75;
      }
      color: #ff3093;
    }
  }
  .rs-btn:focus-visible {
    outline: none;
  }
  .rs-dropdown-menu {
    width: 160px;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: none;
    padding: 12px 0;
    .rs-dropdown-item {
      display: flex;
      align-items: center;
      height: 48px;
      line-height: 32px;
      padding: 0 20px;
      font-size: 16px;
    }
    a {
      width: 100%;
      outline: none;
      text-decoration: none;
      color: #040a2d;
    }
    li::before {
      content: '';
      margin-right: 0px;
    }
    li {
      text-align: center;
    }
    .rs-dropdown-item {
      color: #040a2d;
      &:hover {
        background: #efeff2;
        color: #040a2d;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 15px;
  }
`

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BodyWrapper = styled.div`
  position: relative;
  display: flex;
`

const Inner = styled.div<{ isPushed: boolean }>`
  flex-grow: 1;
  // transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate3d(0, 0, 0);
  max-width: 100%;
`

const ImgMobileLogo = styled.img`
  width: 127px;
  height: 28px;
`

const ImgNavigation = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`

const StyledNavigationTitle = styled.span<{ isReward?: boolean }>`
  font-size: 16px;
  color: ${({ isReward }) => (isReward ? '#FFC530' : 'white')};
`

const StyleNavigationItem = styled(Flex)<{ isLastKey: boolean; disabled: boolean }>`
  align-items: center;
  margin: ${({ isLastKey }) => (isLastKey ? '0 24px' : '0 24px')};
  padding-bottom: 10px;
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ disabled }) => (disabled ? '0.75' : '1')};
  }
`

const ImgMenu = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

const Divider = styled.div`
  background: white;
  width: 1px;
  height: 14px;
  margin-bottom: 10px;
`

const MoreText = styled.div`
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin-top: -10px;
  letter-spacing: 0.15rem;
  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

const ProductContainer = styled(Flex)`
  align-items: center;
`

const MenuImg = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 12px;
`

interface NavigationItemProps extends MenuEntry {
  isLastKey: boolean
}

const NavigationItem: React.FC<NavigationItemProps> = ({
  label,
  icon,
  isLastKey,
  isReward,
  href,
  disabled = false,
}) => {
  return (
    <StyleNavigationItem
      isLastKey={isLastKey}
      disabled={disabled}
      onClick={() => {
        if (!disabled && !isLastKey) {
          window.location.href = href
        } else if (isLastKey) {
          window.open(href, '_target')
        }
      }}
    >
      {icon ? <ImgNavigation src={icon} /> : null}
      <StyledNavigationTitle isReward={isReward}>{label}</StyledNavigationTitle>
    </StyleNavigationItem>
  )
}

const Navigation: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
  const [isHoveringProduct, hoverProps] = useHover()
  const hoverColor = isHoveringProduct ? '#FF3093' : 'white'

  if (isMobile) return null
  return (
    <Flex style={{ marginTop: '15px', marginLeft: '60px', alignItems: 'center' }}>
      {MenuList.map((data, index) => {
        const isProduct = index === MenuList.length - 3
        const isMore = index === MenuList.length - 1
        console.log('MenuList =', MenuList, 'isProduct =', isProduct)
        if (data?.items?.length) {
          const title = isProduct ? (
            <ProductContainer {...hoverProps}>
              <ProductIcon color={hoverColor} />
              <Text ml="8px" color={hoverColor}>
                {data.label}
              </Text>
            </ProductContainer>
          ) : isMore ? (
            <MoreText>{data.label}</MoreText>
          ) : (
            data.label
          )
          return (
            <>
              {isProduct ? <Divider /> : null}
              <Dropdown
                title={title}
                disabled={data?.disabled ?? false}
                noCaret
                key={data.label}
                trigger="hover"
                placement={isProduct ? 'bottomEnd' : 'bottomStart'}
              >
                {data.items.map((subMenu, subIndex) => {
                  if (subMenu.href.includes('https')) {
                    return (
                      <a href={subMenu.href} target="_blank" rel="noopener noreferrer">
                        <Dropdown.Item
                          key={subIndex}
                          style={{ justifyContent: subMenu?.icon ? 'flex-start' : 'center' }}
                        >
                          {subMenu?.icon ? <MenuImg src={subMenu?.icon} /> : null}
                          {subMenu.label}
                        </Dropdown.Item>
                      </a>
                    )
                  }
                  return (
                    <Link to={subMenu.href} key={subMenu.label}>
                      <Dropdown.Item key={subIndex} style={{ justifyContent: subMenu?.icon ? 'flex-start' : 'center' }}>
                        {subMenu.label}
                        {/* {subMenu.disabled ? subMenu.label : <Link to={subMenu.href}>{subMenu.label}</Link>} */}
                      </Dropdown.Item>
                    </Link>
                  )
                })}
              </Dropdown>
            </>
          )
        }
        return <NavigationItem {...data} key={data.label} isLastKey={isProduct} />
      })}
    </Flex>
  )
}

const Menu: React.FC<NavProps> = ({ account, login, logout, isDark, links, pendingCount, children }) => {
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const [isPushed, setIsPushed] = useState(!isMobile)

  // Find the home link if provided
  const homeLink = links.find((link) => link.label === 'Home')

  const handleScroll = () => {
    const { scrollTop } = document.documentElement
    const getNav = document.getElementById('shardexNav')
    if (scrollTop >= 20) {
      getNav.style.background = '#000000'
    } else {
      getNav.style.background = 'transparent'
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Wrapper>
      <StyledNav id="shardexNav">
        <Container>
          <RowFixed style={{ marginTop: '10px' }}>
            {isMobile ? (
              <RowFixed>
                <ImgMenu
                  src={
                    isPushed
                      ? require(`assets/images/icon/m-close.svg`).default
                      : require(`assets/images/icon/menu.svg`).default
                  }
                  alt="menu"
                  onClick={() => {
                    setIsPushed((prevState: boolean) => !prevState)
                  }}
                />
                <a href={homeLink?.href ?? '/'}>
                  <ImgMobileLogo src={require('assets/images/icon/shardex-logo.svg').default} alt="logo" />
                </a>
              </RowFixed>
            ) : (
              <Logo
                isPushed={isPushed}
                togglePush={() => {
                  clickEvent('page_bar_logo')
                }}
                isDark={isDark}
                href={homeLink?.href ?? '/'}
              />
            )}
            <Navigation isMobile={isMobile} />
          </RowFixed>
          {!!login && !!logout && (
            <Flex alignItems="center" style={{ marginTop: '10px' }}>
              <UserBlock pendingCount={pendingCount} account={account} login={login} logout={logout} />
            </Flex>
          )}
        </Container>
      </StyledNav>
      <BodyWrapper>
        {isMobile ? (
          <Panel
            isPushed={isPushed}
            isMobile={isMobile}
            showMenu={true}
            isDark={isDark}
            pushNav={setIsPushed}
            links={links}
          />
        ) : null}

        <Inner isPushed={isPushed}>{children}</Inner>
      </BodyWrapper>
    </Wrapper>
  )
}

export default Menu
