import React, { useRef } from 'react'
import styled from 'styled-components'
import { Button, ChevronUpIcon, ColumnType, useTable } from 'uikit'

// eslint-disable-next-line import/named
import Column, { AutoColumn } from 'components/Column'
import { useTranslation } from 'react-i18next'
import FarmV1Row from './FarmV1Row'
import Row, { RowProps } from './Row'

export interface ITableProps {
  data: RowProps[]
  columns: ColumnType<RowProps>[]
  isMobile: boolean
  sortColumn?: string
  type?: IFarmType
}

export enum IFarmType {
  V1 = 'v1',
  V2 = 'v2',
}

const Container = styled.div`
  filter: ${({ theme }) => theme.card.dropShadow};
  width: 100%;
  max-width: 1200px;
  background: ${({ theme }) => theme.card.background};
  border-radius: 12px;
  margin: 0px auto;
`

const MobileContainer = styled.div`
  filter: ${({ theme }) => theme.card.dropShadow};
  width: 351px;
  min-height: 257px;
  background: ${({ theme }) => theme.card.background};
  border-radius: 16px;
  margin-top: 12px;
`

const TableWrapper = styled.div`
  overflow: visible;

  &::-webkit-scrollbar {
    display: none;
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  font-size: 14px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`

const TableBody = styled.tbody`
  & tr {
    td {
      font-size: 16px;
      vertical-align: middle;
    }
  }
`

const TableContainer = styled.div`
  position: relative;
`

const ScrollButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
`

const FarmTable: React.FC<ITableProps> = (props) => {
  const tableWrapperEl = useRef<HTMLDivElement | null>(null)
  const { t } = useTranslation()

  const { data, columns, type } = props
  const { rows } = useTable(columns, data, { sortable: true, sortColumn: 'farm' })
  const scrollToTop = (): void => {
    tableWrapperEl?.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  if (props.isMobile) {
    return (
      <AutoColumn justify="center">
        {/* {rows.length > 0 && <Title title={t('FARM_68')} version={2} style={{ margin: '5px 0' }} />} */}
        {rows.map((row) => {
          if (!row?.original?.details?.doubleDigPerBlock) {
            return null
          }
          return (
            <MobileContainer>
              <Row {...row.original} key={`table-row-${row.id}`} />
            </MobileContainer>
          )
        })}
        {/* {rows.length > 0 && <Title title={t('FARM_69')} version={1} style={{ margin: '20px 0 10px 0' }} />} */}
        {rows.map((row) => {
          if (row?.original?.details?.doubleDigPerBlock) {
            return null
          }
          return (
            <MobileContainer>
              <Row {...row.original} key={`table-row-${row.id}`} />
            </MobileContainer>
          )
        })}
      </AutoColumn>
    )
  }

  return (
    <Column style={{ alignItems: 'center', width: '100%' }}>
      {rows.length > 0 ? (
        <>
          {/* {type !== IFarmType.V1 && <Title title={t('FARM_68')} version={2} />} */}
          <Container style={{ marginBottom: '0px' }}>
            <TableContainer>
              <TableWrapper ref={tableWrapperEl}>
                <StyledTable>
                  <TableBody>
                    {rows.map((row) => {
                      if (type === IFarmType.V1) return <FarmV1Row {...row.original} key={`table-row-${row.id}`} />
                      if (!row?.original?.details?.doubleDigPerBlock) {
                        return null
                      }
                      return <Row {...row.original} key={`table-row-${row.id}`} />
                    })}
                  </TableBody>
                </StyledTable>
              </TableWrapper>
              {rows?.length > 6 && (
                <ScrollButtonContainer>
                  <Button variant="text" onClick={scrollToTop}>
                    {t('FARM_70')}
                    <ChevronUpIcon color="primary" />
                  </Button>
                </ScrollButtonContainer>
              )}
            </TableContainer>
          </Container>
        </>
      ) : null}
      {/* {rows.length > 0 && type !== IFarmType.V1 && <Title title={t('FARM_69')} version={1} />} */}
      {type !== IFarmType.V1 && (
        <Container>
          <TableContainer>
            <TableWrapper>
              <StyledTable>
                <TableBody>
                  {rows.map((row) => {
                    if (row?.original?.details?.doubleDigPerBlock) {
                      return null
                    }
                    return <Row {...row.original} key={`table-row-${row.id}`} />
                  })}
                </TableBody>
              </StyledTable>
            </TableWrapper>
          </TableContainer>
        </Container>
      )}
    </Column>
  )
}

export default FarmTable
