import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Text, Input, InputProps, Flex, Link } from 'uikit'
import { RowBetween } from '../Row/index'
import BN from 'bignumber.js'
import { useResponsive } from '../../hooks/useResponse'
import { useTranslation } from 'react-i18next'
import TokenImage from 'uikit/components/Image/TokenImage'
import { getTokenUrl } from '../../utils/getTokenUrl'

interface ModalInputProps {
  max: string
  symbol: string
  onSelectMax?: () => void
  onChange: (e: React.FormEvent<HTMLInputElement>) => void
  placeholder?: string
  value: string
  addLiquidityUrl?: string
  inputTitle?: string
  styles?: CSSProperties
  isValidAmmount?: boolean
  inputWidth?: string
}

const getBoxShadow = ({ isWarning = false, theme }) => {
  if (isWarning) {
    return theme.shadows.warning
  }

  return theme.shadows.inset
}

const StyledTokenInput = styled.div<InputProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.input};
  border-radius: 12px;
  // box-shadow: ${getBoxShadow};
  color: ${({ theme }) => theme.colors.text};
  padding: 0px;
  width: 100%;
`

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 100%;
  background: #efeff2;
  border: 1px solid transparent;
  height: 54px;
  border-radius: 8px;
  position: relative;
  font-size: 18px;
  &:focus:not(:disabled) {
    border: 1px solid #f5455b;
  }

  &:focus {
    border: 1px solid #f5455b;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    font-size: 18px;
  }
`

const StyledErrorMessage = styled(Text)`
  position: relative;
  top: 5px;
  a {
    display: inline;
  }
  margin-bottom: 5px;
`

const TokenWrap = styled.div`
  width: 50px;
  color: #fff;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
`

const InputSuffix = styled(RowBetween)`
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%) translateX(-16px);
  background: #efeff2;
  height: 96%;
`
const InputWrap = styled.div`
  position: relative;
  margin-top: 16px;
`

const ModalInput: React.FC<ModalInputProps> = ({
  max,
  symbol,
  onChange,
  value,
  addLiquidityUrl,
  inputTitle,
  styles,
}) => {
  const { t } = useTranslation()

  const { isMobile } = useResponsive()

  const isBalanceZero = max === '0' || !max

  const displayBalance = () => {
    if (isBalanceZero) {
      return '0'
    }

    const balanceNumber = new BN(max)

    if (balanceNumber.gt(0) && balanceNumber.lt(0.01)) {
      return balanceNumber.toString()
    }

    return balanceNumber.toFixed(2, 1)
  }

  const tokenImages = React.useMemo(() => {
    const images = symbol.split(' ')[0].toLowerCase()
    return images?.split('/') as string[]
  }, [symbol])

  return (
    <div style={{ position: 'relative' }}>
      <StyledTokenInput isWarning={isBalanceZero} style={styles}>
        <Flex justifyContent="space-between" alignItems="center">
          <Text color="#494E67" fontSize="14px">
            {inputTitle}
          </Text>
          <Text fontSize="14px" color="#494E67">
            {t('COMPONENTS_178')}: {displayBalance()}
          </Text>
        </Flex>

        <InputWrap>
          <StyledInput onChange={onChange} placeholder="0" value={value} />
          <InputSuffix>
            <TokenWrap>
              <TokenImage src={getTokenUrl(tokenImages[0])} width={24} height={24} />
              <TokenImage src={getTokenUrl(tokenImages[1])} width={24} height={24} style={{ marginLeft: '4px' }} />
            </TokenWrap>
            <Text
              fontSize={isMobile ? '12px' : '14px'}
              fontWeight={500}
              style={{ width: 'auto', lineHeight: '100%', textAlign: 'right', marginLeft: '10px' }}
            >
              {symbol}
            </Text>
          </InputSuffix>
        </InputWrap>
      </StyledTokenInput>
      {isBalanceZero && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('COMPONENTS_42')}
          <Link fontSize="14px" bold={false} href={addLiquidityUrl} external color="failure">
            {t('COMPONENTS_43')} {symbol}
          </Link>
        </StyledErrorMessage>
      )}
      {/* {!isBalanceZero && !isValidAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('FARM_15')} {symbol}
        </StyledErrorMessage>
      )} */}
    </div>
  )
}

export default ModalInput
