import BigNumber from 'bignumber.js'
import { useState, useCallback } from 'react'
import { CHAIN_BLOCK_TIME } from '../../../../constants'
import ifoV2Abi from 'constants/abis/ifoV2.json'
import { Ifo, IfoStatus } from 'constants/types'
import { ethers } from 'ethers'
import { useProjectTokenUSDT } from 'state/hooks'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { PublicIfoData } from '../../types'
import { getStatus } from '../helpers'

// https://github.com/pancakeswap/pancake-contracts/blob/master/projects/ifo/contracts/IFOV2.sol#L431
// 1,000,000,000 / 100
const TAX_PRECISION = ethers.FixedNumber.from(10000000000)

const formatPool = (pool) => ({
  raisingAmountPool: pool ? new BigNumber(pool[0].toString()) : BIG_ZERO,
  offeringAmountPool: pool ? new BigNumber(pool[1].toString()) : BIG_ZERO,
  limitPerUserInLP: pool ? new BigNumber(pool[2].toString()) : BIG_ZERO,
  hasTax: pool ? pool[3] : false,
  totalAmountPool: pool ? new BigNumber(pool[4].toString()) : BIG_ZERO,
  sumTaxesOverflow: pool ? new BigNumber(pool[5].toString()) : BIG_ZERO,
})

/**
 * Gets all public data of an IFO
 */
const useGetPublicIfoData = (ifo: Ifo): PublicIfoData => {
  const { address, unlimitedAddress, releaseBlockNumber } = ifo
  const cakePriceUsd = useProjectTokenUSDT()
  // const lpTokenPriceInUsd =
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  //   ifo.currency.symbol === BASE_TOKEN_SYMBOL || ifo.currency.symbol === 'TEST'
  //     ? new BN(0)
  //     : useLpTokenPrice(ifo.currency.symbol)
  const currencyPriceInUSD = cakePriceUsd

  const [state, setState] = useState({
    isInitialized: false,
    progress: 5,
    poolBasic: {
      raisingAmountPool: BIG_ZERO,
      offeringAmountPool: BIG_ZERO,
      limitPerUserInLP: BIG_ZERO,
      taxRate: 0,
      totalAmountPool: BIG_ZERO,
      sumTaxesOverflow: BIG_ZERO,
      startBlockNum: 0,
      endBlockNum: 0,
      blocksRemaining: 0,
      secondsUntilStart: 0,
      secondsUntilEnd: 0,
      status: 'idle' as IfoStatus,
    },
    poolUnlimited: {
      raisingAmountPool: BIG_ZERO,
      offeringAmountPool: BIG_ZERO,
      limitPerUserInLP: BIG_ZERO,
      taxRate: 0,
      totalAmountPool: BIG_ZERO,
      sumTaxesOverflow: BIG_ZERO,
      startBlockNum: 0,
      endBlockNum: 0,
      blocksRemaining: 0,
      secondsUntilStart: 0,
      secondsUntilEnd: 0,
      status: 'idle' as IfoStatus,
      unlimitedReleaser1UntilStart: 0,
      unlimitedReleaser2UntilStart: 0,
      unlimitedReleaser1hasStart: false,
      unlimitedReleaser2hasStart: false,
    },
    thresholdPoints: undefined,
    numberPoints: 0,
  })

  const fetchIfoData = useCallback(
    async (currentBlock: number) => {
      const [startBlock, endBlock, poolBasic, poolUnlimited, unlimitedStartBlock, unlimitedEndBlock, taxRate] =
        await multicall(ifoV2Abi, [
          {
            address,
            name: 'startBlock',
          },
          {
            address,
            name: 'endBlock',
          },
          {
            address,
            name: 'viewPoolInformation',
            params: [0],
          },
          {
            address: unlimitedAddress,
            name: 'viewPoolInformation',
            params: [0],
          },
          {
            address: unlimitedAddress,
            name: 'startBlock',
          },
          {
            address: unlimitedAddress,
            name: 'endBlock',
          },
          {
            address: unlimitedAddress,
            name: 'viewPoolTaxRateOverflow',
            params: [0],
          },
        ])

      const poolBasicFormatted = formatPool(poolBasic[0])
      const poolUnlimitedFormatted = formatPool(poolUnlimited[0])
      const startBlockNum = startBlock ? startBlock[0].toNumber() : 0
      const endBlockNum = endBlock ? endBlock[0].toNumber() : 0
      const unlimitedStartBlockNum = unlimitedStartBlock ? unlimitedStartBlock[0].toNumber() : 0
      const unlimitedEndBlockNum = unlimitedEndBlock ? unlimitedEndBlock[0].toNumber() : 0
      // only unlimited pool
      const taxRateNum = taxRate ? ethers.FixedNumber.from(taxRate[0]).divUnsafe(TAX_PRECISION).toUnsafeFloat() : 0

      const status = getStatus(currentBlock, startBlockNum, endBlockNum)
      const unlimitedStatus = getStatus(currentBlock, unlimitedStartBlockNum, unlimitedEndBlockNum)

      const totalBlocks = endBlockNum - startBlockNum
      const unlimitedTotalBlocks = unlimitedEndBlockNum - unlimitedStartBlockNum

      const blocksRemaining = endBlockNum - currentBlock
      const unlimitedRemaining = unlimitedEndBlockNum - currentBlock
      const unlimitedReleaser1Remaining = ifo.poolUnlimited.claimEpoch1 - currentBlock
      const unlimitedReleaser2Remaining = ifo.poolUnlimited.claimEpoch2 - currentBlock

      const basicSecondsUntilEnd = blocksRemaining * CHAIN_BLOCK_TIME
      const basicSecondsUntilStart = (startBlockNum - currentBlock) * CHAIN_BLOCK_TIME
      const unlimitedSecondsUntilEnd = unlimitedRemaining * CHAIN_BLOCK_TIME
      const unlimitedSecondsUntilStart = (unlimitedStartBlockNum - currentBlock) * CHAIN_BLOCK_TIME

      const unlimitedReleaser1hasStart = !(unlimitedReleaser1Remaining > 0)
      const unlimitedReleaser2hasStart = !(unlimitedReleaser2Remaining > 0)
      const unlimitedReleaser1UntilStart = unlimitedReleaser1Remaining * CHAIN_BLOCK_TIME
      const unlimitedReleaser2UntilStart = unlimitedReleaser2Remaining * CHAIN_BLOCK_TIME
      console.log('block =', currentBlock)
      // Calculate the total progress until finished or until start
      const progress =
        currentBlock > startBlockNum
          ? ((currentBlock - startBlockNum) / totalBlocks) * 100
          : ((currentBlock - releaseBlockNumber) / (startBlockNum - releaseBlockNumber)) * 100

      setState((prev) => ({
        ...prev,
        isInitialized: true,
        poolBasic: {
          ...poolBasicFormatted,
          taxRate: 0,
          blocksRemaining,
          startBlockNum,
          endBlockNum,
          secondsUntilStart: basicSecondsUntilStart,
          secondsUntilEnd: basicSecondsUntilEnd,
          totalBlocks,
          status,
        },
        poolUnlimited: {
          ...poolUnlimitedFormatted,
          taxRate: taxRateNum,
          blocksRemaining: unlimitedRemaining,
          startBlockNum: unlimitedStartBlockNum,
          endBlockNum: unlimitedEndBlockNum,
          totalBlocks: unlimitedTotalBlocks,
          secondsUntilEnd: unlimitedSecondsUntilEnd,
          secondsUntilStart: unlimitedSecondsUntilStart,
          status: unlimitedStatus,
          unlimitedReleaser1UntilStart,
          unlimitedReleaser2UntilStart,
          unlimitedReleaser1hasStart,
          unlimitedReleaser2hasStart,
        },
        progress,
        thresholdPoints: 0,
        numberPoints: 0,
      }))
    },
    [address, unlimitedAddress, ifo.poolUnlimited.claimEpoch1, ifo.poolUnlimited.claimEpoch2, releaseBlockNumber]
  )

  return { ...state, currencyPriceInUSD, fetchIfoData }
}

export default useGetPublicIfoData
