import BN from 'bignumber.js'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Modal, Text } from 'uikit'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { useResponsive } from '../../../hooks/useResponse'
import { AnimationButton } from 'components/Button'
import AmountButtonList from './AmountButtonList'
import { useToast } from 'state/hooks'

const ModalActionButton = styled(AnimationButton)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 100%;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  font-weight: 500;
  margin-top: 17px;
`

const StyledErrorMessage = styled(Text)`
  position: relative;
  margin-top: 10px;
  padding-left: 10px;
  a {
    display: inline;
  }
`

const WithdrawModal: React.FC<any> = ({ onConfirm, onDismiss, max, tokenName = '' }) => {
  const [val, setVal] = useState('')
  const [pendingTx, setPendingTx] = useState(false)
  const { t } = useTranslation()
  const { toastError } = useToast()

  const { isMobile } = useResponsive()

  const fullBalance = useMemo(() => {
    return getFullDisplayBalance(max)
  }, [max])

  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setVal(e.currentTarget.value)
    },
    [setVal]
  )

  const isValidAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    if (!/^[1-9]\d*\.\d*|0\.\d*[1-9]\d*$/.test(val) && !/^[1-9]\d*$/.test(val)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const insufficentAmmount = React.useMemo(() => {
    if (new BN(val).gt(fullBalance)) {
      return false
    }
    return true
  }, [val, fullBalance])

  const handleSelectMax = useCallback(() => {
    setVal(fullBalance)
  }, [fullBalance, setVal])

  return (
    <Modal style={{ width: isMobile ? '96%' : '440px' }} title={t('FARM_88')} onDismiss={onDismiss}>
      <ModalInput
        isValidAmmount={isValidAmmount}
        onSelectMax={handleSelectMax}
        onChange={handleChange}
        value={val}
        max={fullBalance}
        symbol={tokenName}
        inputTitle={t('FARM_90')}
        inputWidth={isMobile ? '110px' : 'auto'}
        styles={{ background: '#fff', borderRadius: '8px' }}
      />

      {!insufficentAmmount && (
        <StyledErrorMessage fontSize="14px" color="failure">
          {t('FARM_15')}
        </StyledErrorMessage>
      )}

      <AmountButtonList max={fullBalance} setValue={setVal} styles={{ marginTop: '16px' }} />

      <div>
        <ModalActions>
          {/* <ModalActionCancelButton disabled={false} onClick={onDismiss} width="100%">
            {t('FARM_16')}
          </ModalActionCancelButton> */}
          <ModalActionButton
            disabled={pendingTx || !isValidAmmount || !max}
            onClick={async () => {
              const Reg = /^([1-9][0-9]*|[0])(\.[0-9]{1,18})?$/
              if (!Reg.test(val)) {
                toastError('Invalid format', 'Please enter a valid number')
                return
              }
              setPendingTx(true)
              try {
                await onConfirm(val)
                onDismiss()
              } finally {
                setPendingTx(false)
              }
            }}
            width="100%"
          >
            {pendingTx ? t('FARM_17') : t('FARM_18')}
          </ModalActionButton>
        </ModalActions>
      </div>
    </Modal>
  )
}

export default WithdrawModal
