/* eslint-disable react-hooks/exhaustive-deps */
import { AnimationButton } from 'components/Button'
import { useActiveWeb3React } from 'hooks'
import useTheme from 'hooks/useTheme'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { postBind } from 'state/referral/hooks'
import styled from 'styled-components'
import { Modal, Text } from 'uikit'
import { useToast } from '../../../state/hooks'

interface InviteModalProps {
  updateBindInfo: () => void
  onDismiss: () => void
  showCodeModal?: boolean
  referral?: string
  account?: string | null | undefined
}

const Imgs = {
  header: require('../../../assets/images/referral/frisbee.webp').default,
  send: require('../../../assets/images/invite/send.svg').default,
}

const InviteHeaderImg = styled.img`
  width: 230px;
  height: 222px;
  margin: 0 auto;
`

const InviteCodeInput = styled.input<{ error: boolean }>`
  width: 100%;
  height: 60px;
  background: #efeff2;
  border: 1px solid ${({ error, theme }) => (error ? theme.colors.primary : '#efeff2')};
  border-radius: 40px;
  outline: none;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 20px;
  padding: 0 10px;
`

const InviteCodeError = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #f5455b;
  margin-top: 5px;
`

const InviteBtn = styled(AnimationButton)<{ disabled: boolean }>`
  width: 100%;
  height: 48px;
  border-radius: 24px;
  cursor: ${({ disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  color: white;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  -webkit-tap-highlight-color: transparent;
  margin-top: 24px;
`

const CodeModal: React.FC<InviteModalProps> = ({ updateBindInfo, onDismiss, account, referral, showCodeModal }) => {
  const { chainId, library } = useActiveWeb3React()
  const [inputError, setInputError] = useState(false)
  const { theme } = useTheme()
  const [data, setData] = useState('')
  const { toastSuccess, toastError } = useToast()
  const { t } = useTranslation()

  useEffect(() => {
    const params = window.location.href.split('ref=')[1] ?? ''
    if (!showCodeModal) {
      setData(params)
    }
  }, [account, referral])

  const bindCode = async () => {
    const EIP712Domain = [
      { name: 'name', type: 'string' },
      { name: 'version', type: 'string' },
      { name: 'chainId', type: 'uint256' },
    ]
    const domain = {
      name: 'Shardex Referral',
      version: '1',
      chainId,
    }
    const Permit = [
      { name: 'owner', type: 'address' },
      { name: 'value', type: 'string' },
      { name: 'timestamp', type: 'uint256' },
    ]
    const message = {
      owner: account,
      value: `Welcome to Shardex! \n Wallet address: ${account}`,
      timestamp: new Date().valueOf(),
    }
    const signData = JSON.stringify({
      types: {
        EIP712Domain,
        Permit,
      },
      domain,
      primaryType: 'Permit',
      message,
    })
    library?.send('eth_signTypedData_v4', [account, signData]).then((result) => {
      postBind(account ?? '', data, result, JSON.parse(signData))
        .then((res) => {
          if (res.msg === 'SUCCESS') {
            updateBindInfo()
            toastSuccess(t('REFERRAL_51'))
            onDismiss()
          } else {
            toastError(res.msg)
          }
        })
        .catch((e) => {
          toastError(e.message)
        })
    })
  }

  return (
    <Modal
      title={t('REFERRAL_50')}
      showHeader={true}
      onDismiss={onDismiss}
      style={{ width: isMobile ? '90%' : '440px' }}
      bodyPadding="24px"
    >
      <InviteHeaderImg src={Imgs.header} />
      <Text fontSize="16px" color={theme.colors.textRemark} textAlign="center" mt="45px" mb="14px">
        {t('REFERRAL_2')}
      </Text>
      <InviteCodeInput
        value={data}
        error={inputError}
        placeholder="Enter the code"
        onChange={(e: any) => {
          // eslint-disable-next-line no-useless-escape
          const value = e.target.value.replace(/[^\a-\z\A-\Z0-9]/g, '')
          setData(value)
        }}
      />
      {inputError && <InviteCodeError>{t('REFERRAL_5')}</InviteCodeError>}
      <InviteBtn
        disabled={!data || data.length !== 8 || inputError}
        onClick={() => {
          if (data.length === 8) {
            if (referral === data) {
              setInputError(true)
            } else {
              bindCode()
            }
          }
        }}
      >
        {t('REFERRAL_7')}
      </InviteBtn>
    </Modal>
  )
}

export default CodeModal
