import React from 'react'
import { Button, useWalletModal } from 'uikit'
import useAuth from 'hooks/useAuth'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const MojitoButton = styled(Button)`
  border-radius: 32px;
  height: 48px;
  font-size: 16px;
  color: #fff;
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  position: relative;
  z-index: 1;
  &::before {
    background-image: ${({ disabled }) => {
      if (disabled) {
        return '#EFEFF2'
      }
      return 'linear-gradient(271.45deg, #ff3093 3.84%, #5218f4 94.54%)'
    }};
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    border-radius: 32px;
  }
  &:hover:before {
    opacity: 1;
  }
`

const UnlockButton = (props) => {
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const { t } = useTranslation()
  return (
    <MojitoButton onClick={onPresentConnectModal} {...props}>
      {props.text ? props.text : t('COMPONENTS_157')}
    </MojitoButton>
  )
}

export default UnlockButton
