import React from 'react'
import styled from 'styled-components'
import { getTokenUrl } from '../../utils/getTokenUrl'

const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: visible;
`

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0: string
  currency1: string
}

const MainLaunchpadLogo = styled.img`
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
`

const TableWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: visible;
  height: 54px;
  width: 90px;
`

const FarmTableWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 28px;
`

const FarmTableSubLogo = styled.img`
  position: relative;
  z-index: 1;
  border-radius: 50%;
  transform: translateX(-10px);
`

const TableMainLogo = styled.img`
  border-radius: 50%;
`

const TableSubLogo = styled.img`
  position: absolute;
  bottom: 0px;
  left: 40px;
  z-index: 1;
  border-radius: 50%;
`

const SubLogo = styled.img`
  width: 36px;
  height: 36px;
  border: 3px solid #fff;
  position: absolute;
  background: #fff;
  bottom: 0px;
  right: -24px;
  border-radius: 50%;
`

export default function FarmDoubleLogo({ currency0, currency1, size = 54 }: DoubleCurrencyLogoProps) {
  return (
    <TableWrapper>
      <TableMainLogo
        style={{ width: size ? `${size}px` : '54px', height: size ? `${size}px` : '54px' }}
        src={getTokenUrl(currency0)}
      />
      <TableSubLogo
        style={{ width: size ? `${size}px` : '54px', height: size ? `${size}px` : '54px' }}
        src={getTokenUrl(currency1)}
      />
    </TableWrapper>
  )
}

export function LaunchpadDoubleLogo({ currency0, currency1, size = 16, margin = false }: DoubleCurrencyLogoProps) {
  return (
    <Wrapper sizeraw={size} margin={margin}>
      <MainLaunchpadLogo src={getTokenUrl(currency0)} />
      <SubLogo src={getTokenUrl(currency1)} />
    </Wrapper>
  )
}

export function FarmTableDoubleLogo({ currency0, currency1, size = 42 }: DoubleCurrencyLogoProps) {
  return (
    <FarmTableWrapper>
      <TableMainLogo
        style={{ width: size ? `${size}px` : '42px', height: size ? `${size}px` : '42px' }}
        src={getTokenUrl(currency0)}
      />
      <FarmTableSubLogo
        style={{ width: size ? `${size}px` : '42px', height: size ? `${size}px` : '42px' }}
        src={getTokenUrl(currency1)}
      />
    </FarmTableWrapper>
  )
}
