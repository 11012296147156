import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from 'uikit'
import ApyButton from 'pages/Farms/components/FarmCard/ApyButton'
import { Address } from 'constants/types'
import BigNumber from 'bignumber.js'
import { BASE_ADD_LIQUIDITY_URL } from 'constants/index'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import { FarmWithStakedValue } from 'pages/Farms/components/FarmCard/FarmCard'
import { MouseoverTooltip } from 'components/Tooltip'
import { useTranslation } from 'react-i18next'

export interface AprProps {
  value: string
  multiplier: string
  lpLabel: string
  tokenAddress?: Address
  quoteTokenAddress?: Address
  projectTokenPrice: BigNumber
  originalValue: number
  hideButton?: boolean
  details?: FarmWithStakedValue
}

const Container = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};

  button {
    width: 20px;
    height: 20px;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.textSubtle};
      }
    }
  }
`

const AprWrapper = styled.div`
  min-width: 60px;
  text-align: left;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.primary};
  font-family: '' Poppins '';
  font-weight: 500;
  line-height: 21px;
`

const Apr: React.FC<AprProps> = ({
  value,
  lpLabel,
  tokenAddress,
  quoteTokenAddress,
  projectTokenPrice,
  originalValue,
  hideButton = false,
  details,
}) => {
  const { t } = useTranslation()
  const liquidityUrlPathParts = getLiquidityUrlPathParts({ quoteTokenAddress, tokenAddress })
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const aprToolTips = (
    <div>
      <Flex justifyContent="space-between">
        <Text color="textSubtle" fontSize="14px">
          {t('FARM_39')}
        </Text>
        <Text color="text" fontSize="14px">
          {new BigNumber(details?.doubleDigFeeApr).toFixed(2, 1)}%
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="8px">
        <Text color="textSubtle" fontSize="14px">
          {t('FARM_40')}
        </Text>
        <Text color="text" fontSize="14px">
          {new BigNumber(details?.mainTokenApr).toFixed(2, 1)}%
        </Text>
      </Flex>
      <Flex justifyContent="space-between" mt="8px">
        <Text color="textSubtle" fontSize="14px">
          {details?.doubleDigToken?.symbol}
          {t('FARM_30')}
        </Text>
        <Text color="text" fontSize="14px">
          {new BigNumber(details?.doubleDigTokenApr).toFixed(2, 1)}%
        </Text>
      </Flex>
    </div>
  )

  return originalValue !== 0 ? (
    <Container>
      {originalValue ? (
        <>
          {details?.version === 2 && details?.doubleDigTokenApr ? (
            <MouseoverTooltip placement="top" text={aprToolTips}>
              <AprWrapper>{value}%</AprWrapper>
            </MouseoverTooltip>
          ) : (
            <AprWrapper>{value}%</AprWrapper>
          )}
          {details && !hideButton && (
            <ApyButton
              variant="text-and-button"
              pid={details?.pid}
              lpSymbol={details?.lpSymbol}
              multiplier={details?.multiplier}
              lpLabel={lpLabel}
              projectTokenPrice={projectTokenPrice}
              apr={originalValue}
              addLiquidityUrl={addLiquidityUrl}
              details={details ?? {}}
              version={details?.version ?? 1}
            />
          )}
        </>
      ) : (
        <AprWrapper>{t('FARM_38')}</AprWrapper>
      )}
    </Container>
  ) : (
    <Container>
      <AprWrapper>{originalValue}%</AprWrapper>
    </Container>
  )
}

export default Apr
