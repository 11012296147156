import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" {...props}>
      <path
        d="M4.16602 8.33464H5.83268C7.49935 8.33464 8.33268 7.5013 8.33268 5.83464V4.16797C8.33268 2.5013 7.49935 1.66797 5.83268 1.66797H4.16602C2.49935 1.66797 1.66602 2.5013 1.66602 4.16797V5.83464C1.66602 7.5013 2.49935 8.33464 4.16602 8.33464Z"
        stroke={props.color ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.166 8.33464H15.8327C17.4993 8.33464 18.3327 7.5013 18.3327 5.83464V4.16797C18.3327 2.5013 17.4993 1.66797 15.8327 1.66797H14.166C12.4993 1.66797 11.666 2.5013 11.666 4.16797V5.83464C11.666 7.5013 12.4993 8.33464 14.166 8.33464Z"
        stroke={props.color ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M14.166 18.3346H15.8327C17.4993 18.3346 18.3327 17.5013 18.3327 15.8346V14.168C18.3327 12.5013 17.4993 11.668 15.8327 11.668H14.166C12.4993 11.668 11.666 12.5013 11.666 14.168V15.8346C11.666 17.5013 12.4993 18.3346 14.166 18.3346Z"
        stroke={props.color ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M4.16602 18.3346H5.83268C7.49935 18.3346 8.33268 17.5013 8.33268 15.8346V14.168C8.33268 12.5013 7.49935 11.668 5.83268 11.668H4.16602C2.49935 11.668 1.66602 12.5013 1.66602 14.168V15.8346C1.66602 17.5013 2.49935 18.3346 4.16602 18.3346Z"
        stroke={props.color ?? 'white'}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Svg>
  )
}

export default Icon
