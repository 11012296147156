import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import MoreHorizontal from '../../components/Svg/Icons/MoreHorizontal'
import { ButtonProps } from '../../components/Button'
import { connectorLocalStorageKey } from './config'
import { Login, Config } from './types'

interface Props {
  walletConfig: Config
  login: Login
  onDismiss: () => void
}

const WalletButton = styled(Button).attrs({ width: '190px', variant: 'text', px: '16px' })`
  align-items: center;
  display: flex;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
  background: #efeff2;
  border-radius: 8px;
  :hover {
    background: linear-gradient(91.57deg, rgba(255, 48, 147, 0.1) 1.17%, rgba(82, 24, 244, 0.1) 99.12%);
    opacity: 1 !important;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const MoreWalletCard: React.FC<ButtonProps> = ({ ...props }) => {
  return (
    <WalletButton variant="tertiary" {...props}>
      <MoreHorizontal width="40px" mb="8px" color="textSubtle" />
      <Text fontSize="14px">More</Text>
    </WalletButton>
  )
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss }) => {
  const { title, icon: Icon } = walletConfig
  return (
    <WalletButton
      width="100%"
      variant="tertiary"
      onClick={() => {
        login(walletConfig.connectorId)
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
        onDismiss()
      }}
      style={{ justifyContent: 'flex-start' }}
      id={`wallet-connect-${title.toLocaleLowerCase()}`}
    >
      <Icon width="32px" height="32px" />
      <Text fontSize="14px" ml="12px">
        {title}
      </Text>
    </WalletButton>
  )
}

export default WalletCard
