import React from 'react'
import { useTranslation } from 'react-i18next'
import { Token } from 'shardex-sdk'
import { Button, Link, Modal, ModalBody, OpenNewIcon, Text } from 'uikit'

interface Props {
  currency: Token
  onDismiss?: () => void
}

const GetTokenModal: React.FC<Partial<Props>> = ({ currency, onDismiss }) => {
  const { t } = useTranslation()
  return (
    <Modal title={t('LAUNCHPAD_29', { asset: currency.symbol })} onDismiss={onDismiss}>
      <ModalBody>
        {/* <Image src={`/images/tokens/${currency.address}.svg`} width={72} height={72} margin="auto" mb="24px" /> */}
        <Text mb="16px">{t('LAUNCHPAD_30', { asset: currency.symbol })}</Text>
        <Text mb="24px">{t('LAUNCHPAD_31', { asset: currency.symbol })}</Text>
        <Button
          as={Link}
          external
          // href={`/add/KCS/${tokens.mjt.address[process.env.REACT_APP_CHAIN_ID]}`}
          href="/swap"
          endIcon={<OpenNewIcon color="white" />}
          minWidth="100%" // Bypass the width="fit-content" on Links
        >
          <Text color="white" bold>
            {t('LAUNCHPAD_32', { asset: currency.symbol })}
          </Text>
        </Button>
      </ModalBody>
    </Modal>
  )
}

export default GetTokenModal
