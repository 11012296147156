import { MenuEntry } from '../../uikit'
import i18next from '../../i18n'

const IconList = {
  reward: require('assets/images/icon/reward.svg').default,
  products: require('assets/images/icon/products.svg').default,
  twitter: require('assets/images/icon/m-twitter.svg').default,
  telegram: require('assets/images/icon/m-telegram.svg').default,
  docs: require('assets/images/icon/m-docs.svg').default,
  medium: require('assets/images/icon/m-medium.svg').default,
  discord: require('assets/images/icon/m-discord.svg').default,
}

const PROD_MENU: MenuEntry[] = [
  {
    label: i18next.t('Trade'),
    ga: 'page_sidebar_trade',
    items: [
      {
        label: i18next.t('SWAP_12'),
        href: '/swap',
      },
      {
        label: i18next.t('Liquidity'),
        href: '/pool',
      },
    ],
  },
  {
    label: i18next.t('Earn'),
    items: [
      {
        label: i18next.t('Farm'),
        href: '/farm',
      },
      {
        label: i18next.t('Pool'),
        href: '/pool',
      },
      // {
      //   label: i18next.t('Launchpad'),
      //   href: '/ido/detail/sdt',
      // },
    ],
  },
  {
    label: i18next.t('Win'),
    href: '/',
    disabled: true,
  },
  {
    label: i18next.t('Reward'),
    icon: IconList.reward,
    href: '/',
    disabled: true,
  },
  {
    label: i18next.t('Shardex Products'),
    icon: IconList.products,
    href: '/',
    disabled: true,
  },
]

const DEV_MENU: MenuEntry[] = [
  {
    label: i18next.t('Trade'),
    ga: 'page_sidebar_trade',
    items: [
      {
        label: i18next.t('SWAP_12'),
        href: '/swap',
      },
      {
        label: i18next.t('Liquidity'),
        href: '/pool',
      },
    ],
  },
  {
    label: i18next.t('Earn'),
    items: [
      {
        label: i18next.t('Farm'),
        href: '/farm',
      },
      {
        label: i18next.t('Pool'),
        href: '/pools',
      },
      // {
      //   label: i18next.t('Launchpad'),
      //   href: '/ido/detail/sdt',
      // },
    ],
  },
  // {
  //   label: i18next.t('Win'),
  //   href: '',
  //   disabled: true,
  // },
  {
    label: i18next.t('Bridge'),
    // icon: IconList.reward,
    href: 'https://bridge.shardex.org/',
    disabled: false,
  },
  {
    label: i18next.t('Reward'),
    icon: IconList.reward,
    href: '/reward',
    isReward: true,
    disabled: false,
  },
  // {
  //   label: i18next.t('Shardex Products'),
  //   icon: IconList.products,
  //   href: '',
  //   items: [
  //     {
  //       label: 'Bridge',
  //       href: 'https://bridge.shardex.org/',
  //     },
  //   ],
  // },
  {
    label: i18next.t('...'),
    href: '',
    items: [
      {
        label: 'Twitter',
        href: 'https://twitter.com/ShardexOrg',
        icon: IconList.twitter,
      },
      {
        label: 'Discord',
        href: 'https://discord.gg/TWzDNx6EYR',
        icon: IconList.discord,
      },
      {
        label: 'Medium',
        href: 'https://medium.com/@Shardex',
        icon: IconList.medium,
      },
      {
        label: 'Telegram',
        href: 'https://t.me/Shardex_official',
        icon: IconList.telegram,
      },
      {
        label: 'Docs',
        href: 'https://docs.shardex.org/',
        icon: IconList.docs,
      },
    ],
  },
]

const config =
  process.env.REACT_APP_ENV_NAME === 'production-mainnet' || process.env.REACT_APP_ENV_NAME === 'preview'
    ? PROD_MENU
    : DEV_MENU

export default config
