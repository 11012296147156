import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Link } from 'react-router-dom'
import Flex from '../../../components/Box/Flex'
import { Image } from 'components'
import { ShardexIcon } from '../icons'
import { useResponsive } from 'hooks/useResponse'
import LogoImage from 'assets/images/logo-text.svg'

interface Props {
  isPushed: boolean
  isDark: boolean
  togglePush: () => void
  href: string
}

const blink = keyframes`
  0%,  100% { transform: scaleY(1); } 
  50% { transform:  scaleY(0.1); } 
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  .mobile-icon {
    width: 32px;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: none;
    }
  }
  .desktop-icon {
    width: 162px;
    height: 37.2px;
    margin-right: 85px;
    display: none;
    ${({ theme }) => theme.mediaQueries.nav} {
      display: block;
    }
  }
  .right-eye {
    animation-delay: 20ms;
  }
  &:hover {
    .left-eye,
    .right-eye {
      transform-origin: center 60%;
      animation-name: ${blink};
      animation-duration: 350ms;
      animation-iteration-count: 1;
    }
  }
`

const LogoText = styled(Image)`
  width: 115px;
  height: 20px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  margin-left: 6px;
  @media (max-width: 768px) {
    width: 86px;
    height: 15px;
  }
`

const Logo: React.FC<Props> = ({ href }) => {
  const isAbsoluteUrl = href.startsWith('http')
  const [isHover, setIsHover] = React.useState<boolean>(false)
  const { isMobile } = useResponsive()

  const innerLogo = (
    <>
      <ShardexIcon
        onMouseEnter={() => setIsHover(() => true)}
        onMouseLeave={() => setIsHover(() => false)}
        style={{
          width: isMobile ? '27px' : '36px',
          height: isMobile ? '27px' : '36px',
        }}
        isHover={isHover}
      />
      <LogoText src={LogoImage} alt="logo-with-text" />
    </>
  )

  return (
    <Flex>
      {isAbsoluteUrl ? (
        // @ts-ignore
        <StyledLink as="a" href={href} aria-label="home page">
          {innerLogo}
        </StyledLink>
      ) : (
        <StyledLink to={href} aria-label="home page">
          {innerLogo}
        </StyledLink>
      )}
    </Flex>
  )
}

export default React.memo(Logo, (prev, next) => prev.isPushed === next.isPushed && prev.isDark === next.isDark)
