import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'

const TooltipContainer = styled.div`
  width: 228px;
  padding: 16px;
  line-height: 150%;
  font-weight: 400;
  font-size: 14px;
`

interface TooltipProps extends Omit<PopoverProps, 'content'> {
  text: any
  styles?: any
  close?: any
}

export default function Tooltip({ text, styles, ...rest }: TooltipProps) {
  return <Popover content={<TooltipContainer style={styles}>{text}</TooltipContainer>} {...rest} />
}

export function MouseleaveTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <Tooltip {...rest} show={show}>
      <div onMouseEnter={open} onMouseLeave={close}>
        {children}
      </div>
    </Tooltip>
  )
}

export function MouseoverTooltip({ children, ...rest }: Omit<TooltipProps, 'show'>) {
  const [show, setShow] = useState(false)
  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])
  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip {...rest} show={show}>
        <div
          onMouseEnter={() => {
            if (rest.text) {
              open()
            }
          }}
          onMouseLeave={() => {
            if (rest.text) {
              close()
            }
          }}
        >
          {children}
        </div>
      </Tooltip>
    </span>
  )
}
