/* eslint-disable @typescript-eslint/no-unused-vars */
import { useReducer, useState, useEffect } from 'react'
import { useSBTContract } from 'hooks/useContract'
import i18next from '../../i18n'
import { getSBTokenContract } from 'utils/contractHelpers'

const api = process.env.REACT_APP_REFERRAL_API_URL
export interface SocialProps {
  address: string
  username: string
  type: string
}

interface SignProps {
  address: string
  data: string
  signature: string
}

export interface UserInfo {
  address: string
  code: string
  point: number
  confirmed: number
  rank: number
  socials: SocialProps[] | null
  sign: SignProps
  referer?: string
}

export interface HttpResponse<T> {
  code: number
  msg: string
  success: boolean
  data: T
}

export const handleResponse = (res, resolve) => {
  resolve(res)
}

export const handleError = (err, reject) => {
  console.log(err)
  reject({ code: 'NET_ERROR', message: i18next.t('CONSTANT_30') })
}

export const get = (url, params?): Promise<any> =>
  new Promise((resolve, reject) => {
    if (params) {
      const paramsArray: any[] = []
      Object.keys(params).forEach((key) => paramsArray.push(`${key}=${params[key]}`))
      if (paramsArray.length > 0) {
        if (url.search(/\?/) === -1) {
          url += `?${paramsArray.join('&')}`
        } else {
          url += `&${paramsArray.join('&')}`
        }
      }
    }
    fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => response.json())
      .then((res) => handleResponse(res, resolve))
      .catch((err) => handleError(err, reject))
  })

export const post = (url, jsonData): Promise<any> =>
  new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(jsonData),
    })
      // eslint-disable-next-line no-unused-expressions
      .then((response: any) => response.json())
      .then((res) => handleResponse(res, resolve))
      .catch((err) => handleError(err, reject))
  })

export function postRegister(
  account: string,
  signature: string,
  data: string,
  ref?: string
): Promise<
  HttpResponse<{
    address: string
    code: string
    point: number
    socials: SocialProps[] | null
    referer: string
    sign: SignProps
  }>
> {
  return post(`${api}/v1/register`, { account, signature, ref })
}

export function getUserInfo(account: string): Promise<HttpResponse<UserInfo>> {
  return get(`${api}/v1/info`, { account })
}

export function getTwitterLink(): Promise<HttpResponse<string>> {
  return get(`${api}/v1/login_twitter`)
}

export function getDiscordLink(): Promise<HttpResponse<string>> {
  return get(`${api}/v1/login_discord`)
}

export function postAuthTwitter(account: string, token: string, verifier: string): Promise<HttpResponse<string>> {
  return post(`${api}/v1/auth_twitter`, { account, token, verifier })
}

export function postAuthDiscord(account: string, state: string, code: string): Promise<HttpResponse<string>> {
  return post(`${api}/v1/auth_discord`, { account, state, code })
}

export function postBind(account: string, ref: string, signature: string, data: string): Promise<HttpResponse<string>> {
  return post(`${api}/v1/bind_ref`, { account, sign: signature, ref, data })
}

/*  Contract helper */
export function useSBTMinted(address: string | null | undefined): {
  mintResult: boolean
  updateMintStatus: () => void
} {
  const [mintResult, setResult] = useState<boolean>(false)
  const SBTContract = useSBTContract()
  const [_, forceUpdate] = useReducer((x) => x + 1, 0)

  useEffect(() => {
    const fetchAuthorized = async () => {
      const result = await SBTContract?.functions.balanceOf(address)
      const count = result ? Number(result.toString()) > 0 : false
      setResult(count)
    }
    if (address) {
      fetchAuthorized()
    }
  }, [address, _, SBTContract?.functions])
  return { mintResult, updateMintStatus: forceUpdate }
}

export function useSBTSupply(): {
  totalSupply: number
} {
  const [totalSupply, setResult] = useState<number>(0)
  const SBTContract = getSBTokenContract()

  useEffect(() => {
    const fetchAuthorized = async () => {
      const result = await SBTContract?.methods?.totalSupply().call()
      setResult(result || 0)
    }
    fetchAuthorized()
  }, [SBTContract])
  return { totalSupply }
}
