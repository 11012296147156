import React from 'react'
import styled from 'styled-components'
import PanelBody from './PanelBody'
import { PanelProps, PushedProps } from '../types'

interface Props extends PanelProps, PushedProps {
  showMenu: boolean
  isMobile: boolean
}

const StyledPanel = styled.div<{ isPushed: boolean; showMenu: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  padding-top: ${({ showMenu }) => (showMenu ? '50px' : 0)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #000000;
  width: ${({ isPushed }) => (isPushed ? `100vw` : 0)};
  height: 100%;
  // transition: padding-top 0.2s, width 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  // border-right: ${({ isPushed }) => (isPushed ? '2px solid rgba(133, 133, 133, 0.1)' : 0)};
  z-index: 11;
  overflow: ${({ isPushed }) => (isPushed ? 'initial' : 'hidden')};
  transform: translate3d(0, 0, 0);
  ${({ isPushed }) => !isPushed && 'white-space: nowrap'};
`

const Panel: React.FC<Props> = (props) => {
  const { isPushed, showMenu } = props
  return (
    <StyledPanel isPushed={isPushed} showMenu={showMenu}>
      <PanelBody {...props} />
    </StyledPanel>
  )
}

export default Panel
