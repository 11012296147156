import React from 'react'
import styled from 'styled-components'
import { Text, Flex, LinkExternal } from 'uikit'
import { getMasterChefV2Address } from 'utils/addressHelpers'
import { useTranslation } from 'react-i18next'

export interface ExpandableSectionProps {
  bscScanAddress?: string
  infoAddress?: string
  removed?: boolean
  totalValueFormatted?: string
  lpLabel?: string
  addLiquidityUrl?: string
  version?: number
  showTorches?: boolean
}

const Wrapper = styled.div`
  margin-top: 24px;
`

export const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
`

const DetailsSection: React.FC<ExpandableSectionProps> = ({
  infoAddress,
  removed,
  totalValueFormatted,
  lpLabel,
  addLiquidityUrl,
  showTorches,
}) => {
  const { t } = useTranslation()
  const masterChefV2Address = getMasterChefV2Address()

  const masterChefUrl = `${process.env.REACT_APP_EXPLORER_URL}/address/${masterChefV2Address}`

  return (
    <Wrapper style={{ marginTop: '0px', marginBottom: '20px' }}>
      <Flex justifyContent="space-between">
        <Text color="#fff">{t('FARM_61')}:</Text>
        <Text color="#fff">{totalValueFormatted}</Text>
      </Flex>
      {!removed && (
        <StyledLinkExternal color="#FF3093" style={{ marginTop: '14px', color: '#FF3093' }} href={addLiquidityUrl}>
          {t('FARM_41', { asset: lpLabel })}
        </StyledLinkExternal>
      )}
      <StyledLinkExternal color="#FF3093" style={{ marginTop: '14px', color: '#FF3093' }} href={masterChefUrl}>
        {t('FARM_57')}
      </StyledLinkExternal>
      <StyledLinkExternal color="#FF3093" style={{ marginTop: '14px', color: '#FF3093' }} href={infoAddress}>
        {t('FARM_58')}
      </StyledLinkExternal>
      {showTorches && (
        <StyledLinkExternal
          color="#fff"
          style={{ marginTop: '14px', color: '#fff' }}
          href="https://www.torches.finance/en/crypto-bank"
        >
          {t('FARM_104')}
        </StyledLinkExternal>
      )}
    </Wrapper>
  )
}

export default DetailsSection
