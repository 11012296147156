import React from 'react'
import styled from 'styled-components'
import { Tag, Flex } from 'uikit'
import FarmDoubleLogo from 'components/DoubleLogo/FarmDoubleLogo'
import { Text } from 'uikit'

export interface ExpandableSectionProps {
  lpLabel: string
  version: number
  multiplier?: string
  isCommunityFarm?: boolean
  farmImage?: string
  tokenSymbol?: string
  endBlock?: number
}

const Wrapper = styled(Flex)`
  padding: 0 24px;
  height: 102px;
  padding-bottom: 0px;
  align-items: center;
  svg {
    margin-right: 4px;
  }
`

const MultiplierTag = styled(Tag)`
  margin-left: 4px;
  background: #ff3093;
  color: #fff;
  font-size: 14px;
  border-radius: 13px;
  height: 25px;
  margin-top: 4px;
`

const CardHeading: React.FC<ExpandableSectionProps> = ({ lpLabel, multiplier, farmImage, endBlock }) => {
  // debugger
  const [currency0, currency1] = farmImage?.split('/') as string[]
  return (
    <Wrapper justifyContent="flex-start" alignItems="center" mb="0px">
      <FarmDoubleLogo size={54} currency0={currency0} currency1={currency1} />
      <Flex flexDirection="column" alignItems="flex-start" style={{ marginLeft: '32px' }}>
        <Text fontWeight={500} color="#fff" fontSize="24px">
          {lpLabel.split(' ')[0]}
        </Text>

        <Flex flexDirection="row" alignItems="center">
          <MultiplierTag>{multiplier}</MultiplierTag>
          {endBlock ? <MultiplierTag>Time-limited</MultiplierTag> : null}
          {/* <MultiplierTag>Time-limited</MultiplierTag> */}
        </Flex>
        {/* <Flex justifyContent="center">
           isCommunityFarm ? <CommunityTag /> : <CoreTag /> 
           {version === 2 ? null : (
            <MultiplierTag variant="secondary">
              <Text fontWeight={700} color="#FF3093" fontSize="16px" fontFamily="Poppins" lineHeight="19px">
                {multiplier}
              </Text>
            </MultiplierTag>
          )} 
        </Flex> */}
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
