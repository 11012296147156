import { Button } from '../../uikit'
import styled from 'styled-components'

export const SwapButton = styled(Button)<{ disabled?: boolean; background?: string; color?: string }>`
  border-radius: 24px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#9c9c9c'
    }
    return 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)'
  }};
  color: ${({ theme }) => `${theme.colors.primary}`};
  box-shadow: none;
`

export const PrimaryButton = styled(Button)<{
  disabled?: boolean
  background?: string
  color?: string
  isBorder?: boolean
}>`
  border-radius: 24px;
  height: 48px;
  font-size: 18px;
  font-weight: 500;
  background: ${({ disabled, background, theme, isBorder }) => {
    if (disabled) {
      return '#9c9c9c'
    }
    if (isBorder) {
      return 'white'
    }
    return background ?? theme.colors.primary
  }};
  color: ${({ isBorder, theme }) => (isBorder ? `${theme.colors.primary}` : 'white')};
  border: ${({ isBorder, theme }) => (isBorder ? `2px solid ${theme.colors.primary}` : 'none')};
  box-shadow: none;
`

export const AnimationButton = styled(Button)<{ disabled?: boolean }>`
  border-radius: 24px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  box-shadow: none;
  background-image: ${({ disabled }) => {
    if (disabled) {
      return '#EFEFF2'
    }
    return 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)'
  }};
  position: relative;
  z-index: 0;

  &::before {
    background-image: ${({ disabled }) => {
      if (disabled) {
        return '#EFEFF2'
      }
      return 'linear-gradient(271.45deg, #ff3093 3.84%, #5218f4 94.54%)'
    }};
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    border-radius: 24px;
  }
  &:hover:before {
    opacity: 1;
  }
`

export const ColorBorder = styled.div<{ disabled: boolean }>`
  position: absolute;
  z-index: -1;
  top: -2px;
  left: -2px;
  height: 52px;
  width: 146px;
  border-radius: 30px;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
`

export const ColorBorderButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 222;
  box-shadow: none;
  position: relative;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background: #fff;
`

export const FarmConfirmButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return '#FF3093'
  }};
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Confirm';
    line-height: 44px;
  }
`

export const FarmCancelButton = styled(Button)<{ disabled: boolean }>`
  border-radius: 30px;
  height: 48px;
  width: 142px;
  z-index: 1;
  box-shadow: none;
  position: relative;
  overflow: visible;
  background: ${({ disabled }) => {
    if (disabled) {
      return '#C9C9C9'
    }
    return 'linear-gradient(to right, #14d89e, #0bc5d9)'
  }};
  color: #fff;
  &::after {
    position: absolute;
    z-index: 99;
    top: 2px;
    bottom: 2px;
    right: 2px;
    left: 2px;
    background: ${({ disabled }) => {
      if (disabled) {
        return '#C9C9C9'
      }
      return '#fff'
    }};
    display: ${({ disabled }) => {
      if (disabled) {
        return 'none'
      }
      return 'block'
    }};
    color: ${({ disabled }) => {
      if (disabled) {
        return '#fff'
      }
      return '#FF3093'
    }};
    border-radius: 30px;
    content: 'Cancel';
    line-height: 44px;
  }
`
