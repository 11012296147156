import React from 'react'
import { useWalletModal } from '../../uikit'
import useAuth from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import { AnimationButton } from '../Button'
import { Image } from 'components'

const UnlockButton: React.FC<any> = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)

  return (
    <AnimationButton onClick={onPresentConnectModal} style={{ fontWeight: '500' }} {...props}>
      <Image
        src={require('assets/images/home/wallet.svg').default}
        width="20px"
        height="20px"
        style={{ marginRight: '6px' }}
      />
      {t('COMPONENTS_157')}
    </AnimationButton>
  )
}

export default UnlockButton
