import React from 'react'
import styled, { keyframes } from 'styled-components'

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Animation = keyframes`
  0%{
 transform: rotate(0);

  }
  50%{
 transform:  rotate(0.5turn);
  }
    100%{
 transform:  rotate(1turn);
  }
`

const Logo = require('../../assets/images/color-logo.png').default

const IMAGE = styled.img`
  width: 60px;
  height: 60px;
`

const ImageWrap = styled.div`
  transform: rotate(1turn);
  animation: ${Animation} 1s ease-in-out infinite;
  width: 60px;
  height: 60px;
`

const FullLoading: React.FunctionComponent = () => {
  return (
    <Wrap>
      <ImageWrap>
        <IMAGE src={Logo} />
      </ImageWrap>
    </Wrap>
  )
}

export default FullLoading
