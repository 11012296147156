import { LinkStatus } from './types'
import i18next from '../../../i18n'

export const status = {
  LIVE: <LinkStatus>{
    text: i18next.t('UIKIT_1'),
    color: 'failure',
  },
  SOON: <LinkStatus>{
    text: i18next.t('UIKIT_2'),
    color: 'warning',
  },
  NEW: <LinkStatus>{
    text: i18next.t('UIKIT_3'),
    color: 'tagNew',
  },
  HOT: <LinkStatus>{
    text: i18next.t('UIKIT_4'),
    color: 'tagNew',
  },
}

export const links = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://app.mojitoswap.finance/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://app.mojitoswap.finance/pool',
      },
    ],
  },
  {
    label: 'BAR',
    icon: 'FarmIcon',
    href: '/farms',
    status: status.LIVE,
  },
  {
    label: 'WINE POOLS',
    icon: 'PoolIcon',
    href: '/syrup',
  },
  {
    label: 'COCKTAIL TICKETS',
    icon: 'TicketIcon',
    href: '/lottery',
  },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  // {
  //   label: 'Team Battle',
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  //   status: status.SOON,
  // },
  // {
  //   label: 'Profile & Teams',
  //   icon: 'GroupsIcon',
  //   status: status.LIVE,
  //   items: [
  //     {
  //       label: 'Leaderboard',
  //       href: '/teams',
  //       status: status.NEW,
  //     },
  //     {
  //       label: 'YourProfile',
  //       href: '/',
  //     },
  //   ],
  //   calloutClass: 'rainbow',
  // },
  // {
  //   label: 'Info',
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: 'Overview',
  //       href: 'https://mojitoswap.info',
  //     },
  //     {
  //       label: 'Tokens',
  //       href: 'https://mojitoswap.info/tokens',
  //     },
  //     {
  //       label: 'Pairs',
  //       href: 'https://mojitoswap.info/pairs',
  //     },
  //     {
  //       label: 'Accounts',
  //       href: 'https://mojitoswap.info/accounts',
  //     },
  //   ],
  // },
  // {
  //   label: "IFO",
  //   icon: "IfoIcon",
  //   items: [
  //     {
  //       label: "Next",
  //       href: "/ifo",
  //     },
  //     {
  //       label: "History",
  //       href: "/ifo/history",
  //     },
  //   ],
  // },
  // {
  //   label: "More",
  //   icon: "MoreIcon",
  //   items: [
  //     {
  //       label: "Voting",
  //       href: "https://voting.mojitoswap.finance",
  //     },
  //     {
  //       label: "Github",
  //       href: "https://github.com/mojitoswap",
  //     },
  //     {
  //       label: "Docs",
  //       href: "https://docs.mojitoswap.finance",
  //     },
  //     {
  //       label: "Blog",
  //       href: "https://mojitoswap.medium.com",
  //     },
  //   ],
  // },
]

export const socials = [
  {
    label: 'Telegram',
    icon: 'TelegramIcon',
    items: [
      {
        label: 'English',
        href: 'https://t.me/mojitoswap',
      },
      {
        label: 'Bahasa Indonesia',
        href: 'https://t.me/PancakeSwapIndonesia',
      },
      {
        label: '中文',
        href: 'https://t.me/PancakeSwap_CN',
      },
      {
        label: 'Tiếng Việt',
        href: 'https://t.me/PancakeSwapVN',
      },
      {
        label: 'Italiano',
        href: 'https://t.me/pancakeswap_ita',
      },
      {
        label: 'русский',
        href: 'https://t.me/pancakeswap_ru',
      },
      {
        label: 'Türkiye',
        href: 'https://t.me/pancakeswapturkiye',
      },
      {
        label: 'Português',
        href: 'https://t.me/PancakeSwapPortuguese',
      },
      {
        label: 'Español',
        href: 'https://t.me/PancakeswapEs',
      },
      {
        label: '日本語',
        href: 'https://t.me/pancakeswapjp',
      },
      {
        label: 'Français',
        href: 'https://t.me/pancakeswapfr',
      },
      {
        label: 'Announcements',
        href: 'https://t.me/PancakeSwapAnn',
      },
      {
        label: 'Whale Alert',
        href: 'https://t.me/PancakeSwapWhales',
      },
    ],
  },
  {
    label: 'Twitter',
    icon: 'TwitterIcon',
    href: 'https://twitter.com/mojitoswap',
  },
]

export const MENU_HEIGHT = 48
export const MENU_ENTRY_HEIGHT = 64
export const SIDEBAR_WIDTH_FULL = 250
export const SIDEBAR_WIDTH_REDUCED = 70
