import React, { useMemo } from 'react'
import { CheckmarkCircleIcon, ErrorIcon, Flex, LinkExternal, Text, Modal, Box } from '../../uikit'
import { useActiveWeb3React } from 'hooks'
import { getKCCExplorerLink } from 'utils'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import Loader from 'components/Loader'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'

type RecentTransactionsModalProps = {
  onDismiss?: () => void
  translateString: (translationId: number, fallback: string) => string
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails, chainId?: any) => {
  const { hash, receipt } = sortedRecentTransaction

  if (!receipt) {
    return {
      icon: (
        <Box>
          <Loader stroke="#001425" />
        </Box>
      ),
      color: 'text',
      url: '',
    }
  }

  if (hash && receipt?.status === 1) {
    return {
      icon: <CheckmarkCircleIcon color="#14D89D" />,
      color: '#14D89D',
      url: getKCCExplorerLink(chainId, hash, 'transaction'),
    }
  }

  return {
    icon: <ErrorIcon color="#FF8F2C" />,
    color: '#FF8F2C',
    url: getKCCExplorerLink(chainId, hash, 'transaction'),
  }
}

const RecentTransactionsModal = ({ onDismiss = defaultOnDismiss }: RecentTransactionsModalProps) => {
  const { account, chainId } = useActiveWeb3React()
  const allTransactions = useAllTransactions()
  const { t } = useTranslation()
  // Logic taken from Web3Status/index.tsx line 175
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  return (
    <Modal title={t('COMPONENTS_179')} onDismiss={onDismiss} minWidth={isMobile ? '96%' : '440px'}>
      {!account && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text mt="30px" mb="40px" fontSize="14px" color="textRemark">
            {t('COMPONENTS_49')}
          </Text>
          {/* <Button
            style={{
              height: '36px',
              background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
              color: '#fff',
            }}
            scale="sm"
            onClick={onDismiss}
          >
            {t('COMPONENTS_50')}
          </Button> */}
        </Flex>
      )}
      {account && chainId && sortedRecentTransactions.length === 0 && (
        <Flex justifyContent="center" flexDirection="column" alignItems="center">
          <Text color="textRemark" mt="30px" mb="40px">
            {t('COMPONENTS_51')}
          </Text>
          {/* <Button
            style={{
              background: 'linear-gradient(91.57deg, #FF3093 1.17%, #5218F4 99.12%)',
              color: '#fff',
              height: '36px',
            }}
            scale="sm"
            onClick={onDismiss}
          >
            {t('COMPONENTS_50')}
          </Button> */}
        </Flex>
      )}
      {account &&
        chainId &&
        sortedRecentTransactions.map((sortedRecentTransaction, index) => {
          const { hash, summary } = sortedRecentTransaction
          const { icon, color, url } = getRowStatus(sortedRecentTransaction, chainId)

          return (
            <>
              <Flex key={`${hash}${index + 1}`} alignItems="center" justifyContent="space-between" mb="4px">
                <Text color={color}>
                  <LinkExternal style={{ fontSize: '14px', fontWeight: '500' }} href={url} color={color}>
                    {summary ?? hash}
                  </LinkExternal>
                </Text>
                {icon}
              </Flex>
            </>
          )
        })}
    </Modal>
  )
}

export default RecentTransactionsModal
