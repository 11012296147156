import { ChainId } from 'shardex-sdk'
import tokens from './tokens'
import { FarmConfig } from './types'

const devFarms: FarmConfig[] = [
  {
    pid: 1,
    lpSymbol: `${tokens.wchain.symbol.toUpperCase()}/${tokens.usdt.symbol.toUpperCase()} LP`,
    lpAddresses: {
      [ChainId.TESTNET]: '0xD52509d84C6Ea5317607ea3aCE47DB528a78b79D',
      [ChainId.MAINNET]: '0xa0d7C8aA789362CDf4FAAE24b9D1528eD5a3777f',
    },
    token: tokens.wchain,
    quoteToken: tokens.usdt,
    // doubleDigRewarder: '0x945dee84f998e20647c1e7e71304d2393b4feb63',
    // doubleDigToken: tokens.wkcs,
    // doubleDigPerBlock: 0.003472222,
    version: 2,
  },
  {
    pid: 2,
    lpSymbol: 'WSHM/SDT LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0xc5d160530602f497A6ca9a1472EFD74AB977B38D',
      [ChainId.MAINNET]: '0xa0d7C8aA789362CDf4FAAE24b9D1528eD5a3777f',
    },
    token: tokens.wchain,
    quoteToken: tokens.sdt,
    // doubleDigRewarder: '0x945dee84f998e20647c1e7e71304d2393b4feb63',
    // doubleDigToken: tokens.wkcs,
    // doubleDigPerBlock: 0.003472222,
    version: 2,
  },
  {
    pid: 3,
    lpSymbol: 'SDT/USDT LP',
    lpAddresses: {
      [ChainId.TESTNET]: '0xeEb9426aFA1457DF18021Eb7a88cECb629Bf310a',
      [ChainId.MAINNET]: '0xa0d7C8aA789362CDf4FAAE24b9D1528eD5a3777f',
    },
    token: tokens.sdt,
    quoteToken: tokens.usdt,
    doubleDigRewarder: '0xc6C027f873184EF583443A132F5D04B7E04B1caf',
    doubleDigToken: tokens.test,
    doubleDigPerBlock: 0.003472222,
    version: 2,
  },
]
const proFarms: FarmConfig[] = []

const mainnetEnvName = ['production-mainnet', 'preview']

const isMainnet = mainnetEnvName.includes(process.env.REACT_APP_ENV_NAME ?? '')

export default isMainnet ? proFarms : devFarms
