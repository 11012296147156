import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import Box from '../../components/Box/Box'
import Grid from '../../components/Box/Grid'
import { Modal, ModalBody } from '../Modal'
import WalletCard from './WalletCard'
import config from './config'
import { Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
}

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => {
  return (
    <Modal
      title="Connect to a wallet"
      onDismiss={onDismiss}
      minWidth={isMobile ? '90%' : '440px'}
      bodyPadding="0px"
      style={{ maxWidth: isMobile ? '90%' : '440px' }}
    >
      {/* {config.map((entry, index) =>
      isMobile ? (
        <WalletCard
          key={entry.title}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          mb={index < config.length - 1 ? '8px' : '0'}
        />
      ) : !isMobile && !entry?.onlyH5 ? (
        <WalletCard
          key={entry.title}
          login={login}
          walletConfig={entry}
          onDismiss={onDismiss}
          mb={index < config.length - 1 ? '8px' : '0'}
        />
      ) : null
    )} */}
      <ModalBody width={isMobile ? '90%' : '440px'} style={{ margin: '0 auto' }}>
        <WalletWrapper padding="16px 24px 24px" maxHeight="453px" overflowY="auto">
          <Grid gridTemplateColumns={isMobile ? '1fr' : '1fr 1fr'} gridGap="12px">
            {config.map((wallet) => (
              <Box key={wallet.title}>
                <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
              </Box>
            ))}
          </Grid>
        </WalletWrapper>
      </ModalBody>
    </Modal>
  )
}

export default ConnectModal
