import { SafeAppConnector, useSafeAppConnection } from '@gnosis.pm/safe-apps-web3-react'
import i18next from 'i18next'
import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'

import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { Language } from '../uikit'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'

import { useFetchPublicData } from 'state/hooks'
import { allLanguages, EN } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import { RedirectPathToSwapOnly } from './Swap/redirects'

// import Info from './Info/index'
import Farms from './Farms/index'
// import FarmV1 from './Farms/Farms'
import Menu from '../components/Menu'
// import Home from './Home'
import FullLoading from 'components/FullLoading'
import Swap from './Swap'

import AddLiquidity from './AddLiquidity'
// const AddLiquidity = lazy(() => import(/* webpackChunkName:'AddLiquidity' */ './AddLiquidity'))
import Pool from './Pool/index'
// const Pool = lazy(() => import(/* webpackChunkName:'Pool' */ './Pool'))
import PoolFinder from './PoolFinder'
// const PoolFinder = lazy(() => import(/* webpackChunkName:'PoolFinder' */ './PoolFinder'))
import RemoveLiquidity from './RemoveLiquidity'
// const RemoveLiquidity = lazy(() => import(/* webpackChunkName:'RemoveLiquidity' */ './RemoveLiquidity'))
import Pools from './Pools/index'

import Referral from './Referral'

import { useFetchPriceList } from 'state/hooks'

// import { useFetchPriceList } from 'utils/prices'

// const Ifos = lazy(() => import(/* webpackChunkName:'Ifos' */ './Ifos'))
import Ifos from './Ifos'
// const Invite = lazy(() => import(/* webpackChunkName:'Invite' */ './Invite'))
// const Box = lazy(() => import(/* webpackChunkName:'Ifos' */ './Box'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  // margin-bottom: 64px;
  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0;
  }
`

const CACHE_KEY = 'MojitoSwapLanguage'

const safeMultisigConnector = new SafeAppConnector()

export default function App() {
  useFetchPriceList()
  useFetchPublicData()
  // useFetchProfile()
  // useFetchUserNfts(true)

  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])

  const getStoredLang = (storedLangCode: string) => {
    return allLanguages.filter((language) => {
      return language.code === storedLangCode
    })[0]
  }

  const triedToConnectToSafe = useSafeAppConnection(safeMultisigConnector)

  React.useEffect(() => {
    if (triedToConnectToSafe) {
      // fallback to other providers
      console.log('failed load gnosis')
    }
  }, [triedToConnectToSafe])

  useEffect(() => {
    const storedLangCode = localStorage.getItem(CACHE_KEY)
    if (storedLangCode) {
      const storedLang = getStoredLang(storedLangCode)
      setSelectedLanguage(storedLang)
      i18next.changeLanguage(storedLangCode)
    } else {
      setSelectedLanguage(EN)
    }
  }, [])

  const handleLanguageSelect = (langObject: Language) => {
    setSelectedLanguage(langObject)
    localStorage.setItem(CACHE_KEY, langObject.code)
    i18next.changeLanguage(langObject.code)
    window.location.reload()
  }

  return (
    <Suspense fallback={<FullLoading />}>
      <BrowserRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{
              selectedLanguage,
              setSelectedLanguage: handleLanguageSelect,
              translatedLanguage,
              setTranslatedLanguage,
            }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  <Popups />
                  <Web3ReactManager>
                    <Switch>
                      <Route exact path="/" component={Swap} />
                      <Route exact strict path="/swap" component={Swap} />
                      <Route exact strict path="/find" component={PoolFinder} />
                      <Route exact strict path="/pool" component={Pool} />
                      <Route exact path="/add" component={AddLiquidity} />
                      <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                      <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                      <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
                      <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                      <Route path="/farm" component={Farms} />
                      <Route path="/pools" component={Pools} />
                      <Route path="/reward" component={Referral} />
                      <Route path="/ido/detail/:projectId" component={Ifos} />
                      {/*      <Route path="/farmV1" component={FarmV1} />
                      <Route path="/minning" component={Minning} />
                      <Route path="/referral" component={Invite} />
                      <Route path="/create-profile" component={ProfileCreation} />
                      <Route path="/nfts" component={NftMarket} />
                      <Route path="/ido" component={Ifos} />
                      <Route path="/box" component={Box} />
                      <Route path="/rolling" component={Rolling} /> */}
                      {/* Info pages */}
                      {/* <Route path="/info">
                        <Info />
                      </Route> */}
                      {/* Redirection: These old routes are still used in the code base */}
                      <Route path="*" component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                </BodyWrapper>
              </Menu>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </BrowserRouter>
    </Suspense>
  )
}
