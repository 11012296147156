import { JsonRpcProvider } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import ApproveConfirmButtons from 'components/ApproveConfirmButtons'
import krc20Abi from 'constants/abis/erc20.json'
import ifoV2Abi from 'constants/abis/ifoV2.json'
import { Contract, ethers } from 'ethers'
import { parseUnits } from 'ethers/lib/utils'
import useApproveConfirmTransaction from 'hooks/useApproveConfirmTransaction'
import { useCallWithGasPrice } from 'hooks/useCallWithGasPrice'
import { PublicIfoData, WalletIfoData } from 'pages/Ifos/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BalanceInput, Button, Flex, Modal, ModalBody, Text } from 'uikit'
import { formatNumber, getBalanceAmount } from 'utils/formatBalance'
import { DEFAULT_TOKEN_DECIMAL } from '../../../../../constants'
import { Ifo, PoolIds } from '../../../../../constants/types'

interface Props {
  poolId: PoolIds
  ifo: Ifo
  publicIfoData: PublicIfoData
  walletIfoData: WalletIfoData
  userCurrencyBalance: BigNumber
  onSuccess: (amount: BigNumber, txHash: string) => void
  onDismiss?: () => void
}

const multiplierValues = [0.1, 0.25, 0.5, 0.75, 1]

// Default value for transaction setting, tweak based on KCC network congestion.
const gasPrice = parseUnits('20', 'gwei').toString()

const ContributeModal: React.FC<Props> = ({
  poolId,
  ifo,
  publicIfoData,
  walletIfoData,
  userCurrencyBalance,
  onDismiss,
  onSuccess,
}) => {
  const publicPoolCharacteristics = publicIfoData[poolId]
  const userPoolCharacteristics = walletIfoData[poolId]

  const { currency, address, unlimitedAddress } = ifo
  const { limitPerUserInLP } = publicPoolCharacteristics
  const { amountTokenCommittedInLP } = userPoolCharacteristics
  // const { contract } = walletIfoData

  const [value, setValue] = useState('')
  const { account, library } = useWeb3React()
  const { callWithGasPrice } = useCallWithGasPrice()
  const signer = library?.getSigner()
  const provider = signer ?? new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL).getSigner()
  const raisingTokenContract = new Contract(currency.address, krc20Abi, provider)
  const { t } = useTranslation()
  const valueWithTokenDecimals = new BigNumber(value).times(DEFAULT_TOKEN_DECIMAL)
  const maximumLpCommitable = limitPerUserInLP.isGreaterThan(0)
    ? limitPerUserInLP.minus(amountTokenCommittedInLP ?? 0)
    : userCurrencyBalance
  const contract = new Contract(address, ifoV2Abi, provider)
  const unlimitedContract = new Contract(unlimitedAddress, ifoV2Abi, provider)
  const submitContract = poolId === PoolIds.poolBasic ? contract : unlimitedContract
  const { isApproving, isApproved, isConfirmed, isConfirming, handleApprove, handleConfirm } =
    useApproveConfirmTransaction({
      onRequiresApproval: async () => {
        try {
          const response = await raisingTokenContract.allowance(account, submitContract.address)
          const currentAllowance = new BigNumber(response.toString())
          return currentAllowance.gt(0)
        } catch (error) {
          return false
        }
      },
      onApprove: () => {
        return callWithGasPrice(
          raisingTokenContract as any,
          'approve',
          [submitContract.address, ethers.constants.MaxUint256],
          {
            gasPrice,
          }
        )
      },
      onConfirm: () => {
        return callWithGasPrice(submitContract, 'depositPool', [valueWithTokenDecimals.toFixed(0, 1), 0], {
          gasPrice,
        })
      },
      onSuccess: async ({ receipt }) => {
        await onSuccess(valueWithTokenDecimals, receipt.transactionHash)
        onDismiss()
      },
    })

  return (
    <Modal title={`Contribute ${currency.symbol}`} onDismiss={onDismiss}>
      <ModalBody style={{ paddingBottom: '5px' }}>
        {limitPerUserInLP.isGreaterThan(0) && (
          <Flex justifyContent="space-between" mb="16px">
            <Text>{t('LAUNCHPAD_24')}</Text>
            <Text>{`${formatNumber(getBalanceAmount(maximumLpCommitable, currency.decimals).toNumber(), 3, 3)} ${
              ifo.currency.symbol
            }`}</Text>
          </Flex>
        )}
        <Flex justifyContent="space-between" mb="8px">
          <Text>{t('LAUNCHPAD_22')}:</Text>
          <Flex flexGrow={1} justifyContent="flex-end">
            <Text>{currency.symbol}</Text>
          </Flex>
        </Flex>
        <BalanceInput
          value={value}
          currencyValue={publicIfoData.currencyPriceInUSD.times(value || 0).toFixed(2)}
          onUserInput={setValue}
          isWarning={
            valueWithTokenDecimals.isGreaterThan(maximumLpCommitable) ||
            valueWithTokenDecimals.gt(maximumLpCommitable) ||
            valueWithTokenDecimals.gt(userCurrencyBalance)
          }
          decimals={currency.decimals}
          mb="8px"
        />
        {(valueWithTokenDecimals.isGreaterThan(maximumLpCommitable) ||
          valueWithTokenDecimals.gt(maximumLpCommitable)) && (
          <Text fontSize="12px" textAlign="right" color="#FF5B65" mt="-5px">
            {t('LAUNCHPAD_25')}
          </Text>
        )}
        {valueWithTokenDecimals.gt(userCurrencyBalance) && (
          <Text fontSize="12px" textAlign="right" color="#FF5B65" mt="-5px">
            {t('LAUNCHPAD_26', { asset: ifo.currency.symbol })}
          </Text>
        )}

        <Text color="textSubtle" textAlign="right" fontSize="12px" mb="16px">
          {`${t('LAUNCHPAD_27')}: ${getBalanceAmount(userCurrencyBalance, currency.decimals).toString()}`}
        </Text>
        <Flex justifyContent="space-between" mb="16px">
          {multiplierValues.map((multiplierValue, index) => (
            <Button
              key={multiplierValue}
              scale="xs"
              variant="tertiary"
              onClick={() => setValue(getBalanceAmount(maximumLpCommitable.times(multiplierValue)).toString())}
              style={{ background: '#EFEFF2' }}
              mr={index < multiplierValues.length - 1 ? '8px' : 0}
            >
              {multiplierValue * 100}%
            </Button>
          ))}
        </Flex>
        <Text color="textSubtle" fontSize="12px" mb="10px">
          {t('LAUNCHPAD_28')}
        </Text>
        <ApproveConfirmButtons
          isApproveDisabled={
            isConfirmed || isConfirming || isApproved || valueWithTokenDecimals.gt(userCurrencyBalance)
          }
          isApproving={isApproving}
          isConfirmDisabled={
            !isApproved ||
            isConfirmed ||
            valueWithTokenDecimals.isNaN() ||
            valueWithTokenDecimals.eq(0) ||
            valueWithTokenDecimals.gt(maximumLpCommitable) ||
            valueWithTokenDecimals.gt(userCurrencyBalance)
          }
          isConfirming={isConfirming}
          onApprove={handleApprove}
          onConfirm={handleConfirm}
          width="100%"
        />
      </ModalBody>
    </Modal>
  )
}

export default ContributeModal
