/* eslint-disable @typescript-eslint/no-unused-vars */
import BigNumber from 'bignumber.js'
import { AnimationButton } from 'components/Button'
import Col from 'components/Column'
import Elipisis from 'components/Elipisis/Elipisis'
import { useResponsive } from 'hooks/useResponse'
import useToast from 'hooks/useToast'
import { PublicIfoData, WalletIfoData } from 'pages/Ifos/types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Flex, Text } from 'uikit'
import getTimePeriods from 'utils/getTimePeriods'
import { Ifo, PoolIds } from '../../../../../constants/types'

interface Props {
  ifoVersion: number
  walletIfoData: WalletIfoData
  publicIfoData: PublicIfoData
  canClaim: boolean
  ifo: Ifo
  isCurrencyLpToken: boolean
  account?: string
}

interface StepperButtonProps {
  title: string
  amount: string | number
  hasJoin: boolean
  onClick: () => void
  claimed?: boolean
  countDown?: any
  disabled?: boolean
  isLoading?: boolean
  mb?: string
  claimText?: string
}

const ImgClaim = styled.img`
  width: 36px;
  height: 36px;
`

const Stepper = styled.div<{ isOpen: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 24px;
  border: 2px solid ${({ isOpen, theme }) => (isOpen ? theme.colors.primary : theme.colors.borderDark)};
  font-size: 16px;
  text-align: center;
  line-height: 32px;
  color: ${({ isOpen, theme }) => (isOpen ? theme.colors.primary : theme.colors.borderDark)};
  ${({ theme }) => theme.mediaQueries.md} {
    width: 36px;
  }
`

const ProgressLine = styled.div<{ isEnd: boolean }>`
  height: 1px;
  width: 102px;
  margin: 0 13px;
  border: 1px dashed ${({ isEnd, theme }) => (isEnd ? theme.colors.primary : theme.colors.borderDark)};
  ${({ theme }) => theme.mediaQueries.md} {
    width: 342px;
  }
`

const ClaimedButton = styled.div`
  border: 1px solid #979797;
  border-radius: 24px;
  height: 40px;
  width: 180px;
  color: white;
  font-size: 14px;
  text-align: center;
  line-height: 40px;
  cursor: not-allowed;
  margin-top: 8px;
`

const StepButton: React.FC<StepperButtonProps> = ({
  title,
  amount,
  onClick,
  hasJoin,
  claimed,
  countDown,
  disabled,
  isLoading,
  mb,
  claimText,
}) => {
  const fixPlural = (time: number) => {
    if (time > 1) return 's'
    return ' '
  }
  const { isMobile } = useResponsive()
  const isLastMinutes = countDown?.days === 0 && countDown?.hours === 0 && countDown?.minutes === 0
  const showTime = isLastMinutes
    ? '0day 0hour 1min'
    : `${countDown?.days}day${fixPlural(countDown?.days)}${countDown?.hours}hour${fixPlural(countDown?.hours)}${
        countDown?.minutes
      }min${fixPlural(countDown?.minutes)}`

  return (
    <Flex flexDirection="column" mb={mb ?? '0'}>
      <Text color="#B4B7C1" fontSize="14px">
        {title}
      </Text>
      <Text color="white" fontSize="24px" mt="3px">
        {amount}
      </Text>
      {hasJoin ? (
        claimed ? (
          <ClaimedButton>Already Claimed</ClaimedButton>
        ) : (
          <AnimationButton
            disabled={disabled}
            isLoading={isLoading ?? false}
            mt="8px"
            minWidth={isMobile ? '184px' : '200px'}
            onClick={onClick}
            style={{ height: '40px' }}
          >
            {countDown ? showTime : claimText ?? 'Claim'}
            {isLoading ? <Elipisis /> : null}
          </AnimationButton>
        )
      ) : (
        <AnimationButton
          disabled={true}
          isLoading={false}
          mt="8px"
          minWidth={isMobile ? '184px' : '200px'}
          style={{ height: '40px' }}
        >
          Claim
        </AnimationButton>
      )}
    </Flex>
  )
}

const ClaimButton: React.FC<Props> = ({
  ifoVersion,
  walletIfoData,
  publicIfoData,
  canClaim,
  ifo,
  isCurrencyLpToken,
  account,
}) => {
  const userPoolCharacteristics = walletIfoData[PoolIds.poolBasic]
  const userUnlimitedPoolCharacteristics = walletIfoData[PoolIds.poolUnlimited]
  const isBasicFinished = publicIfoData[PoolIds.poolBasic].status === 'finished'
  const isUnlimitedFinished = publicIfoData[PoolIds.poolUnlimited].status === 'finished'
  const { offeringAmountInToken, refundingAmountInLP } = userPoolCharacteristics
  const { refundingAmountInLP: unlimitedRefundingAmountInLP, releaserInfo } = userUnlimitedPoolCharacteristics

  const totalRefunding = new BigNumber(0).plus(unlimitedRefundingAmountInLP).plus(refundingAmountInLP)

  const { t } = useTranslation()
  const { toastSuccess } = useToast()
  const { isMobile } = useResponsive()

  const setPendingTx = (isPending: boolean) => walletIfoData.setPendingTx(isPending, PoolIds.poolBasic)
  const setUnlimitedPendingTx = (isPending: boolean) => walletIfoData.setPendingTx(isPending, PoolIds.poolUnlimited)

  const setUnlimitedVesterPendingTx1 = (isPending: boolean) =>
    walletIfoData.setVesterPendingTx1(isPending, PoolIds.poolUnlimited)

  const setUnlimitedVesterPendingTx2 = (isPending: boolean) =>
    walletIfoData.setVesterPendingTx2(isPending, PoolIds.poolUnlimited)

  const hasJoinBasicPool =
    walletIfoData[PoolIds.poolBasic].offeringAmountInToken.gt(0) ||
    walletIfoData[PoolIds.poolBasic].refundingAmountInLP.gt(0)
  const hasJoinUnlimitedPool =
    walletIfoData[PoolIds.poolUnlimited].offeringAmountInToken.gt(0) ||
    walletIfoData[PoolIds.poolUnlimited].refundingAmountInLP.gt(0)

  const hasUnlimitedEpoch1Claimed =
    userUnlimitedPoolCharacteristics?.releaserInfo?.claimInfo.length &&
    userUnlimitedPoolCharacteristics?.releaserInfo?.claimInfo?.[0]

  const hasUnlimitedEpoch2Claimed =
    userUnlimitedPoolCharacteristics?.releaserInfo?.claimInfo.length &&
    userUnlimitedPoolCharacteristics?.releaserInfo?.claimInfo?.[1]

  const [count, setCount] = useState<number>(
    ifo?.poolUnlimited?.claimEpoch1 ? ifo?.vesterTime - new Date().valueOf() : 0
  )
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const {
    unlimitedReleaser1UntilStart,
    unlimitedReleaser2UntilStart,
    unlimitedReleaser1hasStart,
    unlimitedReleaser2hasStart,
  } = publicIfoData[PoolIds.poolUnlimited]
  const unlimitedReleaser1TimeUtil = getTimePeriods(unlimitedReleaser1UntilStart)
  const unlimitedReleaser2TimeUtil = getTimePeriods(unlimitedReleaser2UntilStart)

  const stepper = React.useMemo(() => {
    let step = 1
    if (hasJoinBasicPool || hasJoinUnlimitedPool) {
      if (hasJoinBasicPool && hasJoinUnlimitedPool) {
        if (userPoolCharacteristics.hasClaimed && userUnlimitedPoolCharacteristics.hasClaimed) {
          step = 2
        }
      }
      if (hasJoinBasicPool && !hasJoinUnlimitedPool && userPoolCharacteristics.hasClaimed) {
        step = 2
      }
      if (!hasJoinBasicPool && hasJoinUnlimitedPool && userUnlimitedPoolCharacteristics.hasClaimed) {
        step = 2
      }
    }
    // todo
    if (hasJoinUnlimitedPool) {
      if (hasUnlimitedEpoch1Claimed) {
        step = 3
      }
      if (hasUnlimitedEpoch2Claimed) {
        step = 4
      }
    }
    return step
  }, [
    hasJoinBasicPool,
    hasJoinUnlimitedPool,
    hasUnlimitedEpoch1Claimed,
    hasUnlimitedEpoch2Claimed,
    userPoolCharacteristics.hasClaimed,
    userUnlimitedPoolCharacteristics.hasClaimed,
  ])

  const handleClaim = async () => {
    try {
      setPendingTx(true)
      setUnlimitedPendingTx(true)
      const promises: any[] = []

      if (ifoVersion === 1) {
        const tx = await walletIfoData[PoolIds.poolBasic].contract.harvest()
        const txUnlimited = await walletIfoData[PoolIds.poolUnlimited].contract.harvest()
        await tx.wait()
        await txUnlimited.wait()
      } else {
        if (
          (userPoolCharacteristics.offeringAmountInToken.isGreaterThan(0) ||
            userPoolCharacteristics.refundingAmountInLP.isGreaterThan(0)) &&
          !userPoolCharacteristics.hasClaimed
        ) {
          promises.push(walletIfoData[PoolIds.poolBasic].contract.harvestPool(0))
        }
        if (
          (userUnlimitedPoolCharacteristics.offeringAmountInToken.isGreaterThan(0) ||
            userUnlimitedPoolCharacteristics.refundingAmountInLP.isGreaterThan(0)) &&
          !userUnlimitedPoolCharacteristics.hasClaimed
        ) {
          promises.push(walletIfoData[PoolIds.poolUnlimited].contract.harvestPool(0))
        }
      }

      await Promise.all(promises)
        .then(async (hash) => {
          if (hash[0]) {
            await hash[0].wait()
          }
          if (hash[1]) {
            await hash[1].wait()
          }
          walletIfoData.fetchIfoData()
          await delay(6000)
          walletIfoData.setIsClaimed(PoolIds.poolBasic)
          walletIfoData.setIsClaimed(PoolIds.poolUnlimited)
          toastSuccess(t('LAUNCHPAD_18'))
        })
        .catch(() => {
          setTimeout(() => walletIfoData.fetchIfoData(), 6000)
        })
    } catch (error) {
      setUnlimitedPendingTx(false)
      setPendingTx(false)
    } finally {
      walletIfoData.fetchIfoData()
      setUnlimitedPendingTx(false)
      setPendingTx(false)
    }
  }

  const handleClaimVester = async (epoch: number) => {
    try {
      const promises: any[] = []
      if (
        (userUnlimitedPoolCharacteristics.offeringAmountInToken.isGreaterThan(0) ||
          userUnlimitedPoolCharacteristics.refundingAmountInLP.isGreaterThan(0)) &&
        unlimitedReleaser1hasStart &&
        epoch === 0
      ) {
        setUnlimitedVesterPendingTx1(true)
        promises.push(walletIfoData[PoolIds.poolUnlimited].contractVester.claim(account, 0))
        // const txUnlimited = await walletIfoData[PoolIds.poolUnlimited].contractVester.claim()
        // receipt = await txUnlimited.wait()
      }

      if (
        (userUnlimitedPoolCharacteristics.offeringAmountInToken.isGreaterThan(0) ||
          userUnlimitedPoolCharacteristics.refundingAmountInLP.isGreaterThan(0)) &&
        unlimitedReleaser2hasStart &&
        epoch === 1
      ) {
        setUnlimitedVesterPendingTx2(true)
        promises.push(walletIfoData[PoolIds.poolUnlimited].contractVester.claim(account, 1))
        // const txUnlimited = await walletIfoData[PoolIds.poolUnlimited].contractVester.claim()
        // receipt = await txUnlimited.wait()
      }

      await Promise.all(promises)
        .then(async (hash) => {
          if (hash[0]) {
            await hash[0].wait()
          }
          walletIfoData.fetchIfoData()
          await delay(6000)
          toastSuccess(t('LAUNCHPAD_18'))
        })
        .catch(() => {
          setTimeout(() => walletIfoData.fetchIfoData(), 6000)
        })
    } catch (error) {
      console.error(error)
    } finally {
      setUnlimitedVesterPendingTx1(false)
      setUnlimitedVesterPendingTx2(false)
      walletIfoData.fetchIfoData()
    }
  }

  return (
    <Col style={{ width: '100%' }}>
      {stepper > 1 ? (
        <ImgClaim src={require('assets/images/launchpad/success-2.svg').default} />
      ) : (
        <Stepper isOpen={stepper === 1 && (hasJoinBasicPool || hasJoinUnlimitedPool) && isUnlimitedFinished}>1</Stepper>
      )}
      <Flex
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        mt="15px"
        style={{ flexFlow: isMobile ? 'column-reverse' : 'initial' }}
      >
        <StepButton
          title={`Your ${ifo.currency.symbol} Amount`}
          amount={
            isUnlimitedFinished
              ? totalRefunding.gt(0) && totalRefunding.lte(new BigNumber(10).pow(16))
                ? '0.01'
                : totalRefunding.div(new BigNumber(10).pow(18)).toFixed(2, 1)
              : '-'
          }
          hasJoin={hasJoinBasicPool || hasJoinUnlimitedPool}
          disabled={
            userPoolCharacteristics.isPendingTx ||
            userUnlimitedPoolCharacteristics.isPendingTx ||
            !isUnlimitedFinished ||
            !canClaim
          }
          isLoading={userPoolCharacteristics.isPendingTx || userUnlimitedPoolCharacteristics.isPendingTx}
          claimed={
            (hasJoinBasicPool ? userPoolCharacteristics.hasClaimed : true) &&
            (hasJoinUnlimitedPool ? userUnlimitedPoolCharacteristics.hasClaimed : true)
          }
          mb="80px"
          claimText="Approve/Claim"
          onClick={() => {
            handleClaim()
          }}
        />
        <Col>
          <Text color="#B4B7C1" fontSize="14px">
            Your SDT Amount（Limited Sale for SBT Holders）
          </Text>
          <Text color="white" fontSize="24px" mt="3px" mb={isMobile ? '10px' : '0'}>
            {isBasicFinished
              ? offeringAmountInToken.gt(0) && offeringAmountInToken.lte(new BigNumber(10).pow(16))
                ? '0.01'
                : offeringAmountInToken.div(new BigNumber(10).pow(18)).toFixed(2, 1)
              : '-'}
          </Text>
        </Col>
      </Flex>
      <Flex alignItems="center">
        {stepper > 2 ? (
          <ImgClaim src={require('assets/images/launchpad/success-2.svg').default} />
        ) : (
          <Stepper isOpen={stepper === 2}>2</Stepper>
        )}
        <ProgressLine isEnd={stepper > 2} />
        <>
          {stepper > 3 ? (
            <ImgClaim src={require('assets/images/launchpad/success-2.svg').default} />
          ) : (
            <Stepper isOpen={stepper === 3}>3</Stepper>
          )}
        </>
      </Flex>
      <Flex flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" mt="15px">
        <StepButton
          title={`Your ${ifo.id.toUpperCase()}（50%, Limited Sale for Public）`}
          amount={
            isUnlimitedFinished
              ? new BigNumber(releaserInfo?.epochClaimAmountList[0]?.toString()).gt(0) &&
                new BigNumber(releaserInfo?.epochClaimAmountList[0]?.toString()).lte(new BigNumber(10).pow(16))
                ? '0.01'
                : new BigNumber(releaserInfo?.epochClaimAmountList[0]?.toString()).gt(0)
                ? new BigNumber(releaserInfo?.epochClaimAmountList[0]?.toString())
                    .div(new BigNumber(10).pow(18))
                    .toFixed(2, 1)
                : '-'
              : '-'
          }
          hasJoin={hasJoinUnlimitedPool}
          disabled={stepper !== 2 || !unlimitedReleaser1hasStart}
          isLoading={userUnlimitedPoolCharacteristics.isPendingVesterTx1}
          claimed={hasUnlimitedEpoch1Claimed}
          countDown={unlimitedReleaser1hasStart ? 0 : unlimitedReleaser1TimeUtil}
          mb={isMobile ? '40px' : '82px'}
          onClick={() => {
            handleClaimVester(0)
          }}
        />
        <StepButton
          title={`Your ${ifo.id.toUpperCase()} Amount（50%, Limited Sale for Public）`}
          amount={
            isUnlimitedFinished
              ? new BigNumber(releaserInfo?.epochClaimAmountList[1]?.toString()).gt(0) &&
                new BigNumber(releaserInfo?.epochClaimAmountList[1]?.toString()).lte(new BigNumber(10).pow(16))
                ? '0.01'
                : new BigNumber(releaserInfo?.epochClaimAmountList[1]?.toString()).gt(0)
                ? new BigNumber(releaserInfo?.epochClaimAmountList[1]?.toString())
                    .div(new BigNumber(10).pow(18))
                    .toFixed(2, 1)
                : '-'
              : '-'
          }
          hasJoin={hasJoinUnlimitedPool}
          disabled={stepper !== 3 || !unlimitedReleaser2hasStart}
          isLoading={userUnlimitedPoolCharacteristics.isPendingVesterTx2}
          countDown={unlimitedReleaser2hasStart ? 0 : unlimitedReleaser2TimeUtil}
          claimed={hasUnlimitedEpoch2Claimed}
          onClick={() => {
            handleClaimVester(1)
          }}
        />
      </Flex>
    </Col>
  )
}

export default ClaimButton
