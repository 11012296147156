import React from 'react'
import { SvgProps } from '../../../components/Svg'
import styled from 'styled-components'

const ExchangeIcon = require('../../../../assets/images/menu/exchange_b.svg').default
const ActiveExchangeIcon = require('../../../../assets/images/menu/exchange.png').default

const Svg = styled.img`
  width: 24px;
  height: 24px;
`

const Icon: React.FC<SvgProps> = (props) => {
  if (props?.isActive) {
    return <Svg src={ActiveExchangeIcon} />
  }
  return <Svg src={ExchangeIcon} />
}

export default Icon
