import CurrencyLogo from 'components/CurrencyLogo'
import { PublicIfoData } from 'pages/Ifos/types'
import React from 'react'
import styled from 'styled-components'
import { Flex, Text } from 'uikit'
import { formatNumber, getBalanceNumber } from 'utils/formatBalance'
import { Ifo, PoolIds } from '../../../../constants/types'

interface Props {
  ifo: Ifo
  publicIfoData: PublicIfoData
}

const Imgs = {
  graph: require('assets/images/launchpad/graph.svg').default,
  market: require('assets/images/launchpad/market.svg').default,
  info: require('assets/images/launchpad/info.svg').default,
}

const StyledContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 24px auto 100px;
`

const StyledInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const StyledInfoContainer = styled.div`
  background: rgba(255, 255, 255, 0.1);
  // width: 100%;
  height: 277px;
  border-radius: 20px;
  padding: 20px 24px;
`

const StyledInfos = styled(StyledInfoContainer)`
  width: 100%;
  margin-top: 24px;
  @media (max-width: 768px) {
    height: auto;
  }
`

const ImgIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`

const Lines = styled.div`
  background: rgba(115, 126, 141, 0.16);
  width: 100%;
  height: 1px;
  margin-top: 14px;
  padding: 0 24px;
  margin-left: -24px;
  box-sizing: content-box;
`

const Info: React.FC<{ title: string; content: string }> = ({ title, content }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" mt="24px">
      <Text fontSize="14px" color="#B4B7C1">
        {title}
      </Text>
      <Text fontSize="14px" color="white">
        {content}
      </Text>
    </Flex>
  )
}

const DetailInfo: React.FC<Props> = ({ ifo, publicIfoData }) => {
  const basicPoolCharacteristic = publicIfoData[PoolIds.poolBasic]
  const unlimitedPoolCharacteristic = publicIfoData[PoolIds.poolUnlimited]
  const { currencyPriceInUSD } = publicIfoData
  const totalLPCommitted =
    getBalanceNumber(basicPoolCharacteristic.totalAmountPool, ifo.currency.decimals) +
    getBalanceNumber(unlimitedPoolCharacteristic.totalAmountPool, ifo.currency.decimals)
  const totalLPCommittedInUSD = currencyPriceInUSD.times(totalLPCommitted)
  const totalCommitted = `~$${formatNumber(totalLPCommittedInUSD.toNumber(), 0, 0)}`
  return (
    <StyledContainer>
      <StyledInfoGrid>
        <StyledInfoContainer>
          <Flex alignItems="center">
            <ImgIcon src={Imgs.graph} />
            <Text fontSize="20px" color="white">
              Tokenomic
            </Text>
          </Flex>
          <Lines />
          <Flex justifyContent="space-between" alignItems="center" mt="24px">
            <Text fontSize="14px" color="#B4B7C1">
              Ticker
            </Text>
            <Flex alignItems="center">
              <CurrencyLogo currency={ifo.token as any} size="20px" />
              <Text fontSize="14px" color="white" ml="4px">
                {ifo.id.toUpperCase()}
              </Text>
            </Flex>
          </Flex>
          <Info title="Total Supply" content={ifo.totalSupply} />
          <Info title="Total Supply for IDO" content={ifo.initialSupply} />
          <Info
            title="Token Sale Price"
            content={`${ifo.poolBasic.tokenOfferingPrice}~${ifo.poolUnlimited.tokenOfferingPrice}${ifo.currency.symbol}`}
          />
        </StyledInfoContainer>
        <StyledInfoContainer>
          <Flex alignItems="center">
            <ImgIcon src={Imgs.market} />
            <Text fontSize="20px" color="white">
              Market
            </Text>
          </Flex>
          <Lines />
          <Info title="Total Raised" content={totalLPCommittedInUSD.gt(0) ? totalCommitted : '-'} />
        </StyledInfoContainer>
      </StyledInfoGrid>
      <StyledInfos>
        <Flex alignItems="center">
          <ImgIcon src={Imgs.info} />
          <Text fontSize="20px" color="white">
            Infos
          </Text>
        </Flex>
        <Lines />
        <Text fontSize="14px" color="white" mt="20px" lineHeight="24px">
          {ifo.introduction}
        </Text>
      </StyledInfos>
    </StyledContainer>

    // <Text fontSize="32px" bold>{t('LAUNCHPAD_9')}</Text>
    // <StyleIntro>
    //   <Text fontSize="24px" bold>{t('LAUNCHPAD_10')}</Text>
    //   <Text mt="10px" fontSize="14px" lineHeight="25px" color={theme.colors.textRemark}>{ifo.introduction}</Text>
    //   <Text fontSize="24px" mt="35px" bold>{t('LAUNCHPAD_11')}</Text>
    //   <Text mt="10px" fontSize="14px" lineHeight="25px" color={theme.colors.textRemark}>
    //     Four major features make Pikaster the game-changer: <br/>
    //     1.In-game wallet and marketplace in the App<br/>
    //     The product design that has never been seen in other Game-fi projects makes the operation of the entire game and the transaction in the game much easier and safer.<br/>
    //     2.Creative Three-Token Economic Model<br/>
    //     One governance token plus two game tokens are all issued in limited quantities. To adjust the release-consumption relationship one token to alleviate the problems caused by inflation of another might be the most efficient means to protect the long-term benefits of players.<br/>
    //     3.Profit-Sharing System<br/>
    //     As a team that always puts the community first, Pikaster provides various methods such as staking, referral, leasing, and community dividends, so that friends who participate in community construction in any way can benefit from the platform.<br/>
    //     4.More ways of gameplay<br/>
    //     Pikaster, with a strong RD team, makes the game-fi project into a real game for the first time. In addition to the common PVP and PVE, Pikaster also designed a variety of entertainment methods such as World Trees, BOSS challenges,etc.<br/>
    //   </Text>
    //   {
    //     isMobile ?
    //     <>
    //       <Text color="textRemark" fontSize="14px" mt="30px">{t('LAUNCHPAD_12')}</Text>
    //       <Text bold mb="22px" mt="4px">{ifo.totalSupply}</Text>
    //       <Text color="textRemark" fontSize="14px">{t('LAUNCHPAD_13')}</Text>
    //       <Text bold mb="22px" mt="4px">{ifo.totalSale}</Text>
    //       <Text color="textRemark" fontSize="14px">{t('LAUNCHPAD_14')}</Text>
    //       <Text bold mb="22px" mt="4px">{ifo.targetRaise}</Text>
    //       <Text color="textRemark" fontSize="14px">{t('LAUNCHPAD_15')}</Text>
    //       <Text bold mb="22px" mt="4px">{ifo.tokenOfferingPrice}</Text>
    //       <Text color="textRemark" fontSize="14px">{t('LAUNCHPAD_16')}</Text>
    //       <Text bold mb="22px" mt="4px">{ifo.tokenVestingPeriod}</Text>
    //       <Text color="textRemark" fontSize="14px">{t('LAUNCHPAD_17')}</Text>
    //       <Text bold>{ifo.tokenDistribution}</Text>
    //     </>
    //     :
    //     <StyleTable>
    //       <tbody>
    //         <tr>
    //           <td>{t('LAUNCHPAD_12')}</td>
    //           <td>{ifo.totalSupply}</td>
    //         </tr>
    //         <tr>
    //           <td>{t('LAUNCHPAD_13')}</td>
    //           <td>{ifo.totalSale}</td>
    //         </tr>
    //         <tr>
    //           <td>{t('LAUNCHPAD_14')}</td>
    //           <td>{ifo.targetRaise}</td>
    //         </tr>
    //         <tr>
    //           <td>{t('LAUNCHPAD_15')}</td>
    //           <td>${ifo.tokenOfferingPrice}</td>
    //         </tr>
    //         <tr>
    //           <td>{t('LAUNCHPAD_16')}</td>
    //           <td>{ifo.tokenVestingPeriod}</td>
    //         </tr>
    //         <tr>
    //           <td>{t('LAUNCHPAD_17')}</td>
    //           <td>{ifo.tokenDistribution}</td>
    //         </tr>
    //       </tbody>
    //     </StyleTable>
    //   }
    // </StyleIntro>
  )
}

export default DetailInfo
