import React from 'react'
import styled from 'styled-components'

const Label = styled.div`
  font-size: 14px;
  color: #666;
  text-align: left;
  font-family: '' Poppins '';
  font-weight: normal;
`

const CallLayoutWrap = styled.div`
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`

const ContentContainer = styled.div`
  min-height: 24px;
  display: flex;
  align-items: center;
`

interface CellLayoutProps {
  label?: string
}

const CellLayout: React.FC<CellLayoutProps> = ({ label = '', children }) => {
  return (
    <CallLayoutWrap>
      {label && <Label>{label}</Label>}
      <ContentContainer style={{ marginTop: '7px' }}>{children}</ContentContainer>
    </CallLayoutWrap>
  )
}

export default CellLayout
