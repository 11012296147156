import React from 'react'
import { Box, Flex, Text, Input, InputProps } from 'uikit'
import BN from 'bignumber.js'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import TokenImage from 'uikit/components/Image/TokenImage'
import { getTokenUrl } from 'utils/getTokenUrl'
import { RowBetween } from 'components/Row'
import { useResponsive } from 'hooks/useResponse'

export const getDisplayBalance = (balance: any) => {
  const displayBalance = new BN(balance)
  if (displayBalance.gt(0) && displayBalance.lte(0.01)) {
    return '< 0.01'
  }
  return new BN(new BN(displayBalance).toFixed(2, 1)).toString(10)
}

interface TokenInputProps extends InputProps {
  max: number | string
  symbol: string
  value: string
  onSelectMax?: () => void
  onChange: (evt: React.FormEvent<HTMLInputElement>) => void
}

const TokenWrap = styled.div`
  width: 24px;
  color: #fff;
  box-shadow: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  margin-right: 8px;
`

const InputSuffix = styled(RowBetween)`
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  transform: translateY(-50%) translateX(-16px);
  background: #efeff2;
  height: 96%;
`
const InputWrap = styled.div`
  position: relative;
  margin-top: 16px;
`

const StyledInput = styled(Input)`
  box-shadow: none;
  width: 100%;
  background: #efeff2;
  border: 1px solid transparent;
  height: 54px;
  border-radius: 8px;
  padding-left: 10px;
  position: relative;
  font-size: 16px;
  &:focus:not(:disabled) {
    border: 1px solid #f5455b;
  }

  &:focus {
    border: 1px solid #f5455b;
  }
`

const TokenInput: React.FC<TokenInputProps> = ({ max, symbol, onChange, value }) => {
  const { t } = useTranslation()
  const { isMobile } = useResponsive()
  return (
    <Box>
      <Flex justifyContent="space-between" minHeight="21px" mb="8px">
        <Text color="#666" fontSize="14px">
          {t('COMPONENTS_141')}
        </Text>
        <Text color="#666" fontSize="14px">
          {t('COMPONENTS_142')}
          {getDisplayBalance(new BN(max))}
        </Text>
      </Flex>
      <InputWrap>
        <StyledInput onChange={onChange} value={value} />
        <InputSuffix>
          <TokenWrap>
            <TokenImage src={getTokenUrl(symbol.toLowerCase())} width={24} height={24} />
          </TokenWrap>
          <Text
            fontSize={isMobile ? '12px' : '14px'}
            fontWeight={500}
            style={{
              textTransform: 'uppercase',
              width: 'auto',
              lineHeight: '100%',
              textAlign: 'right',
              marginLeft: '10px',
            }}
          >
            {symbol}
          </Text>
        </InputSuffix>
      </InputWrap>
    </Box>
  )
}

export default TokenInput
