import React from 'react'
import styled from 'styled-components'
import { Text } from 'uikit'
import { ColumnCenter } from 'components/Column'
import InviteItem from './InviteItem'
import CountUp from 'react-countup'
import UnlockButton from 'components/ConnectWalletButton'
import { MouseoverTooltip } from 'components/Tooltip'

interface ItemProps {
  account: string
  isMobile: boolean
  points?: number
  confirmed?: number
  rank?: number
  connectWallet?: () => void
  presentInvite?: () => void
}

const Imgs = {
  box: require('assets/images/referral/box-2.webp').default,
}

const StepContainer = styled.div<{ account?: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: ${({ account }) => (account ? '145px 0 0 12px' : '140px 0 0 12px')};
  @media (max-width: 768px) {
    margin: ${({ account }) => (account ? '60px 0 0 12px' : '200px 0 0 12px')};
    flex-direction: column;
  }
`

const ImgBox = styled.img`
  margin: 20px 0;
  width: 240px;
  height: 240px;
  border-radius: 25px;
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  margin: 24px 0 32px 0;
  width: 505px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ContentBox = styled.div`
  height: 100px;
  width: 245px;
  background: #ffffff1a;
  border-radius: 20px;
  padding: 14px 27px;
`

const ContentItem: React.FC<{ title: string; amount: number; account?: string }> = ({ title, amount, account }) => {
  return (
    <ContentBox>
      <Text color="#FFFFFF80" fontSize="14px">
        {title}
      </Text>
      <Text color="#FFFFFF" fontSize="24px" bold mt="5px">
        {/* @ts-ignore */}
        {account ? <CountUp duration={1.5} start={0} separator="," end={amount} /> : '-'}
      </Text>
    </ContentBox>
  )
}

const StepItem: React.FC<ItemProps> = ({
  account,
  isMobile,
  points = 0,
  confirmed = 0,
  rank = 0,
  connectWallet,
  presentInvite,
}) => {
  return (
    <StepContainer account={account}>
      <div>
        <Text fontSize="32px" bold color="primary">
          Step 2
        </Text>
        <Text color="white" mt="10px">
          Ranked the top on the share points leaderboard to boost your airdrop rewards
        </Text>
        {account ? (
          <>
            <ContentGrid>
              <ContentItem title="Total Share Points" amount={points ?? 0} account={account} />
              <ContentItem title="Confirmed referral" amount={confirmed ?? 0} account={account} />
              <ContentItem title="Your Ranking" amount={rank ?? 0} account={account} />
            </ContentGrid>
            <InviteItem account={account} connectWallet={connectWallet} presentInvite={presentInvite} />
          </>
        ) : (
          <UnlockButton width={isMobile ? '270px' : '320px'} mt="24px" />
        )}
      </div>
      <ColumnCenter style={{ width: isMobile ? 'auto' : '320px' }}>
        <Text color="white" textAlign="center" mt={isMobile ? '60px' : '0'}>
          Rewards for <span style={{ color: '#FF3093' }}>Step 2</span>
        </Text>
        <MouseoverTooltip placement="top" text="Coming soon">
          <ImgBox src={Imgs.box} />
        </MouseoverTooltip>
      </ColumnCenter>
    </StepContainer>
  )
}

export default StepItem
