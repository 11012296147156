import { useEffect, useCallback } from 'react'
import Column from 'components/Column'
import Meta from 'components/Meta'
import { useActiveWeb3React } from 'hooks'
import React, { useState } from 'react'
import { Text, useModal, useWalletModal } from 'uikit'
import CodeModal from './components/CodeModal'
import CopyItem from './components/CopyItem'
import StepOne from './components/Step1'
import StepTwo from './components/Step2'
import TwitterModal from './components/TwitterModal'
import * as LocalStyle from './styles'

import Row from 'components/Row'
import useAuth from 'hooks/useAuth'
import { useResponsive } from 'hooks/useResponse'
import {
  getUserInfo,
  UserInfo,
  SocialProps,
  getTwitterLink,
  getDiscordLink,
  postAuthTwitter,
  postAuthDiscord,
} from 'state/referral/hooks'
import { MediaType } from './components/Step1'
import { useToast } from 'state/hooks'
import { useHistory } from 'react-router'

interface SocialLinkProps extends SocialProps {
  url?: string
}

function getQueryString(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) {
    return decodeURIComponent(r[2])
  }
  return null
}

export default function PageReferral() {
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const [twitterInfo, setTwitterInfo] = useState<SocialLinkProps>()
  const [discordInfo, setDiscordInfo] = useState<SocialLinkProps>()
  const [showCodeModal, setShowCodeModal] = useState(false)

  const { account } = useActiveWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, account as any)
  const { isMobile } = useResponsive()
  const { toastSuccess, toastError } = useToast()
  const history = useHistory()

  const getTwitterAuth = useCallback(async () => {
    try {
      const res = await getTwitterLink()
      if (res.msg === 'SUCCESS') {
        setTwitterInfo((prevState) => {
          return { ...prevState, url: res.data }
        })
      } else {
        toastError(res.msg)
      }
    } catch (e) {
      console.error('twitter auth error', e)
    }
  }, [toastError])

  const getDiscordAuth = useCallback(async () => {
    try {
      const res = await getDiscordLink()
      if (res.msg === 'SUCCESS') {
        setDiscordInfo((prevState) => {
          return { ...prevState, url: res.data }
        })
      } else {
        toastError(res.msg)
      }
    } catch (e) {
      console.error('twitter auth error', e)
    }
  }, [toastError])

  const getInfo = useCallback(async () => {
    try {
      const res = await getUserInfo(account)
      if (res.msg === 'SUCCESS') {
        if (res.data.socials.length) {
          const socialsObj = res.data.socials.reduce((acc, val) => {
            return { ...acc, [val.type]: val }
          }, {})
          if (!socialsObj[MediaType.TWITTER.toUpperCase()]) {
            getTwitterAuth()
          } else {
            setTwitterInfo({ ...socialsObj[MediaType.TWITTER.toUpperCase()] })
          }
          if (!socialsObj[MediaType.DISCORD.toUpperCase()]) {
            getDiscordAuth()
          } else {
            setDiscordInfo({ ...socialsObj[MediaType.DISCORD.toUpperCase()] })
          }
        } else {
          getTwitterAuth()
          getDiscordAuth()
          setTwitterInfo(null)
          setDiscordInfo(null)
        }
        setUserInfo(res.data)
      } else {
        toastError(res.msg)
      }
    } catch (e) {
      console.error('user info =', e)
    }
  }, [account, getDiscordAuth, getTwitterAuth, toastError])

  const bindTwitter = async (token: string, verifier: string) => {
    if (!account) return
    try {
      const res = await postAuthTwitter(account, token, verifier)
      if (res.msg === 'SUCCESS') {
        console.log('result =', res)
        getInfo()
        toastSuccess('Successfully Bind')
      } else {
        history.push('/reward')
        toastError(res.msg)
      }
    } catch (e) {
      console.error('bind twitter error = ', e)
    }
  }

  const bindDiscord = async (state: string, code: string) => {
    if (!account) return
    try {
      const res = await postAuthDiscord(account, state, code)
      if (res.msg === 'SUCCESS') {
        console.log('result =', res)
        getInfo()
        toastSuccess('Successfully Bind')
      } else {
        history.push('/reward')
        toastError(res.msg)
      }
    } catch (e) {
      console.error('bind discord error = ', e)
    }
  }

  const [onPresentCodeModal] = useModal(
    <CodeModal
      account={account}
      showCodeModal={showCodeModal}
      referral={userInfo?.code}
      updateBindInfo={() => getInfo()}
      onDismiss={() => {
        console.log('on dismiss')
      }}
    />
  )

  const checkUrl = useCallback(() => {
    const params = window.location.search
    const isAccount = account === userInfo?.address
    if (params.includes('state') && params.includes('code') && !discordInfo && isAccount) {
      const state = getQueryString('state')
      const code = getQueryString('code')
      bindDiscord(state, code)
    }
    if (params.includes('oauth_token') && params.includes('oauth_verifier') && !twitterInfo && isAccount) {
      const token = getQueryString('oauth_token')
      const verifier = getQueryString('oauth_verifier')
      bindTwitter(token, verifier)
    }
    if (params.includes('ref=') && userInfo && !userInfo?.referer && isAccount) {
      const ref = getQueryString('ref')
      if (ref !== userInfo?.code) {
        onPresentCodeModal()
      }
      setShowCodeModal(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, userInfo])

  useEffect(() => {
    if (account) {
      getInfo()
    }
  }, [account, getInfo])

  useEffect(() => {
    if (account && userInfo) {
      checkUrl()
    }
  }, [account, userInfo, checkUrl])

  const [onPresentTwitterModal] = useModal(
    <TwitterModal
      referral={userInfo?.code}
      onDismiss={() => {
        console.log('on dismiss')
      }}
    />
  )
  return (
    <LocalStyle.Container>
      <Meta title="Reward | Shardex" />
      <LocalStyle.ReferralDecorate />
      <LocalStyle.ReferralContent>
        <Text
          fontSize="64px"
          fontWeight="700"
          textAlign={isMobile ? 'center' : 'left'}
          style={{
            background: `linear-gradient(91.57deg, #ff3093 20%, #5218f4 80%)`,
            color: 'transparent',
            WebkitBackgroundClip: 'text',
          }}
        >
          SharDex Waitlist
        </Text>
        <Text mt="20px" color="white" maxWidth="700px" mb="44px" textAlign={isMobile ? 'center' : 'left'}>
          Refer 3 friends to claim Soul Bound Token, and join the SharDex NFT Waitlist for Genesis Airdrop
        </Text>
        {account ? (
          <>
            <CopyItem
              title="Referral Link"
              account={account}
              data={`${process.env.REACT_APP_REFERRAL_URL}${userInfo?.code ?? '-'}`}
              isMobile={isMobile}
            />
            <CopyItem title="Referral Code" account={account} data={`${userInfo?.code ?? '-'}`} isMobile={isMobile} />
          </>
        ) : null}

        <Row align="flex-start" mt="100px">
          <Column style={{ alignItems: 'center', marginTop: '20px' }}>
            <LocalStyle.ReferralDot />
            <LocalStyle.Line />
            <LocalStyle.ReferralDot />
          </Column>
          <Column style={{ width: '100%' }}>
            <StepOne
              account={account}
              isMobile={isMobile}
              twitter={twitterInfo?.username ?? ''}
              discord={discordInfo?.username ?? ''}
              twitterLink={twitterInfo?.url ?? ''}
              discordLink={discordInfo?.url ?? ''}
              confirmed={userInfo?.confirmed}
              sign={userInfo?.sign}
              connectWallet={onPresentConnectModal}
              presentInvite={() => {
                onPresentTwitterModal()
              }}
            />
            <StepTwo
              account={account}
              isMobile={isMobile}
              points={userInfo?.point}
              confirmed={userInfo?.confirmed}
              rank={userInfo?.rank}
              connectWallet={onPresentConnectModal}
              presentInvite={() => {
                onPresentTwitterModal()
              }}
            />
          </Column>
        </Row>
      </LocalStyle.ReferralContent>
    </LocalStyle.Container>
  )
}
