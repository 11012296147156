import { Colors } from './types'

export const baseColors = {
  failure: '#FF3093',
  primary: '#FF3093',
  primaryBright: '#53DEE9',
  primaryDark: '#0098A1',
  secondary: '#5DDA98',
  success: '#31D0AA',
  warning: '#FF9A32',
}

export const additionalColors = {
  binance: '#F0B90B',
  // Settings Card Mask color
  overlay: 'rgba(1, 1, 1, 0.7)',
}

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  tagNew: '#FF5B65',
  background: '#faf9fa',
  backgroundDisabled: '#B4B7C1',
  backgroundAlt: '#FFFFFF',
  backgroundHover: '#EAEAEA',
  backgroundCard: '#F5F5F5',
  cardBorder: '#E7E3EB',
  contrast: '#191326',
  dropdown: '#F6F6F6',
  dropdownDeep: '#EEEEEE',
  invertedContrast: '#FFFFFF',
  input: '#F5F9EE',
  inputSecondary: '#F5FDF9',
  tertiary: '#EFF4F5',
  text: '#040A2D',
  textDisabled: '#BDC2C4',
  textSubtle: '#66727F',
  textRemark: '#494E67',
  textTips: '#292929',
  disabled: '#B4B7C1',
  borderColor: '#fff',
  borderDark: '#C9C9C9',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%)',
    cardHeader: 'linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%)',
    blue: 'linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%)',
    violet: 'linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%)',
    violetAlt: 'linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  tagNew: '#FF5B65',
  secondary: '#9A6AFF',
  background: '#08060B',
  backgroundDisabled: '#3c3742',
  backgroundAlt: '#27262c',
  backgroundHover: '#EAEAEA',
  backgroundCard: '#F5F5F5',
  cardBorder: '#383241',
  borderColor: '#fff',
  contrast: '#FFFFFF',
  dropdown: '#1E1D20',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#F5F9EE',
  inputSecondary: '#262130',
  primaryDark: '#0098A1',
  tertiary: '#5DDA98',
  text: '#F4EEFF',
  textDisabled: '#ffffff',
  textSubtle: '#64ffb2',
  textRemark: '#494E67',
  textTips: '#292929',
  disabled: '#524B63',
  borderDark: '#C9C9C9',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%)',
    inverseBubblegum: 'linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%)',
    cardHeader: 'linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
    gold: 'linear-gradient(180deg, #FFD800 0%, #FDAB32 100%)',
  },
}
