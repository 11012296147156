import React from 'react'
import DetailIfo from './Detail'

const Ifos = () => {
  return (
    <>
      <DetailIfo />
    </>
  )
}

export default Ifos
