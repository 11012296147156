import React, { CSSProperties, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Input, Text, Flex, Box } from '../../uikit'
import { useUserDeadline } from 'state/user/hooks'
import { useResponsive } from '../../hooks/useResponse'
import { useTranslation } from 'react-i18next'

const StyleInput = styled(Input)`
  &:focus {
    border: ${({ theme }) => `3px solid ${theme.colors.primary}`};
  }
`

const Field = styled.div`
  align-items: center;
  display: inline-flex;

  & > ${Input} {
    max-width: 156px;
  }
`

type TransactionDeadlineSettingModalProps = {
  translateString: (translationId: number, fallback: string) => string
  styles: CSSProperties
}

const TransactionDeadlineSetting = ({ translateString, styles }: TransactionDeadlineSettingModalProps) => {
  const [deadline, setDeadline] = useUserDeadline()
  const [value, setValue] = useState(deadline / 60) // deadline in minutes
  const [error, setError] = useState<string | null>(null)
  const { t } = useTranslation()
  const { isMobile } = useResponsive()

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = evt.target
    setValue(parseInt(inputValue, 10))
  }

  // Updates local storage if value is valid
  useEffect(() => {
    try {
      const rawValue = value * 60
      if (!Number.isNaN(rawValue) && rawValue > 0) {
        setDeadline(rawValue)
        setError(null)
      } else {
        setError(t('COMPONENTS_211'))
      }
    } catch {
      setError(t('COMPONENTS_211'))
    }
  }, [value, setError, setDeadline, translateString, t])

  return (
    <>
      <Box mb="16px" style={styles}>
        <Flex alignItems="center" mb="8px">
          <Text>{t('COMPONENTS_58')}</Text>
        </Flex>
        <Field>
          <StyleInput
            type="number"
            step="1"
            min="1"
            value={value}
            onChange={handleChange}
            scale="md"
            style={{
              width: '156px',
              borderRadius: '8px',
              background: '#fff',
              height: isMobile ? '36px' : '48px',
              border: '1px solid rgba(115, 126, 141, 0.16)',
            }}
          />
          <Text fontSize="14px" ml="8px" color="textRemark">
            {t('COMPONENTS_60')}
          </Text>
        </Field>

        {error && (
          <Text mt="8px" color="failure">
            {error}
          </Text>
        )}
      </Box>
      {/* <Box style={{ marginTop: '20px' }}>
        <Field>
          <ToggleWrapper style={{ marginLeft: 0 }}>
            <Toggle checked={enableMaxHops} onChange={toggle} scale="sm" style={{ width: '48px', height: '28px' }} />
            <Text fontSize="14px" color="#01142A" fontWeight={500} fontFamily="Poppins">
              {t('COMPONENTS_61')}
              <QuestionHelper size={16} text={t('COMPONENTS_189')} />
            </Text>
          </ToggleWrapper>
          <ToggleWrapper style={{ marginLeft: '40px' }}>
            <Toggle
              checked={isExpertMode}
              onChange={toggleExpertMode}
              scale="sm"
              style={{ width: '48px', height: '28px' }}
            />
            <Text fontSize="14px" color="#01142A" fontWeight={500} fontFamily="Poppins">
              {t('COMPONENTS_62')}
              <QuestionHelper size={16} text={t('COMPONENTS_63')} />
            </Text>
          </ToggleWrapper>
        </Field>
      </Box> */}
    </>
  )
}

export default TransactionDeadlineSetting
