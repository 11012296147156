import { useEffect, useRef } from 'react'
import { Image } from 'components'
import { ColumnCenter } from 'components/Column'
import UnlockButton from 'components/ConnectWalletButton'
import React from 'react'
import CountUp from 'react-countup'
import { useSBTSupply, useSBTMinted } from 'state/referral/hooks'
import styled from 'styled-components'
import { Text, Flex } from 'uikit'
import InviteItem from './InviteItem'

interface signProps {
  address: string
  signature: string
  data: string
}
interface ItemProps {
  account: string
  isMobile: boolean
  twitter?: string
  discord?: string
  twitterLink?: string
  discordLink?: string
  sign?: signProps
  confirmed?: number
  connectWallet?: () => void
  presentInvite?: () => void
}

const Imgs = {
  twitter: require('assets/images/referral/twitter.svg').default,
  discord: require('assets/images/referral/discord.svg').default,
  people: require('assets/images/referral/people.svg').default,
  box: require('assets/images/referral/sbt.gif').default,
  border: require('assets/images/referral/border.png').default,
}

export enum MediaType {
  TWITTER = 'twitter',
  DISCORD = 'discord',
}

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: 12px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const ShareBtn = styled.div<{ isTwitter: boolean; hasBind: boolean; mt?: string }>`
  width: 240px;
  height: 48px;
  border-radius: 40px;
  background: ${({ isTwitter, hasBind }) => (hasBind ? 'rgba(255, 255, 255, 0.1)' : isTwitter ? '#34C6F4' : '#5C21D9')};
  cursor: ${({ hasBind }) => (hasBind ? 'auto' : 'pointer')};
  padding: 0 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${({ mt }) => mt ?? '24px'};
  font-size: 16px;
  color: white;
`

const ImgBox = styled.img`
  width: 236px;
  height: 236px;
  border-radius: 24px;
`

const StyledWaitList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledImgInfo = styled.img`
  cursor: pointer;
  width: 20px;
  height: 20px;
`

const StyleBorder = styled.div`
  background: url(${Imgs.border}) center center;
  width: 240px;
  height: 240px;
  border-radius: 24px;
  overflow: hidden;
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StepItem: React.FC<ItemProps> = ({
  account,
  isMobile,
  twitter,
  discord,
  confirmed,
  twitterLink,
  discordLink,
  connectWallet,
  presentInvite,
}) => {
  const { totalSupply } = useSBTSupply()
  const { mintResult, updateMintStatus } = useSBTMinted(account)
  const intervalRef = useRef<any>()

  const clickMedia = (type: MediaType) => {
    if (!account) {
      connectWallet()
      return
    }

    if (type === MediaType.TWITTER) {
      if (twitter || !twitterLink) return
      window.open(twitterLink, '_blank')
    } else if (type === MediaType.DISCORD) {
      if (discord || !discordLink) return
      window.open(discordLink, '_blank')
    }
  }

  useEffect(() => {
    if (confirmed && !mintResult) {
      intervalRef.current = setInterval(() => {
        updateMintStatus()
      }, 30000)
    }
    return () => clearInterval(intervalRef.current)
  }, [confirmed, mintResult, updateMintStatus])

  // const mintNFT = async () => {
  //   if (!account || (account && mintResult) || !sign?.data) return
  //   setLoading(true)
  //   const signData = JSON.parse(sign.data)
  //   SBTContract?.functions
  //     .mint([signData.account, signData.deadline], sign?.signature)
  //     .then(async (res) => {
  //       const response = await res.wait(1)
  //       console.log('response =', response)
  //       if (response?.status) {
  //         await delay(3000) // set a time buff for contract info update
  //         toastSuccess('Successfully minted SBT!')
  //         console.log('mintResult =', mintResult)
  //         updateMintStatus()
  //       } else {
  //         toastError('Mint failed, please try agin!')
  //       }
  //       setLoading(false)
  //     })
  //     .catch((e) => {
  //       console.error('mint error = ', e)
  //       toastError('Mint failed, please try agin!')
  //       setLoading(false)
  //     })
  // }

  return (
    <StepContainer>
      <div>
        <Text fontSize="32px" bold color="primary">
          Step 1
        </Text>
        <Text color="white" mt="10px">
          Binding your social accounts to claim SBT
        </Text>
        {account ? (
          <>
            <ShareBtn isTwitter={true} hasBind={!!twitter} onClick={() => clickMedia(MediaType.TWITTER)}>
              <Image src={Imgs.twitter} style={{ marginRight: '13px' }} />
              {twitter || 'Verify with Twitter'}
            </ShareBtn>
            <ShareBtn isTwitter={false} hasBind={!!discord} onClick={() => clickMedia(MediaType.DISCORD)}>
              <Image src={Imgs.discord} style={{ marginRight: '13px' }} />
              {discord || 'Verify with Discord'}
            </ShareBtn>
            <InviteItem account={account} connectWallet={connectWallet} presentInvite={presentInvite} />
          </>
        ) : (
          <UnlockButton width={isMobile ? '270px' : '320px'} mt="24px" />
        )}
      </div>
      <ColumnCenter style={{ width: '320px' }}>
        <Text color="white" textAlign="center" mt={isMobile ? '60px' : '0'}>
          Rewards for <span style={{ color: '#FF3093' }}>Step 1</span>
        </Text>
        <StyleBorder>
          <ImgBox src={Imgs.box} />
        </StyleBorder>

        {account ? (
          mintResult ? (
            <Text color="white">You have already claimed Shardex SBT</Text>
          ) : (
            <Flex alignItems="center">
              <Text color="white" mr="5px">
                You are not eligible for Shardex SBT{' '}
              </Text>
              <a href="https://docs.shardex.org/how-to-claim-shardex-airdrops" target="_blank" rel="noreferrer">
                <StyledImgInfo src={require('assets/images/icon/info-white.svg').default} />
              </a>
            </Flex>
          )
        ) : (
          <StyledWaitList>
            <Text fontSize="20px" bold color="primary" mr="4px">
              {/* @ts-ignore */}
              <CountUp start={0} end={totalSupply ?? 0} decimals={0} duration={1} separator="," />
            </Text>
            <Text color="white">in Shardex Waitlist</Text>
          </StyledWaitList>
        )}
      </ColumnCenter>
    </StepContainer>
  )
}

export default StepItem
