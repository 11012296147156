/* eslint-disable react-hooks/exhaustive-deps */
import { Image } from 'components'
import copy from 'copy-to-clipboard'
import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'
import { Modal, Text } from 'uikit'
import { useToast } from '../../../state/hooks'
import { useTranslation } from 'react-i18next'

interface InviteModalProps {
  onDismiss: () => void
  referral?: string
}

const Imgs = {
  header: require('../../../assets/images/referral/planet.webp').default,
  send: require('../../../assets/images/invite/send.svg').default,
  twitter: require('assets/images/referral/twitter.svg').default,
}

const InviteHeaderImg = styled.img`
  width: 182px;
  height: 150px;
  margin: 0 auto;
`

const Button = styled.div<{ isTwitter: boolean; mt?: string }>`
  width: 368px;
  height: 54px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isTwitter }) => (isTwitter ? '#289AFF' : '#FF3093')};
  margin-top: ${({ mt }) => mt ?? '20px'};
  cursor: pointer;
  color: white;
  font-weight: 700;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const TwitterModal: React.FC<InviteModalProps> = ({ onDismiss, referral }) => {
  const { toastSuccess } = useToast()
  const { t } = useTranslation()
  return (
    <Modal showHeader={false} onDismiss={onDismiss} style={{ width: isMobile ? '90%' : '440px' }} bodyPadding="36px">
      <Text fontSize="20px" mt="10px" mb="28px" textAlign="center">
        Refer to Earn
      </Text>
      <InviteHeaderImg src={Imgs.header} />
      <Text mt="20px">
        Refer your friends to claim SBT with this link. Once they bonded their Twitter and Discord and connected their
        wallet, the referral would be confirmed!
      </Text>
      <Text mt="12px">For each confirmed referral, both your friend and you will get Share Points. </Text>
      <Button
        isTwitter={true}
        mt="40px"
        onClick={() => {
          window.open(
            `https://twitter.com/intent/tweet?url=https%3A%2F%2Ftestnet.shardex-interface.pages.dev%2Freward%3Fref%3D${referral}&text=I%20just%20found%20a%20new%20community-driven%20and%20all-in-one%20DEX%20on%20Shardeum%20Blockchain%20@ShardexOrg%20.%20Use%20my%20referral%20link%2C%20and%20we%27ll%20both%20earn%20more%20Shar%20Points%20for%20free%20airdrop%20when%20you%20join%3A%0A`,
            '_blank'
          )
        }}
      >
        <Image src={Imgs.twitter} style={{ marginRight: '13px' }} />
        Share on Twitter
      </Button>
      <Button
        isTwitter={false}
        onClick={() => {
          copy(`${process.env.REACT_APP_REFERRAL_URL}${referral}`)
          toastSuccess(t('REFERRAL_8'))
        }}
      >
        Copy Link
      </Button>
    </Modal>
  )
}

export default TwitterModal
