import BN from 'bignumber.js'
import CurrencyLogo from 'components/CurrencyLogo'
import Row, { RowBetween } from 'components/Row'
import Tooltip from 'components/Tooltip'
import tokens from 'constants/tokens'
import { useGetKcsBalance, useProjectTokenBalance } from 'hooks/useTokenBalance'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ETHER } from 'shardex-sdk'
import styled, { useTheme } from 'styled-components'
import { projectToken } from '../../../constants/migrate'
import Flex from '../../components/Box/Flex'
import LinkExternal from '../../components/Link/LinkExternal'
import Text from '../../components/Text/Text'
import { useModal } from '../Modal'
import AccountModal from './AccountModal'
import { connectorLocalStorageKey } from './config'

interface Props {
  account: string
  logout: () => void
  visible: boolean
  onDismiss?: () => void
}

const Imgs = {
  right: require('assets/images/menu/right.svg').default,
  loginOut: require('assets/images/icon/arrow-right-primary.svg').default,
  eyeOn: require('assets/images/launchpad/eye-on.svg').default,
  eyeClose: require('assets/images/launchpad/eye-close.svg').default,
  red: require('assets/images/home/redPoint.svg').default,
}

const ModalContainer = styled.div`
  width: 256px;
  background: white;
  border-radius: 12px;
  padding: 20px 0px 15px 0;
  position: absolute;
  top: 38px;
  right: 0px;
  z-index: 11;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
`

const WalletContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-top: 4px;
`

const ImgRight = styled.img`
  height: 20px;
  width: 20px;
`

const ImgEye = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
`

const Lines = styled.div`
  background: rgba(115, 126, 141, 0.16);
  height: 1px;
  width: 100%;
  margin: 10px 0;
`

const ImgWarn = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`

const BetaAccountModal: React.FC<Props> = ({ account, logout, visible, onDismiss = () => null }) => {
  const { balance: SDTBalance } = useProjectTokenBalance()
  const { balance: kcsBalance, refresh } = useGetKcsBalance()
  const [onPresentAccountModal] = useModal(<AccountModal account={account || ''} logout={logout} />)
  const theme: any = useTheme()
  // const avatarImage = profile?.nft?.image?.thumbnail || '/images/no-avatar.png'
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()

  const clickListener = (e) => {
    const target = window.event || e
    const parentApp = document.getElementById('accountProfileTip')
    if (parentApp && !parentApp.contains(target?.target as any)) {
      setShow(false)
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', clickListener)
    return () => window.removeEventListener('mousedown', clickListener)
  })

  useEffect(() => {
    refresh()
  }, [visible, refresh])

  const Tips = (
    <div>
      <Text color={theme.colors.textRemark} fontSize="14px">
        {t('UIKIT_15', { symbol: ETHER.symbol })}
      </Text>
      <LinkExternal
        style={{ fontSize: '16px', fontWeight: 500 }}
        small
        href="https://docs.shardex.org/get-start/lets-get-some-usdshm"
        target="_self"
        mr="16px"
      >
        <span style={{ color: '#2BCAA0' }}>{t('UIKIT_5', { symbol: ETHER.symbol })}</span>
      </LinkExternal>
    </div>
  )

  return (
    <ModalContainer id="overlay" style={{ display: visible ? 'block' : 'none' }}>
      <Flex justifyContent="space-between" alignItems="center" px="15px">
        <Row style={{ width: 'auto' }}>
          <Text fontSize="20px">{t('UIKIT_16')}</Text>
        </Row>
        <Row
          style={{ width: 'auto' }}
          onClick={() => {
            onPresentAccountModal()
          }}
        >
          <Text color={theme.colors.textRemark} fontSize="14px">
            {t('UIKIT_20')}
          </Text>
          <ImgRight src={Imgs.right} style={{ height: '16px', width: '16px' }} />
        </Row>
      </Flex>
      <WalletContainer>
        <Lines />
        <RowBetween style={{ padding: '0 15px' }}>
          <Flex alignItems="center">
            <CurrencyLogo currency={ETHER} size="24px" />
            <Text color={theme.colors.textRemark} fontSize="14px" ml="12px">
              {ETHER.symbol}
            </Text>
          </Flex>

          <Row style={{ width: 'auto' }}>
            <Text color={theme.colors.textRemark} fontSize="14px">
              {new BN(kcsBalance.toString()).div(10 ** 18).toFixed(2, 1)}
            </Text>
            {new BN(kcsBalance.toString()).div(10 ** 18).lt(2) && visible && (
              <Tooltip text={Tips} show={show} placement="top">
                <ImgWarn
                  src={require('assets/images/menu/warning.svg').default}
                  style={{ marginLeft: '4px' }}
                  onClick={() => {
                    setShow(!show)
                  }}
                  id="accountProfileTip"
                />
              </Tooltip>
            )}
          </Row>
        </RowBetween>
        <Lines />
        <RowBetween style={{ padding: '0 15px' }}>
          <Flex alignItems="center">
            <CurrencyLogo currency={tokens.sdt} size="24px" />
            <Text color={theme.colors.textRemark} fontSize="14px" ml="12px">
              {projectToken.symbol}
            </Text>
          </Flex>
          <Text color={theme.colors.textRemark} fontSize="14px">
            {new BN(SDTBalance.toString()).div(10 ** 18).toFixed(2, 1)}
          </Text>
        </RowBetween>
        <Lines />
      </WalletContainer>
      <Flex
        justifyContent="center"
        alignItems="center"
        mt="9px"
        onClick={() => {
          logout()
          window.localStorage.removeItem(connectorLocalStorageKey)
          onDismiss()
        }}
        style={{ cursor: 'pointer' }}
      >
        <Text fontSize="14px" mr="6px" color="primary">
          {t('UIKIT_21')}
        </Text>
        <ImgEye src={Imgs.loginOut} />
      </Flex>
    </ModalContainer>
  )
}

export default BetaAccountModal
