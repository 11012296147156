import { PoolIds } from 'constants/types'
import { PublicIfoData } from 'pages/Ifos/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Text } from 'uikit'
import getTimePeriods from 'utils/getTimePeriods'

interface Props {
  publicIfoData: PublicIfoData
  poolId: PoolIds
  isDetail?: boolean
}

const Timer: React.FC<Props> = ({ publicIfoData, poolId, isDetail }) => {
  const { t } = useTranslation()
  const { status, secondsUntilStart, secondsUntilEnd } = publicIfoData[poolId]
  const countdownToUse = status === 'coming_soon' ? secondsUntilStart : secondsUntilEnd
  const timeUntil = getTimePeriods(countdownToUse)
  const suffix = status === 'coming_soon' ? t('Start in') : t('End in')
  const styleTime = { color: '#FF3093', fontSize: '20px', fontWeight: 700, margin: isDetail ? '0 3px' : '0' }
  const isFinished = status === 'finished'
  const isLastMinutes = timeUntil.days === 0 && timeUntil.hours === 0 && timeUntil.minutes === 0
  const fixPlural = (time: number) => {
    if (time > 1) return 's'
    return ' '
  }
  return (
    <Flex justifyContent="center" style={{ width: '100%' }}>
      <Flex
        alignItems="center"
        justifyContent={isDetail ? 'flex-start' : 'space-between'}
        style={{ width: '100%', alignItems: 'center', flexWrap: 'wrap' }}
      >
        <Text small color={isDetail ? '#FFFFFF' : 'textSubtle'} fontSize="14px" mr={isDetail ? '10px' : '0'}>
          {suffix}
          {isDetail ? ':' : ''}
        </Text>
        {isFinished ? (
          <Text small textAlign="right" fontSize="14px" color="#FFFFFF">
            {t('LAUNCHPAD_42')}
          </Text>
        ) : isLastMinutes ? (
          <Text small textAlign="right" fontSize="14px" color="#FFFFFF">
            <span style={styleTime}>0 </span> {t('LAUNCHPAD_43')} <span style={styleTime}> 0 </span> {t('LAUNCHPAD_44')}{' '}
            <span style={styleTime}>1 </span> {t('LAUNCHPAD_45')}
          </Text>
        ) : (
          <Text small textAlign="right" fontSize="14px" color="#FFFFFF">
            <span style={styleTime}>{timeUntil.days ?? '-'} </span> {t('LAUNCHPAD_43')} {fixPlural(timeUntil.days)}{' '}
            <span style={styleTime}>{timeUntil.hours ?? '-'} </span> {t('LAUNCHPAD_44')}
            {fixPlural(timeUntil.hours)} <span style={styleTime}>{timeUntil.minutes ?? '-'} </span> {t('LAUNCHPAD_45')}
            {fixPlural(timeUntil.minutes)}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

export default Timer
