import React from 'react'
import { Svg } from 'uikit/components/Svg'
import { SvgProps } from '../../../components/Svg/types'

const LogoIcon: React.FC<SvgProps & { isHover: boolean }> = (props) => {
  return (
    <Svg width="37" height="37" viewBox="0 0 37 37" fill="none" {...props}>
      <path
        d="M12.9393 0C5.79312 0 0 5.79312 0 12.9393V30.0192C0 33.4493 2.7807 36.23 6.21087 36.23H23.2907C30.4369 36.23 36.23 30.4369 36.23 23.2907C36.23 16.1446 30.4369 10.3514 23.2907 10.3514H12.9393C11.5101 10.3514 10.3514 11.5101 10.3514 12.9393C10.3514 14.3685 11.5101 15.5272 12.9393 15.5272H23.2907C27.5784 15.5272 31.0543 19.003 31.0543 23.2907C31.0543 27.5784 27.5784 31.0543 23.2907 31.0543H7.76358C6.33434 31.0543 5.17572 29.8957 5.17572 28.4665C5.17572 27.0372 6.33434 25.8786 7.76358 25.8786H23.2907C24.72 25.8786 25.8786 24.72 25.8786 23.2907C25.8786 21.8615 24.72 20.7029 23.2907 20.7029H12.9393C8.65159 20.7029 5.17572 17.227 5.17572 12.9393C5.17572 8.65159 8.65159 5.17572 12.9393 5.17572H32.0895C33.5187 5.17572 34.6773 4.0171 34.6773 2.58786C34.6773 1.15862 33.5187 0 32.0895 0H12.9393Z"
        fill={props.isHover ? 'url(#paint0_linear_1188_508)' : 'white'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1188_508"
          x1="2.84664"
          y1="7.81048e-07"
          x2="32.8658"
          y2="36.23"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF3093" />
          <stop offset="1" stopColor="#5218F4" />
        </linearGradient>
      </defs>
    </Svg>
  )
}

export default LogoIcon
