// @ts-nocheck
import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import Meta from 'components/Meta'
import React from 'react'
import CountUp from 'react-countup'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { harvestAllPoolsV2, usePools, usePoolTotalEarns } from 'state/hooks'
import styled from 'styled-components'
import { Text } from 'uikit'
import { useToast } from '../../state/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'
import PoolsV2 from './SyrupV2'

import poolbg from 'assets/images/farm/farm_bg.jpg'

const HarvestButton = styled.div<{ disabled: boolean }>`
  height: 40px;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 11px 32px;
  position: relative;
  z-index: 0;
  cursor: ${({ disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  pointer-events: ${({ disabled }) => {
    if (disabled) {
      return 'none'
    }
    return 'default'
  }};

  &::before {
    background-image: ${({ disabled }) => {
      if (disabled) {
        return '#EFEFF2'
      }
      return 'linear-gradient(271.45deg, #ff3093 3.84%, #5218f4 94.54%)'
    }};
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  &:hover:before {
    opacity: 1;
  }
`

const Header = styled.div`
  background: url(${poolbg}) top center no-repeat;
  background-size: auto 100%;
  background-position: -800px 0;
  height: auto;
  color: #fff;
  padding-bottom: 60px;

  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
    padding-bottom: 0px;
    background-position: 0 0;
    background-size: 100% 100%;
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  height: 100%;

  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;

  ${({ theme }) => theme.mediaQueries.md} {
    background: none;
    height: 300px;
    padding-top: 76px;
    padding-left: 40px;
    padding-right: 36px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 24px;
  }
  @media (max-width: 768px) {
    padding-bottom: 100px;
  }
`

const PoolWrap = styled.div`
  background: #000;
  min-height: 100vh;
`

export const FilterSizeContainer = styled(Container)`
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const ProfitPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 32px;
  width: 360px;
  height: 180px;
  position: relative;
  z-index: 1;
  align-items: flex-start;
  margin-top: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 100%);
  border-radius: 16px;
  @media (max-width: 768px) {
    align-items: center;
    margin-top: 40px;
    width: 96%;
    max-width: 360px;
  }
`

const Index: React.FC = () => {
  const { t } = useTranslation()
  const { account, library } = useWeb3React()
  const { poolsV2 } = usePools(account)

  const allEarnings = usePoolTotalEarns()

  const { toastInfo } = useToast()

  const transactionAdder = useTransactionAdder()

  const harvestAll = () => {
    const promises: any[] = []
    if (new BN(poolsV2[0]?.userData?.pendingReward ?? 0).div(new BN(10).pow(18)).gte(0.0001)) {
      promises.push(harvestAllPoolsV2(account, library, transactionAdder))
    }
    if (promises.length) {
      Promise.all(promises).then(() => {})
    } else {
      toastInfo(t('POOL_59'), t('POOL_58'))
    }
  }

  return (
    <PoolWrap>
      <Meta title="Wine Pools | Shardex" />
      <Header>
        <HeaderContainer style={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
          <div style={{ position: 'relative', zIndex: 1, marginTop: isMobile ? '100px' : '40px' }}>
            <Text
              color="#fff"
              fontSize={isMobile ? '36px' : '40px'}
              fontWeight={700}
              style={{ letterSpacing: '4px', textAlign: isMobile ? 'center' : 'left' }}
            >
              {t('Pools')}
            </Text>
            <Text
              color="#fff"
              fontSize="14px"
              fontWeight={500}
              style={{
                width: isMobile ? 'auto' : '590px',
                textAlign: isMobile ? 'center' : 'left',
                letterSpacing: isMobile ? '2px' : '0px',
                marginTop: isMobile ? '8px' : '0',
              }}
            >
              Just stake some tokens to earn. Enjoy it!
            </Text>
          </div>
          <ProfitPanel>
            <Text
              fontSize="18px"
              fontWeight={600}
              color="#fff"
              fontFamily="Poppins"
              style={{ marginTop: '8px' }}
              lineHeight="21px"
            >
              {t('POOL_62')}
            </Text>
            <Text
              fontSize="32px"
              style={{ marginTop: '8px' }}
              fontWeight={600}
              color="#fff"
              fontFamily="Poppins"
              lineHeight="38px"
            >
              ${' '}
              <CountUp
                duration={1}
                start={0}
                decimals={2}
                formattingFn={(n) => {
                  return new BN(n).toFormat({ groupSeparator: ',', groupSize: 3, decimalSeparator: '.' })
                }}
                end={new BN(new BN(allEarnings).gt(0) ? allEarnings : 0).toNumber()}
              />
            </Text>
            <HarvestButton disabled={!account || !allEarnings} onClick={harvestAll}>
              <Text fontSize="16px" fontWeight={500} fontFamily="Poppins" color="#fff">
                {t('POOL_63')}
              </Text>
            </HarvestButton>
          </ProfitPanel>
        </HeaderContainer>
      </Header>
      {/* <InfoContainer>
        <ImgInfo src={require('assets/images/farm/warning.svg').default} />
        <Text fontSize="14px" lineHeight="22px">
          {t('POOL_64')} <InfoAnchor>{t('POOL_65')}</InfoAnchor> {t('POOL_66')}
        </Text>
      </InfoContainer> */}
      <Container>
        <PoolsV2 />
      </Container>
    </PoolWrap>
  )
}

export default Index
