import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import FlexLayout from 'components/layout/Flex'
import Row from 'components/Row'
import useRefresh from 'hooks/useRefresh'
import React, { useCallback, useEffect, useState } from 'react'
import { Route, useLocation, useRouteMatch } from 'react-router-dom'
import { useAppDispatch } from 'state'
import { useFarmsV2, useGetApiPrices } from 'state/hooks'
import styled from 'styled-components'
import { RowType, Text, Toggle } from '../../uikit'

import usePersistState from 'hooks/usePersistState'
import { orderBy } from 'lodash'
import { fetchFarmV2UserDataAsync } from 'state/actions'
import { getBalanceNumber } from 'utils/formatBalance'
import { Farm, FarmV2 } from '../../state/types'

import tokens from 'constants/tokens'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { getAddress } from 'utils/addressHelpers'
import { useBlockNumber } from '../../state/application/hooks'
import FarmCard, { FarmWithStakedValue } from './components/FarmCard/FarmCard'
import FarmTabButtons from './components/FarmTabButtons'
import Table, { IFarmType } from './components/FarmTable/FarmTable'
import { RowProps } from './components/FarmTable/Row'
import SearchInput from './components/SearchInput'
import Select, { OptionProps } from './components/Select/Select'
import ToggleView from './components/ToggleView/ToggleView'
import { DesktopColumnSchemaV2, ViewMode } from './components/types'
import { projectToken } from '../../constants/migrate'

const FarmContent = styled.div`
  padding: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 24px;
  padding-bottom: 100px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 44px;
    justify-content: flex-start;
  }
`

const ControlContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  padding: 10px 20px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px 16px 0 20px;
    height: 60px;
    max-width: 1200px;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 24px;

  ${Text} {
    margin-left: 8px;
  }
`

const LabelWrapper = styled.div`
  > ${Text} {
    font-size: 12px;
  }
`

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;

  ${({ theme }) => theme.mediaQueries.sm} {
    width: auto;
    padding: 0;
  }
`

const ViewControls = styled.div`
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 100%;
  > div {
    padding: 8px 0px;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
    width: auto;
    > div {
      padding: 0;
    }
  }
`

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  ${({ theme }) => theme.mediaQueries.md} {
    margin-bottom: 100px;
  }
`

export const FilterSizeContainer = styled(Container)`
  margin-top: 10px;
  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const FarmsV2: React.FC = () => {
  const localSortStyle = localStorage.getItem('FARM_SORT_STYLE')
  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const farmsLP = useFarmsV2()
  const [query, setQuery] = useState('')
  const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, 'shardex_farm_view')
  const { account } = useWeb3React()
  const [sortOption, setSortOption] = useState(localSortStyle ?? 'hot')
  const prices = useGetApiPrices()
  const { slowRefresh } = useRefresh()

  const dispatch = useAppDispatch()

  const scrollToAnchor = (anchorId) => {
    if (anchorId) {
      const anchorElement = document.getElementById(anchorId)
      if (anchorElement) {
        anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
      }
    }
  }

  useEffect(() => {
    if (account) {
      dispatch(fetchFarmV2UserDataAsync(account))
    }
  }, [account, dispatch, slowRefresh])
  const { pathname, hash } = useLocation()
  useEffect(() => {
    if (hash) {
      scrollToAnchor(hash.slice(1))
    }
  })

  const [stakedOnly, setStakedOnly] = usePersistState(false, 'mojito_farm_staked')
  const isActive = !pathname.includes('historyV2')
  const activeFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier !== '0X')
  const inactiveFarms = farmsLP.filter((farm) => farm.pid !== 0 && farm.multiplier === '0X')

  const stakedOnlyFarms = activeFarms.filter(
    (farm) => farm.userData && new BN(farm.userData.stakedBalance).isGreaterThan(0)
  )

  const stakedInactiveFarms = inactiveFarms.filter(
    (farm) => farm.userData && new BN(farm.userData.stakedBalance).isGreaterThan(0)
  )

  const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
    const orderType = localSortStyle ?? sortOption
    switch (orderType) {
      case 'apr':
        return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr, 'desc')
      case 'multiplier':
        return orderBy(
          farms,
          (farm: FarmWithStakedValue) => (farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0),
          'desc'
        )
      case 'earned':
        return orderBy(
          farms,
          (farm: FarmWithStakedValue) => (farm.userData ? getBalanceNumber(new BN(farm.userData.earnings)) : 0),
          'desc'
        )
      case 'liquidity':
        return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
      default:
        return farms
    }
  }

  const currentBlockNumber = useBlockNumber()

  const historyfarmsList = useCallback(
    (farmsToDisplay: FarmV2[]): FarmWithStakedValue[] => {
      const finishedFarms = farmsToDisplay.filter((farm) => {
        return farm?.dual?.endBlock && Number(currentBlockNumber) > Number(farm?.dual.endBlock)
      })

      if (query) {
        const lowercaseQuery = query.toLowerCase()
        const farmsToDisplayWithAPR = finishedFarms.filter((farm: FarmWithStakedValue) => {
          return farm.lpSymbol.toLowerCase().includes(lowercaseQuery)
        })
        return farmsToDisplayWithAPR
      }

      return finishedFarms
    },
    [query, currentBlockNumber]
  )

  const farmsList = useCallback(
    (farmsToDisplay: Farm[]): any[] => {
      const notFinishedFarm = farmsToDisplay.filter((farm) => {
        return !farm?.dual?.endBlock || currentBlockNumber < farm?.dual.endBlock
      })

      if (query) {
        const lowercaseQuery = query.toLowerCase()
        const farmsToDisplayWithAPR = notFinishedFarm.filter((farm: FarmWithStakedValue) => {
          return farm.lpSymbol.toLowerCase().includes(lowercaseQuery)
        })
        return farmsToDisplayWithAPR
      }
      return notFinishedFarm
    },
    [query, currentBlockNumber]
  )

  const handleChangeQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value)
  }

  let farmsStaked: any = []
  if (isActive) {
    farmsStaked = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
  } else {
    farmsStaked = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
  }

  farmsStaked = sortFarms(farmsStaked)

  let historyFarms: any[] = stakedOnly ? historyfarmsList(stakedInactiveFarms) : historyfarmsList(inactiveFarms)

  historyFarms = sortFarms(historyFarms)

  const rowData = farmsStaked.map((farm) => {
    const { token, quoteToken } = farm
    const tokenAddress = token?.address
    const quoteTokenAddress = quoteToken?.address
    const lpLabel = farm.lpSymbol && farm.lpSymbol.split(' ')[0].toUpperCase().replace('MOJITO', '')

    const row: RowProps = {
      apr: {
        value: farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 }),
        multiplier: farm.multiplier,
        lpLabel,
        tokenAddress,
        quoteTokenAddress,
        projectTokenPrice: new BN(prices[getAddress(tokens.sdt.address).toLowerCase()]),
        originalValue: farm.apr,
      },
      farm: {
        image: farm.lpSymbol.split(' ')[0].toLocaleLowerCase(),
        label: lpLabel,
        pid: farm.pid,
      },
      earned: {
        earnings: (farm.userData
          ? `${getBalanceNumber(new BN(farm.userData.earnings)).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })} ${projectToken.symbol}`
          : null) as any,
        pid: farm?.pid,
      },
      rewarded: {
        earnings: (farm.userData
          ? `${getBalanceNumber(new BN(farm.userData?.doubleDigEarnings)).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })} ${farm?.doubleDigToken?.symbol}`
          : null) as any,
        pid: farm?.pid,
      },
      liquidity: {
        liquidity: farm?.liquidity,
      },
      multiplier: {
        multiplier: farm.multiplier,
      },
      details: farm,
    }

    return row
  })

  const renderContent = (): JSX.Element => {
    if (viewMode === ViewMode.TABLE && rowData.length) {
      const columnSchema = DesktopColumnSchemaV2

      const columns = columnSchema.map((column) => ({
        id: column.id,
        name: column.name,
        label: column.label,
        sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
          switch (column.name) {
            case 'farm':
              return b.id - a.id
            case 'apr':
              if (a.original.apr.value && b.original.apr.value) {
                return Number(a.original.apr.value) - Number(b.original.apr.value)
              }

              return 0
            case 'earned':
              return new BN(a.original.earned.earnings).minus(b.original.earned.earnings).toNumber()
            default:
              return 1
          }
        },
        sortable: column.sortable,
      }))

      return <Table data={rowData} columns={columns} isMobile={isMobile} type={IFarmType.V2} />
    }

    return (
      <Container>
        <Route path={`${path}`}>
          <FlexLayout style={{ marginBottom: '0px' }}>
            {farmsStaked.map((farm) => (
              <FarmCard
                key={farm.pid}
                farm={farm}
                projectTokenPrice={new BN(prices[getAddress(tokens.sdt.address).toLowerCase()])}
                account={account as string}
                removed={false}
              />
            ))}
          </FlexLayout>
        </Route>
        <Route exact path={`${path}/historyV2`}>
          <FlexLayout>
            {historyFarms.map((farm) => (
              <FarmCard
                key={farm.pid}
                farm={farm}
                projectTokenPrice={new BN(prices[getAddress(tokens.sdt.address).toLowerCase()])}
                account={account as string}
                removed
              />
            ))}
          </FlexLayout>
        </Route>
      </Container>
    )
  }

  const handleSortOptionChange = (option: OptionProps): void => {
    setSortOption(option.value)
    localStorage.setItem('FARM_SORT_STYLE', option.value)
  }

  return (
    <>
      {/* <Container>
        <Text fontSize="28px" bold mt="48px" mb="20px" style={{ marginLeft: isMobile ? '12px' : '0' }}>
          MojitoSwap BAR V2
        </Text>
      </Container> */}
      <ControlContainer>
        <FilterSizeContainer>
          <ViewControls>
            <ToggleView viewMode={viewMode} onToggle={(mode: ViewMode) => setViewMode(mode)} />
            <FarmTabButtons version={2} />
            {isMobile ? null : (
              <ToggleWrapper>
                <Toggle
                  checked={stakedOnly}
                  onChange={() => setStakedOnly(!stakedOnly)}
                  scale="sm"
                  style={{ width: '48px', height: '28px' }}
                />
                <Text fontSize="14px" color="#B4B7C1" fontWeight={500} fontFamily="Poppins">
                  {t('FARM_9')}
                </Text>
              </ToggleWrapper>
            )}
          </ViewControls>
          {isMobile && (
            <Row>
              <ToggleWrapper style={{ marginLeft: 0 }}>
                <Toggle
                  checked={stakedOnly}
                  onChange={() => setStakedOnly(!stakedOnly)}
                  scale="sm"
                  style={{ width: '48px', height: '28px' }}
                />
                <Text fontSize="14px" color="#fff" fontWeight={500} fontFamily="Poppins">
                  {t('FARM_9')}
                </Text>
              </ToggleWrapper>
            </Row>
          )}
          <FilterContainer>
            <LabelWrapper>
              <Select
                styles={{
                  color: '#fff',
                  background: '#181818',
                  border: 'none',
                }}
                options={[
                  { label: t('FARM_78'), value: t('FARM_79') },
                  {
                    label: t('FARM_80'),
                    value: t('FARM_81'),
                  },
                  {
                    label: t('FARM_82'),
                    value: t('FARM_83'),
                  },
                  {
                    label: t('FARM_84'),
                    value: t('FARM_85'),
                  },
                  {
                    label: t('FARM_86'),
                    value: t('FARM_87'),
                  },
                ]}
                onChange={handleSortOptionChange}
              />
            </LabelWrapper>
            <LabelWrapper style={{ marginLeft: 12 }}>
              <SearchInput
                onChange={handleChangeQuery}
                styles={{ height: '40px', borderRadius: '20px', background: '#181818', border: 'none' }}
              />
            </LabelWrapper>
          </FilterContainer>
        </FilterSizeContainer>
      </ControlContainer>
      <FarmContent>{renderContent()}</FarmContent>
    </>
  )
}

export default FarmsV2
