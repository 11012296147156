import React from 'react'
import styled from 'styled-components'
import { ChevronDownIcon, ChevronUpIcon, Text } from 'uikit'
import { useTranslation } from 'react-i18next'

export interface ExpandableSectionButtonProps {
  onClick: () => void
  expanded?: boolean
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    fill: ${({ theme }) => theme.colors.primary};
  }
`

const ExpandableSectionButton: React.FC<ExpandableSectionButtonProps> = ({ onClick, expanded }) => {
  const { t } = useTranslation()

  return (
    <Wrapper aria-label={t('COMPONENTS_186')} role="button" onClick={() => onClick()} style={{ padding: '20px' }}>
      <Text color="#fff">{expanded ? t('COMPONENTS_34') : t('COMPONENTS_35')}</Text>
      {expanded ? <ChevronUpIcon color="#fff" /> : <ChevronDownIcon color="#fff" />}
    </Wrapper>
  )
}

ExpandableSectionButton.defaultProps = {
  expanded: false,
}

export default ExpandableSectionButton
