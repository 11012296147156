import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Text } from '../../uikit'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface OwnProps {
  TranslateString: any
}

type Props = OwnProps

const NoLiquidityWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`

const SwapIcon = styled.img`
  height: 64px;
  width: 64px;
`
const TextWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
`

const NoLiquidity: FunctionComponent<Props> = () => {
  const { t } = useTranslation()
  return (
    <NoLiquidityWrap>
      <SwapIcon src={require('../../assets/images/cup.svg').default} />
      <TextWrap>
        <Text color="textRemark" textAlign="center" style={{ marginTop: '8px' }}>
          {t('POOL_10')}
        </Text>
      </TextWrap>
    </NoLiquidityWrap>
  )
}

export default withRouter<any, any>(NoLiquidity)
