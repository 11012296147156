import BigNumber from 'bignumber.js'
import erc20ABI from 'constants/abis/erc20.json'
import masterChefV2ABI from 'constants/abis/masterchefV2.json'
import sousChefABI from 'constants/abis/sousChef.json'
import vaultABI from 'constants/abis/vault.json'
import { poolsV2 } from 'constants/pools'
import { PoolCategory } from 'constants/types'
import { ETHER } from 'shardex-sdk'
import { getAddress, getMasterChefV2Address } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { getWeb3NoAccount } from 'utils/web3'
import { AbiItem } from 'web3-utils'
import { getVaultV2Address } from '../../utils/addressHelpers'

// version 2 pools info
const manulPoolsV2 = poolsV2.filter(
  (p) => p.stakingToken.symbol !== ETHER.name && p.poolCategory === PoolCategory.Manual
)

const SHMPoolsV2 = poolsV2.filter((p) => p.stakingToken.symbol === ETHER.name)

const autoPoolsV2 = poolsV2.filter((p) => p.poolCategory === PoolCategory.Auto)

const nonMasterAndVaultPoolsV2 = poolsV2.filter((p) => p.sousId !== 0)

const web3 = getWeb3NoAccount()

const masterChefV2Contract = new web3.eth.Contract(masterChefV2ABI as unknown as AbiItem, getMasterChefV2Address())

export const fetchPoolsAllowance = async (account) => {
  const poolInfo = poolsV2
  const calls = poolInfo.map((p: any) => ({
    address: getAddress(p.stakingToken.address),
    name: 'allowance',
    params: [account, getAddress(p.contractAddress)],
  }))

  const allowances = await multicall(erc20ABI, calls)

  return poolInfo.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sortOrder]: new BigNumber(allowances[index]).toJSON() }),
    {}
  )
}

// CHECKED
export const fetchUserBalances = async (account) => {
  // Non SHM pools
  const poolInfo = poolsV2
  const calls = poolInfo.map((p: any) => ({
    address: getAddress(p.stakingToken.address),
    name: 'balanceOf',
    params: [account],
  }))

  const tokenBalancesRaw = await multicall(erc20ABI, calls)

  const tokenBalances = poolInfo.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sortOrder]: new BigNumber(tokenBalancesRaw[index]).toString(10) }),
    {}
  )

  // SHM pools
  const SHMPoolInfo = SHMPoolsV2
  const SHMBalance = await web3.eth.getBalance(account)
  const SHMBalances = SHMPoolInfo.reduce(
    (acc, pool) => ({ ...acc, [pool.sortOrder]: new BigNumber(SHMBalance).toString(10) }),
    {}
  )

  return { ...tokenBalances, ...SHMBalances }
}

// CHECKED
export const fetchUserStakeBalances = async (account) => {
  const poolInfo = manulPoolsV2
  const calls = poolInfo.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [0, account],
  }))

  const manulUserInfo = await multicall(masterChefV2ABI, calls)

  // console.log('manulUserInfo', manulUserInfo)

  const stakedBalances = poolInfo.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sortOrder]: {
        userStakedBalance: new BigNumber(manulUserInfo[index].amount._hex).toJSON(),
        userSelfShare: '0',
        lastDepositedTime: 0,
      },
    }),
    {}
  )

  // get auto pool stake
  const autoPoolInfo = autoPoolsV2
  const calls1 = [
    {
      address: getAddress(autoPoolInfo[0].contractAddress),
      name: 'userInfo',
      params: [account],
    },
    {
      address: getAddress(autoPoolInfo[0].contractAddress),
      name: 'totalShares',
    },
    {
      address: getAddress(autoPoolInfo[0].contractAddress),
      name: 'balanceOf',
    },
  ]

  const autoUserInfo = await multicall(vaultABI, calls1) // get shares

  const selfShare = new BigNumber(autoUserInfo[0].shares._hex).toString(10)
  const totalShare = new BigNumber(autoUserInfo[1]).toString(10)
  const totalBalance = new BigNumber(autoUserInfo[2]).toString(10)
  const lastDepositedTime = `${new BigNumber(autoUserInfo[0].lastDepositedTime._hex).toString(10)}000`
  const balance =
    totalShare === '0' || selfShare === '0'
      ? 0
      : new BigNumber(selfShare).div(totalShare).multipliedBy(totalBalance).toString(10)

  const stakedBalances1 = autoPoolInfo.reduce(
    (acc, pool) => ({
      ...acc,
      [pool.sortOrder]: {
        userStakedBalance: balance ?? 0,
        userSelfShare: selfShare ?? 0,
        lastDepositedTime,
      },
    }),
    {}
  )

  // get user sousChef staked info
  const nonMasterAndVaultPoolsInfo = nonMasterAndVaultPoolsV2
  const calls2 = nonMasterAndVaultPoolsInfo.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'userInfo',
    params: [account],
  }))
  const userInfo = await multicall(sousChefABI, calls2)
  const stakedBalances2 = nonMasterAndVaultPoolsInfo.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sortOrder]: {
        userStakedBalance: new BigNumber(userInfo[index].amount._hex).toJSON(),
        userSelfShare: '0',
        lastDepositedTime: 0,
      },
    }),
    {}
  )
  return { ...stakedBalances, ...stakedBalances1, ...stakedBalances2 }
}

export const getVaultPendingRewards = async (account) => {
  try {
    const contractAddress = getVaultV2Address()
    const calls = [
      {
        address: contractAddress,
        name: 'totalShares',
      },
      {
        address: contractAddress,
        name: 'userInfo',
        params: [account],
      },
      {
        address: contractAddress,
        name: 'calculateTotalPendingShardexRewards',
      },
    ]

    const res = await multicall(vaultABI, calls)

    const totalShares = res[0].toString(10)

    const myShares = res[1].shares.toString(10)

    const allPendingSDT = res[2].toString(10)

    const r = new BigNumber(allPendingSDT).multipliedBy(new BigNumber(myShares).div(totalShares)).toString(10)
    return r
  } catch (e) {
    console.log(e)
    return 1
  }
}

export const fetchUserPendingRewards = async (account) => {
  const vaultPendingSDT = await getVaultPendingRewards(account)

  const nonMasterAndVaultPoolsInfo = nonMasterAndVaultPoolsV2

  const calls = nonMasterAndVaultPoolsInfo.map((p) => ({
    address: getAddress(p.contractAddress),
    name: 'pendingReward',
    params: [account],
  }))

  const res = await multicall(sousChefABI, calls)

  const pendingRewards = nonMasterAndVaultPoolsInfo.reduce(
    (acc, pool, index) => ({
      ...acc,
      [pool.sousId]: new BigNumber(res[index]).toJSON(),
    }),
    {}
  )
  // SDT POOL
  const pendingReward = await masterChefV2Contract.methods.pendingShardex('0', account).call()

  const response = { 0: new BigNumber(pendingReward).toJSON(), 1: vaultPendingSDT ?? 0, ...pendingRewards }

  return response
}
