import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ResetCSS } from './uikit'
import GlobalStyle from './style/Global'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
// import StatisticsUpdater from './state/statistics/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import ToastListener from './components/ToastListener'

import Providers from './Providers'
import 'inter-ui'
import './i18n'

import Preload from 'Preload'
import App from 'pages/App'

if (window?.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

if (!('process' in window)) {
  // @ts-ignore
  window.process = {}
}

// if (process.env.REACT_APP_ENV_NAME !== 'production-mainnet') {
//   const vConsole = new VConsole({ maxLogNumber: 1000 })
// }

window.addEventListener('error', () => {
  localStorage?.removeItem('redux_localstorage_simple_lists')
})

ReactDOM.render(
  <StrictMode>
    <Providers>
      <>
        <Preload />
        <ListsUpdater />
        {/* <StatisticsUpdater /> */}
        <ApplicationUpdater />
        <TransactionUpdater />
        <MulticallUpdater />
        <ToastListener />
      </>
      <ResetCSS />
      <GlobalStyle />
      <App />
    </Providers>
  </StrictMode>,
  document.getElementById('root')
)
