import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <rect x="1" y="7" width="13" height="1.5" fill={props.color ?? 'white'} />
      <path
        d="M10.9498 4.99943L13.8996 7.94922L10.9498 10.899"
        fill="none"
        stroke={props.color ?? 'white'}
        strokeWidth="1.5"
      />
    </Svg>
  )
}

export default Icon
