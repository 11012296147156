import React from 'react'
import styled from 'styled-components'

const LogoWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: auto;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  font-size: 24px;
  font-weight: bold;
`

const Logo: React.FC<any> = () => {
  return (
    <LogoWrapper>
      Shardex
      {/* <LogoIcon src={require('../../../../assets/images/logo-with-text.svg').default} /> */}
      {/* <LogoText src={require('../../../../assets/images/logo.svg').default} /> */}
    </LogoWrapper>
  )
}

export default React.memo(Logo)
