import useRefresh from 'hooks/useRefresh'
import React from 'react'
import { CurrencyAmount, Fraction, JSBI, Pair, PAIR_SWAP_FEE, Percent, TokenAmount, Trade } from 'shardex-sdk'
import store, { useAppDispatch } from 'state'
import { updatePriceList } from 'state/prices'
import {
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_LOW,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
} from '../constants'
import { useAllTokens } from '../hooks/Tokens'
import { getTokenPrice } from '../hooks/useTokenPrice'
import { Field } from '../state/swap/actions'
import { basisPointsToPercent } from './index'

const BASE_FEE = new Percent(JSBI.BigInt(30), JSBI.BigInt(10000))
const ONE_HUNDRED_PERCENT = new Percent(JSBI.BigInt(10000), JSBI.BigInt(10000))

export function getPairFee(pair: Pair) {
  const lpAddress = Pair.getAddress(pair.token0, pair.token1, pair.token0.chainId)
  // console.log('lpAddress', lpAddress)

  if (lpAddress in PAIR_SWAP_FEE[pair.token0.chainId]) {
    return new Percent(JSBI.BigInt(PAIR_SWAP_FEE[pair.token0.chainId][lpAddress]), JSBI.BigInt(10000))
  }
  return BASE_FEE
}

// computes price breakdown for the trade
export function computeTradePriceBreakdown(trade?: Trade): {
  priceImpactWithoutFee?: Percent
  realizedLPFee?: CurrencyAmount
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.2% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .02) * (1-.02))

  const realizedLPFee = !trade
    ? undefined
    : ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>((currentFee: Fraction, pair): Fraction => {
          const SWAP_FEE = getPairFee(pair)
          const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(SWAP_FEE)
          return currentFee.multiply(INPUT_FRACTION_AFTER_FEE)
        }, ONE_HUNDRED_PERCENT)
      )

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction = trade && realizedLPFee ? trade.priceImpact.subtract(realizedLPFee) : undefined

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(priceImpactWithoutFeeFraction?.numerator, priceImpactWithoutFeeFraction?.denominator)
    : undefined

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    (trade.inputAmount instanceof TokenAmount
      ? new TokenAmount(trade.inputAmount.token, realizedLPFee.multiply(trade.inputAmount.raw).quotient)
      : CurrencyAmount.ether(realizedLPFee.multiply(trade.inputAmount.raw).quotient))

  return { priceImpactWithoutFee: priceImpactWithoutFeePercent, realizedLPFee: realizedLPFeeAmount }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade | undefined,
  allowedSlippage: number
): { [field in Field]?: CurrencyAmount } {
  const pct = basisPointsToPercent(allowedSlippage)
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  }
}

export function warningSeverity(priceImpact: Percent | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function formatExecutionPrice(trade?: Trade, inverted?: boolean): string {
  if (!trade) {
    return ''
  }
  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${trade.inputAmount.currency.symbol} / ${
        trade.outputAmount.currency.symbol
      }`
    : `${trade.executionPrice.toSignificant(6)} ${trade.outputAmount.currency.symbol} / ${
        trade.inputAmount.currency.symbol
      }`
}

/**
 * Helper to multiply a Price object by an arbitrary amount
 */
export const multiplyPriceByAmount = (price: any, amount: number) => {
  if (!price) {
    return 0
  }

  return Number(price) * amount
}

export const useFetchPriceList = () => {
  const dispatch = useAppDispatch()

  const allTokens = useAllTokens()

  const { priceReresh } = useRefresh()

  const keys: any[] = Reflect.ownKeys(allTokens)

  async function getPricesList() {
    const updated_at = store.getState().prices.lastUpdated

    if (updated_at === '') {
      let tempData = {}
      for (let i = 0; i < keys.length; i++) {
        const token = allTokens[keys[i]]
        const priceItem = {
          [token.address.toLowerCase()]: '0',
        }
        tempData = { ...tempData, ...priceItem }
      }
      const p = {
        updated_at: '0', // init updated time
        data: {
          ...tempData,
        },
      }
      dispatch(updatePriceList(p))
    } else {
      const updated_at1 = store.getState().prices.lastUpdated

      const now = new Date().getTime()

      if (now - Number(updated_at1) > 1000 * 60 * 2) {
        console.log('prics updated_at1', updated_at1)

        const promises = keys.map((key) => {
          return getTokenPrice(allTokens[key].address.toLocaleLowerCase())
        })

        const priceData = await Promise.all(promises)

        let tempData = {}

        for (let i = 0; i < priceData.length; i++) {
          const token = allTokens[keys[i]]
          const priceItem = {
            [token.address.toLowerCase()]: String(Number(priceData[i])) ?? '0',
          }
          tempData = { ...tempData, ...priceItem }
        }

        const p1 = {
          updated_at: new Date().getTime().toString(),
          data: {
            ...tempData,
          },
        }
        dispatch(updatePriceList(p1))
      }
    }
  }

  React.useEffect(() => {
    getPricesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTokens, dispatch, priceReresh])
}
