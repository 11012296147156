import React from 'react'
import styled from 'styled-components'
import { Flex } from '../../uikit'
import { useHistory } from 'react-router-dom'

const StyledSelectTab = styled(Flex)`
  height: 54px;
  flex: 1;
`

const Details = styled(Flex)<{ sec: boolean }>`
  width: 50%;
  background: ${({ sec }) => (sec ? 'white' : '#EFEFF2')};
  color: ${({ sec, theme }) => (sec ? theme.colors.text : theme.colors.textRemark)};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SelectTab = () => {
  const history = useHistory()

  const isSwapPage = React.useMemo(() => {
    return history.location.pathname.includes('swap') || history.location.pathname === '/'
  }, [history])

  const isPoolPage = React.useMemo(() => {
    return (
      history.location.pathname.includes('pool') ||
      history.location.pathname.includes('add') ||
      history.location.pathname.includes('find') ||
      history.location.pathname.includes('remove')
    )
  }, [history])

  const clickTab = (type: string) => {
    if (type === 'swap' && !isSwapPage) {
      history.push('/swap')
    }
    if (type === 'pool' && !isPoolPage) {
      history.push('/pool')
    }
  }

  return (
    <StyledSelectTab>
      <Details sec={isSwapPage} onClick={() => clickTab('swap')}>
        Swap
      </Details>
      <Details sec={isPoolPage} onClick={() => clickTab('pool')}>
        Liquidity
      </Details>
    </StyledSelectTab>
  )
}

export default SelectTab
