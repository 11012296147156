import { useWeb3React } from '@web3-react/core'
import UnlockButton from 'components/UnlockButton'
import { useApprove } from 'hooks/useApprove'
import { FarmWithStakedValue } from 'pages/Farms/components/FarmCard/FarmCard'
import React, { useCallback, useState } from 'react'
import { useFarmUser } from 'state/hooks'
import styled from 'styled-components'
import { AddIcon, Button, IconButton, MinusIcon, Text, useModal } from 'uikit'
import { getKrc20Contract } from 'utils/contractHelpers'

import BN from 'bignumber.js'
import QuestionHelper from 'components/QuestionHelper'
import { MouseoverTooltip } from 'components/Tooltip'
import { BASE_ADD_LIQUIDITY_URL } from 'constants/index'
import useStake from 'hooks/useStake'
import useUnstake from 'hooks/useUnstake'
import useWeb3 from 'hooks/useWeb3'
import { useTranslation } from 'react-i18next'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import useLpUSD from 'utils/getLpUSD'
import { useFarmFromPid } from '../../../../../state/hooks'
import DepositModal from '../../DepositModal'
import WithdrawModal from '../../WithdrawModal'
import { ActionContainer, ActionContent, ActionTitles, Earned, Subtle, Title } from './styles'
import useTheme from 'hooks/useTheme'

const IconButtonWrapper = styled.div`
  display: flex;
`
const TableFarmConfirmButton = styled(Button)<{ disabled?: boolean }>`
  border-radius: 30px;
  background: ${({ disabled, theme }) => {
    if (disabled) {
      return '#B4B7C1'
    }
    return theme.colors.primary
  }};
  color: #fff;
  border: none;
  height: 42px;
  &:hover {
    opacity: 0.7;
  }
`

const UnlockWalletButtonInFarm = styled(UnlockButton)`
  color: #fff;
  border: none;
  border-radius: 30px;
  margin-top: 5px;
`

const Staked: React.FunctionComponent<FarmWithStakedValue> = ({
  pid,
  lpSymbol,
  lpAddresses,
  quoteToken,
  token,
  version,
}) => {
  const { theme } = useTheme()
  const { account } = useWeb3React()
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { allowance, tokenBalance, stakedBalance } = useFarmUser(pid, version)
  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  const web3 = useWeb3()
  const { t } = useTranslation()
  const farm = useFarmFromPid(pid, version)

  const { totalUSD, tokenAmount, tokenPriceUsd, quoteTokenAmount, quoteTokenPriceUsd } = useLpUSD(pid, version ?? 1)

  const isApproved = account && allowance && allowance.isGreaterThan(0)

  const lpAddress = lpAddresses[process.env.REACT_APP_CHAIN_ID as string]
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: quoteToken.address,
    tokenAddress: token.address,
  })
  const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`

  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance)
    if (stakedBalanceNumber > 0 && stakedBalanceNumber < 0.000001) {
      return getFullDisplayBalance(stakedBalance).toLocaleString()
    }
    return stakedBalanceNumber.toLocaleString()
  }, [stakedBalance])

  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={lpSymbol} addLiquidityUrl={addLiquidityUrl} />
  )
  const [onPresentWithdraw] = useModal(<WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={lpSymbol} />)

  const lpContract = getKrc20Contract(lpAddress, web3)

  const { onApprove } = useApprove(lpContract)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApprove])

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Subtle>{t('FARM_49')}</Subtle>
        </ActionTitles>
        <ActionContent>
          <UnlockWalletButtonInFarm width="100%" />
        </ActionContent>
      </ActionContainer>
    )
  }

  if (isApproved) {
    if (stakedBalance.gt(0)) {
      return (
        <ActionContainer>
          <ActionTitles>
            <Title>{lpSymbol} </Title>
            <Subtle>{t('FARM_50')}</Subtle>
          </ActionTitles>
          <ActionContent>
            <div>
              <Earned>{displayBalance()}</Earned>
              <Text fontSize="14px" color="textSubtle" style={{ marginTop: '-6px', lineHeight: '24px' }}>
                ≈ ${totalUSD ?? 0}{' '}
                <QuestionHelper
                  styles={{ position: 'relative', top: '2px', width: '400px' }}
                  color="#303030"
                  text={
                    <div>
                      <div style={{ fontSize: '14px' }}>
                        {`${new BN(tokenAmount)?.toFixed(3, 1)}${farm.token.symbol} ≈ $${new BN(tokenAmount)
                          .multipliedBy(tokenPriceUsd)
                          ?.toFixed(2, 1)}`}
                      </div>
                      <div style={{ fontSize: '14px' }}>
                        {new BN(quoteTokenAmount).toFixed(3, 1)}
                        {farm.quoteToken.symbol} ≈ $
                        {new BN(quoteTokenAmount).multipliedBy(quoteTokenPriceUsd)?.toFixed(3, 1)}
                      </div>
                    </div>
                  }
                  size={18}
                  infoStyles={{ width: '18px', height: '18px' }}
                />
              </Text>
            </div>
            <IconButtonWrapper>
              <IconButton
                onClick={onPresentWithdraw}
                mr="6px"
                style={{
                  background: theme.colors.primary,
                  borderRadius: '50%',
                  border: 'none',
                }}
              >
                <MinusIcon color="#fff" width="20px" />
              </IconButton>
              <MouseoverTooltip placement="top" text={farm?.hasMove ? t('FARM_51') : ''}>
                <IconButton
                  onClick={() => {
                    if (!farm?.hasMove) {
                      onPresentDeposit()
                    }
                  }}
                  // disabled={farm?.hasMove ?? false}
                  style={{
                    background: farm?.hasMove ? '#E6E7E8' : theme.colors.primary,
                    borderRadius: '50%',
                    border: 'none',
                    cursor: farm?.hasMove ? 'not-allowed' : 'pointer',
                  }}
                >
                  <AddIcon color="#fff" width="20px" />
                </IconButton>
              </MouseoverTooltip>
            </IconButtonWrapper>
          </ActionContent>
        </ActionContainer>
      )
    }

    return (
      <ActionContainer>
        <ActionTitles>
          <Subtle>{t('FARM_52')} </Subtle>
          <Title>{lpSymbol}</Title>
        </ActionTitles>
        <ActionContent>
          <TableFarmConfirmButton disabled={farm?.hasMove ?? false} width="100%" onClick={onPresentDeposit}>
            {t('FARM_53')}
          </TableFarmConfirmButton>
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>
        <Subtle>{t('FARM_54')}</Subtle>
      </ActionTitles>
      <ActionContent>
        <TableFarmConfirmButton width="100%" disabled={requestedApproval || farm?.hasMove} onClick={handleApprove}>
          {t('FARM_55')}
        </TableFarmConfirmButton>
      </ActionContent>
    </ActionContainer>
  )
}

export default Staked
