// @ts-nocheck
import { useWeb3React } from '@web3-react/core'
import BN from 'bignumber.js'
import Meta from 'components/Meta'
import React from 'react'
import CountUp from 'react-countup'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Text } from '../../uikit'
import FarmV2 from './FarmsV2'

import farmbg from 'assets/images/farm/farm_bg.jpg'
import { harvestAllFarms, useFarmTotalEarns, useFetchPublicData, useToast } from 'state/hooks'
import { useTransactionAdder } from '../../state/transactions/hooks'

const Header = styled.div`
  background: url(${farmbg}) top center no-repeat;
  background-position: -600px 0;
  background-size: auto 100%;
  height: auto;
  color: #fff;
  padding-bottom: 60px;
  padding-top: 60px;
  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 0px;
    background-position: 0 0;
    background-size: 100% 100%;
    padding-bottom: 0px;
    height: 300px;
    padding-left: 40px;
    padding-right: 36px;
  }
`

const FarmWrap = styled.div`
  background: #000;
  min-height: 100vh;
`

export const HeaderContainer = styled.div`
  height: auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  color: #fff;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  ${({ theme }) => theme.mediaQueries.md} {
    height: 300px;
    padding-top: 76px;
    padding-left: 0px;
    padding-right: 0px;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
  }
`

const HarvestButton = styled.div<{ disabled: boolean }>`
  height: 40px;
  left: 0px;
  top: 0px;
  background-image: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 11px 32px;
  position: relative;
  z-index: 0;
  cursor: ${({ disabled }) => {
    if (disabled) {
      return 'not-allowed'
    }
    return 'pointer'
  }};
  pointer-events: ${({ disabled }) => {
    if (disabled) {
      return 'none'
    }
    return 'default'
  }};

  &::before {
    background-image: ${({ disabled }) => {
      if (disabled) {
        return '#EFEFF2'
      }
      return 'linear-gradient(271.45deg, #ff3093 3.84%, #5218f4 94.54%)'
    }};
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 30px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.5s ease-out;
  }
  &:hover:before {
    opacity: 1;
  }
`

const ProfitPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  padding: 30px 32px;
  width: 360px;
  height: 180px;
  position: relative;
  z-index: 1;
  align-items: flex-start;
  margin-top: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.4) 100%);
  border-radius: 16px;
  @media (max-width: 768px) {
    align-items: center;
    margin-top: 40px;
    width: 96%;
    max-width: 360px;
  }
`

const Index: React.FunctionComponent = () => {
  const { account, library } = useWeb3React()
  const { toastInfo } = useToast()
  const transactionAdder = useTransactionAdder()
  const { t } = useTranslation()

  useFetchPublicData()

  const allEarnings = useFarmTotalEarns()

  const harvestAllInFarm = () => {
    if (new BN(allEarnings ?? 0).gte(0.0001)) {
      harvestAllFarms(account as string, library, transactionAdder)
    } else {
      toastInfo(t('FARM_5'), t('FARM_6'))
    }
  }

  return (
    <FarmWrap>
      <Meta title={t('FARM_94')} />
      <Header>
        <HeaderContainer style={{ alignItems: isMobile ? 'center' : 'flex-start' }}>
          <div style={{ position: 'relative', zIndex: 1, marginTop: '40px' }}>
            <Text
              color="#fff"
              fontSize={isMobile ? '36px' : '40px'}
              fontWeight={700}
              style={{ letterSpacing: '4px', textAlign: isMobile ? 'center' : 'left' }}
            >
              {t('FARM_1')}
            </Text>
            <Text
              color="#fff"
              fontSize="14px"
              fontWeight={500}
              style={{
                width: isMobile ? '100%' : '590px',
                textAlign: isMobile ? 'center' : 'left',
                letterSpacing: isMobile ? '2px' : '0px',
                marginTop: isMobile ? '5px' : '0',
              }}
            >
              {t('FARM_2')}
            </Text>
          </div>
          <ProfitPanel>
            <Text
              fontSize="14px"
              fontWeight={400}
              color="#fff"
              fontFamily="Poppins"
              style={{ marginTop: '8px' }}
              lineHeight="21px"
            >
              {t('FARM_3')}
            </Text>
            <Text
              fontSize="35px"
              style={{ marginTop: '4px' }}
              fontWeight={800}
              color="#fff"
              fontFamily="Poppins"
              lineHeight="126%"
            >
              ${' '}
              <CountUp
                duration={1}
                start={0}
                decimals={2}
                formattingFn={(n) => {
                  return new BN(n).toFormat({ groupSeparator: ',', groupSize: 3, decimalSeparator: '.' })
                }}
                end={new BN(new BN(allEarnings).gt(0) ? allEarnings : 0).toNumber()}
              />
            </Text>

            <HarvestButton onClick={harvestAllInFarm} disabled={!account || !allEarnings}>
              <Text fontSize="16px" fontWeight={500} fontFamily="Poppins" color="#fff">
                {t('FARM_4')}
              </Text>
            </HarvestButton>
          </ProfitPanel>
        </HeaderContainer>
      </Header>
      <FarmV2 />
    </FarmWrap>
  )
}

export default Index
