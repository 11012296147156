import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal, TuneIcon, HistoryIcon } from '../../uikit'
import useI18n from 'hooks/useI18n'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'
import { useTranslation } from 'react-i18next'

interface PageHeaderProps {
  title?: ReactNode
  description?: ReactNode
  children?: ReactNode
}

const StyledPageHeader = styled.div`
  padding: 28px 32px 24px 32px;
`

const Details = styled.div`
  flex: 1;
`

const PageHeader = ({ title, children }: PageHeaderProps) => {
  const TranslateString = useI18n()
  const { t } = useTranslation()
  const [onPresentSettings] = useModal(<SettingsModal translateString={TranslateString} />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal translateString={TranslateString} />)

  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading style={{ fontSize: '20px', fontFamily: 'Poppins', fontWeight: 500 }}>{title}</Heading>
        </Details>
        <>
          <IconButton variant="text" height="24px" onClick={onPresentRecentTransactions} title={t('COMPONENTS_179')}>
            <HistoryIcon width="24px" color="currentColor" />
          </IconButton>
          <IconButton
            variant="text"
            height="24px"
            style={{ width: 'auto', marginLeft: '12px' }}
            onClick={onPresentSettings}
            title={t('COMPONENTS_52')}
          >
            <TuneIcon width="24px" color="currentColor" />
          </IconButton>
        </>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
