import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Text } from '../../../components/Text'
import { Colors } from '../../../theme/types'
import { MENU_ENTRY_HEIGHT } from '../config'

export interface Props {
  secondary?: boolean
  isActive?: boolean
  canClick?: boolean
  theme: DefaultTheme
  isSecond?: boolean
}

const LinkLabel = styled.div<{ isPushed: boolean; isActive: boolean }>`
  transition: color 0.4s;
  flex-grow: 1;
  white-space: nowrap;
`

const MenuEntry = styled.div<Props>`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: ${({ secondary }) => (secondary ? '0px 32px' : '0px')};
  padding-left: 0px;
  font-size: ${({ secondary }) => (secondary ? '16px' : '16px')};
  font-weight: 500;
  background-color: ${({ secondary, isActive }) => (secondary && isActive ? '#F1F1F1' : 'transparent')};
  color: white;
  padding: 0 16px;
  cursor: ${({ canClick }) => {
    if (canClick) {
      return 'pointer'
    }
    return 'default'
  }};
  pointer-events: ${({ canClick }) => {
    if (canClick) {
      return ''
    }
    return 'none'
  }};

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 8px;
    color: white;
  }

  svg {
    fill: white;
  }

  &:hover {
    background-color: #000;
  }

  // Safari fix
  flex-shrink: 0;
`
MenuEntry.defaultProps = {
  secondary: false,
  isActive: false,
}

const LinkStatus = styled(Text)<{ color: keyof Colors }>`
  height: 20px;
  border-radius: 20px;
  background: ${({ theme, color }) => theme.colors[color]};
  box-shadow: none;
  color: #fff;
  margin-left: 8px;
  margin-right: 20px;
  font-size: 12px;
  padding: 0 6px;
  line-height: 20px;
  font-weight: 700;
`

const LinkLabelMemo = React.memo(LinkLabel, (prev, next) => prev.isPushed === next.isPushed)

export { MenuEntry, LinkStatus, LinkLabelMemo as LinkLabel }
