import { useResponsive } from 'hooks/useResponse'
import React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { Box, Flex } from 'uikit'

export type Status = 'past' | 'current' | 'future'
export type Type = 'row' | 'column'

export interface ThemedProps {
  theme: DefaultTheme
}

export interface StatusProps extends ThemedProps {
  theme: DefaultTheme
  status?: Status
  $isFirstStep?: boolean
  $isLastStep?: boolean
  $isFirstPart?: boolean
  type?: Type
}

export interface StepProps {
  index: number
  statusFirstPart: Status
  numberOfSteps?: number
  type?: Type
}

const getStepNumberFontColor = ({ theme, status }: StatusProps) => {
  if (status === 'past') {
    return 'white'
  }
  if (status === 'current') {
    return theme.colors.invertedContrast
  }
  return theme.colors.textDisabled
}

const StyledStep = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.md} {
    justify-content: flex-start;
  }
`

const Connector = styled.div<StatusProps>`
  position: absolute;
  height: ${({ $isLastStep, $isFirstStep }) => ($isLastStep ? '0' : $isFirstStep ? '60%' : '60%')};
  ${({ $isLastStep }) => {
    if (!$isLastStep) return 'top: 20%;'
    return 'display: none;'
  }}
  ${({ theme }) => theme.mediaQueries.md} {
    height: ${({ $isLastStep }) => ($isLastStep ? '0' : '60%')};
    ${({ $isFirstStep, $isLastStep }) => {
      if ($isFirstStep) return 'top: 25%;'
      if (!$isLastStep) return 'top: 30%;'
      return 'top: 0;'
    }}
  }
  left: calc(50% - 2px);
  border: 1px dashed
    ${({ theme, status }) => theme.colors[status === 'past' || status === 'current' ? 'primary' : 'borderDark']};
`

const ConnectorRow = styled.div<StatusProps>`
  position: relative;
  width: ${({ $isLastStep }) => ($isLastStep ? '0' : '50px')};
  margin: ${({ $isLastStep }) => ($isLastStep ? '0' : '0 15px')};
  border: ${({ $isLastStep }) => ($isLastStep ? '0' : '1px')} dashed
    ${({ theme, status }) => theme.colors[status === 'past' || status === 'current' ? 'primary' : 'borderDark']};
  ${({ theme }) => theme.mediaQueries.md} {
    width: ${({ $isLastStep }) => ($isLastStep ? '0' : '140px')};
    margin: ${({ $isLastStep }) => ($isLastStep ? '0' : '0 10px')};
  }
`

const ChildrenWrapper = styled(Box)<{ isVisible: boolean }>`
  ${({ theme }) => theme.mediaQueries.md} {
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  }
`

const ChildrenRightWrapper = styled(ChildrenWrapper)<{
  type?: Type
  $isLastStep?: boolean
  $isFirstStep?: boolean
  status?: Status
}>`
  display: flex;
  ${({ type, $isFirstStep, $isLastStep }) => {
    if (type === 'row' && $isFirstStep) return 'text-align: center; display: block;'
    if (type === 'row' && $isLastStep) return 'text-align: center; position: relative; left: -70px; display: block;'
    if (type === 'row' && !$isLastStep) return 'text-align: center; position: relative; left: -15px; display: block;'
    return null
  }}
  margin-left: ${({ type, $isLastStep, $isFirstStep }) =>
    type === 'row' && !$isLastStep ? ($isFirstStep ? '25%' : '0') : $isLastStep ? '0px' : '8px'};
  max-width: 110px;
  margin-top: ${({ $isLastStep }) => ($isLastStep ? '-20px' : '-10px')};
  flex: 1;
  ${({ theme }) => theme.mediaQueries.md} {
    max-width: 100%;
    margin-top: ${({ $isLastStep }) => ($isLastStep ? '-10px' : '0px')};
    margin-left: ${({ type, $isLastStep }) => (type === 'row' && !$isLastStep ? '0' : $isLastStep ? '-15%' : '16px')};
    ${({ type, $isFirstStep }) => {
      if (type === 'row' && $isFirstStep) return 'text-align: center; display: block;'
      if (type === 'row' && !$isFirstStep)
        return 'text-align: center; position: relative; left: -155px; display: block;'
      return null
    }}
  }
  ${({ type, status }) => {
    if (type === 'row' && status === 'current') return 'color: #01142A;'
    if (type === 'row' && status !== 'current') return 'color: #C9C9C9;'
    return 'color: #01142A;'
  }}// border: 2px solid ${({ status }) => (status === 'future' ? '#E9EAED' : 'transparent')};
`

const Wrapper = styled.div<{ status: Status }>`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ status }) => (status === 'current' ? '48px' : '48px')};
  @media (max-width: 768px) {
    width: 32px;
  }
`

const ColContainer = styled.div`
  position: relative;
  width: auto;
`

export const StepNumber = styled.div<StatusProps>`
  background: #ff3093;
  border: 2px solid ${({ status }) => (status === 'future' ? 'transparent' : 'transparent')};
  border-radius: 60px;
  color: ${getStepNumberFontColor};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  font-size: 16px;
  width: 32px;
  height: 32px;
  z-index: 5;
  position: ${({ type }) => (type === 'row' ? 'relative' : 'absolute')};
  top: -3%;
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 24px;
    width: ${({ status, type }) => (status === 'current' && type !== 'row' ? '48px' : '48px')};
    height: ${({ status, type }) => (status === 'current' && type !== 'row' ? '48px' : '48px')};
  }
`

/**
 * ChildrenLeftWrapper and ChildrenRightWrapper are used on the non mobile version, to force the alternate layout.
 * One of the child is hidden based on the step number.
 */
export const Stepper: React.FC<StepProps> = ({
  index,
  statusFirstPart,
  numberOfSteps = 0,
  children,
  type = 'column',
}) => {
  const isFirst = index === 0
  const isLast = index === numberOfSteps - 1
  const { isMobile } = useResponsive()

  if (type === 'row') {
    return (
      <ColContainer>
        <Flex
          alignItems="center"
          style={{
            marginBottom: '16px',
            position: 'relative',
            left: isFirst ? '0' : window.innerWidth < 768 ? '-70px' : '-155px',
          }}
        >
          <ConnectorRow
            $isFirstStep={isFirst}
            $isLastStep={isLast}
            status={statusFirstPart}
            style={{ visibility: 'hidden' }}
          />
          {/* {statusFirstPart === 'past' ? (
            <StepFinished
              status={statusFirstPart}
              alignItems="center"
              justifyContent="center"
              style={{ position: 'relative' }}
            >
              <StepFinishedImg src={require('assets/images/launchpad/success.svg').default} />
            </StepFinished>
          ) : (
            <StepNumber status={statusFirstPart} type={type}>
              {index + 1}
            </StepNumber>
          )} */}
          <StepNumber status={statusFirstPart} type={type}>
            {index + 1}
          </StepNumber>
          <ConnectorRow $isFirstStep={isFirst} $isLastStep={isLast} status={statusFirstPart} />
        </Flex>
        <ChildrenRightWrapper
          isVisible={true}
          status={statusFirstPart}
          type={type}
          $isFirstStep={isFirst}
          $isLastStep={isLast}
        >
          {children}
        </ChildrenRightWrapper>
      </ColContainer>
    )
  }

  return (
    <StyledStep mb={index < numberOfSteps - 1 ? '16px' : 0}>
      <Wrapper status={statusFirstPart}>
        {/* {statusFirstPart === 'past' ? (
          <StepFinished status={statusFirstPart} alignItems="center" justifyContent="center">
            <StepFinishedImg src={require('assets/images/launchpad/success.svg').default} />
          </StepFinished>
        ) : (
          <StepNumber status={statusFirstPart}>{index + 1}</StepNumber>
        )} */}
        <StepNumber status={statusFirstPart}>{index + 1}</StepNumber>

        <Connector $isFirstStep={isFirst} $isLastStep={isLast} status={statusFirstPart} />
      </Wrapper>
      <ChildrenRightWrapper
        isVisible={true}
        $isFirstStep={isFirst}
        $isLastStep={isLast}
        style={{ maxWidth: isMobile ? '85%' : '100%', marginLeft: isMobile ? '12px' : '20px' }}
      >
        {children}
      </ChildrenRightWrapper>
    </StyledStep>
  )
}
