import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from 'shardex-sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import WETH_ABI from '../constants/abis/weth.json'
import { MULTICALL_ABI, MULTICALL_ADDRESS } from '../constants/multicall'
import { getContract } from '../utils'
import { useActiveWeb3React } from './index'
import useWeb3 from 'hooks/useWeb3'

import {
  getKrc20Contract,
  getProjectTokenContract,
  getMasterchefContract,
  getMasterchefV2Contract,
  getSouschefContract,
  getPointCenterIfoContract,
  getIfoV2Contract,
  getMojitoFactoryContract,
  getMojitoCattleContract,
  getErc721Contract,
  getProfileContract,
  getNftMarketContract,
  getErc721CollectionContract,
  getLuckyDrawResultContract,
  getIfoV2WhiteListContract,
  getRollingContract,
  getSBTContract,
} from 'utils/contractHelpers'
import { getVaultContract, getVaultV2Contract, getRouterContract } from '../utils/contractHelpers'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_ADDRESS[chainId], MULTICALL_ABI, false)
}

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getKrc20Contract(address, web3), [address, web3])
}

export const useSDT = () => {
  const web3 = useWeb3()
  return useMemo(() => getProjectTokenContract(web3), [web3])
}

export const useMasterchef = () => {
  const web3 = useWeb3()
  return useMemo(() => getMasterchefContract(web3), [web3])
}

export const useMasterchefV2 = () => {
  const web3 = useWeb3()
  return useMemo(() => getMasterchefV2Contract(web3), [web3])
}

export const useSousChef = (id) => {
  const web3 = useWeb3()
  return useMemo(() => getSouschefContract(id, web3), [id, web3])
}

export const useVaultChef = () => {
  const web3 = useWeb3()
  return useMemo(() => getVaultContract(web3), [web3])
}

export const useVaultChefV2 = () => {
  const web3 = useWeb3()
  return useMemo(() => getVaultV2Contract(web3), [web3])
}

export const useRolling = () => {
  const web3 = useWeb3()
  return useMemo(() => getRollingContract(web3), [web3])
}

export const usePointCenterIfoContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getPointCenterIfoContract(library), [library])
}

export const useIfoV2Contract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV2Contract(address, library), [address, library])
}

export const useIfoV2WhiteListContract = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getIfoV2WhiteListContract(address, library), [address, library])
}

export const useCattleFactory = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMojitoFactoryContract(library), [library])
}

export const useMojitoCattle = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getMojitoCattleContract(library), [library])
}

export const useERC721 = (address: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getErc721Contract(address, library), [address, library])
}

export const useProfile = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getProfileContract(library), [library])
}

export const useNftMarketContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getNftMarketContract(library), [library])
}

export const useErc721CollectionContract = (collectionAddress: string) => {
  const { library } = useActiveWeb3React()
  return useMemo(() => {
    return getErc721CollectionContract(library, collectionAddress)
  }, [library, collectionAddress])
}

export const useLuckyDrawResultContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getLuckyDrawResultContract(library), [library])
}

export const useSBTContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getSBTContract(library), [library])
}

export const useRouterContract = () => {
  const { library } = useActiveWeb3React()
  return useMemo(() => getRouterContract(library), [library])
}
