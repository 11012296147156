import React, { useState, useEffect } from 'react'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import LinkExternal from '../../components/Link/LinkExternal'
import Row, { RowBetween } from 'components/Row'
import Flex from '../../components/Box/Flex'
import { Modal } from '../Modal'
import { connectorLocalStorageKey } from './config'
import { isMobile } from 'react-device-detect'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { getKCCExplorerLink } from 'utils'
import Loader from 'components/Loader'
import { Box, CheckmarkCircleIcon, ErrorIcon } from 'uikit'
import { useActiveWeb3React } from 'hooks'
import styled, { useTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { clearAllTransactions } from 'state/transactions/actions'
import copy from 'copy-to-clipboard'
import { useToast } from 'state/hooks'
import { useProjectTokenBalance } from 'hooks/useTokenBalance'
import BN from 'bignumber.js'
import { useGetKcsBalance } from 'hooks/useTokenBalance'
import Tooltip from 'components/Tooltip'
import { useTranslation } from 'react-i18next'
import { ETHER, ChainId } from 'shardex-sdk'
import { projectToken } from '../../../constants/migrate'
import Tabs, { TabPane } from 'rc-tabs'
import 'rc-tabs/assets/index.css'
import tokens from 'constants/tokens'
import CurrencyLogo from 'components/CurrencyLogo'

interface Props {
  account: string
  logout: () => void
  onDismiss?: () => void
}

const ClearAll = styled(Text)`
  cursor: pointer;
`

const CellContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(115, 126, 141, 0.16);
  margin-top: 12px;
`

const Lines = styled.div`
  background: rgba(115, 126, 141, 0.16);
  height: 1px;
  width: 100%;
  margin: 10px 0;
`

const WalletContainer = styled.div`
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
`

const ImgCopy = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const ImgTrash = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  margin-right: 4px;
`

const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => b.addedTime - a.addedTime

const getRowStatus = (sortedRecentTransaction: TransactionDetails, chainId?: any) => {
  const { hash, receipt } = sortedRecentTransaction

  // now - addTime > 15s && !confirmTime

  if (!receipt) {
    return {
      icon: (
        <Box style={{ cursor: 'pointer' }}>
          <Loader stroke="#001425" />
        </Box>
      ),
      color: 'text',
      url: '',
    }
  }

  if (hash && receipt?.status === 1) {
    return {
      icon: <CheckmarkCircleIcon color="#14D89D" />,
      color: '#494E67',
      url: getKCCExplorerLink(chainId, hash, 'transaction'),
    }
  }

  return {
    icon: <ErrorIcon color="#FF8F2C" />,
    color: '#494E67',
    url: getKCCExplorerLink(chainId, hash, 'transaction'),
  }
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => {
  const { chainId, account: activeAccount } = useActiveWeb3React()
  const allTransactions = useAllTransactions()
  const { balance: SDTBalance } = useProjectTokenBalance()
  const { balance: kcsBalance } = useGetKcsBalance()
  const [tab, setTab] = useState(0)
  const theme: any = useTheme()
  const { toastInfo } = useToast()
  const [show, setShow] = useState<boolean>(false)
  const { t } = useTranslation()

  const sortedRecentTransactions = React.useMemo(() => {
    const maxLength = 20
    const txs = Object.values(allTransactions) as any
    const list = txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    if (list.length > maxLength) {
      list.splice(maxLength, list.length - maxLength)
    }
    return list
  }, [allTransactions])

  const dispatch = useDispatch()

  const clearAllTransaction = () => {
    dispatch(clearAllTransactions({ chainId: chainId ?? ChainId.MAINNET }))
  }

  const clickListener = (e) => {
    const target = window.event || e
    const parentApp = document.getElementById('accountTip')
    if (parentApp && !parentApp.contains(target?.target as any)) {
      setShow(false)
    }
  }
  useEffect(() => {
    window.addEventListener('mousedown', clickListener)
    return () => window.removeEventListener('mousedown', clickListener)
  })

  const Tips = (
    <>
      <Text color={theme.colors.textRemark} fontSize="14px">
        {t('UIKIT_15', { symbol: ETHER.symbol })}
      </Text>
      <LinkExternal
        size="12px"
        fontSize="14px"
        style={{ fontSize: '14px', fontWeight: 500 }}
        small
        href="https://docs.shardex.org/get-start/lets-get-some-usdshm"
        target="_self"
        mr="16px"
      >
        <span style={{ color: '#2BCAA0', fontSize: '14px' }}>{t('UIKIT_5', { symbol: ETHER.symbol })}</span>
      </LinkExternal>
    </>
  )

  const renderTabWallet = () => {
    return (
      <>
        <Text fontSize="16px" mt="20px">
          {t('UIKIT_6')}
        </Text>
        <CellContainer>
          <Text
            color={theme.colors.textRemark}
            fontSize="16px"
            maxWidth={isMobile ? '300px' : '100%'}
            padding="16px 20px"
            lines={1}
            ellipsis
            style={{ borderBottom: '1px solid rgba(115, 126, 141, 0.16)', display: 'block' }}
          >
            {activeAccount}
          </Text>
          <Text
            fontSize="14px"
            color="primary"
            margin="10px auto"
            textAlign="center"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              copy(activeAccount)
              toastInfo(t('UIKIT_14'))
            }}
          >
            Copy Address to Clipboard
          </Text>
        </CellContainer>
        <Text fontSize="16px" mt="24px">
          {t('UIKIT_7')}
        </Text>
        <WalletContainer>
          <Lines />
          <RowBetween>
            <Flex alignItems="center">
              <CurrencyLogo currency={ETHER} size="24px" />
              <Text color={theme.colors.textRemark} fontSize="14px" ml="12px">
                {ETHER.symbol}
              </Text>
            </Flex>

            <Row style={{ width: 'auto' }}>
              <Text color={theme.colors.textRemark} fontSize="14px">
                {new BN(kcsBalance.toString()).div(10 ** 18).toFixed(2, 1)}
              </Text>
              {new BN(kcsBalance.toString()).div(10 ** 18).lt(2) && (
                <Tooltip text={Tips} show={show} placement="top">
                  <ImgCopy
                    src={require('assets/images/menu/warning.svg').default}
                    style={{ marginLeft: '4px' }}
                    onClick={() => {
                      setShow(!show)
                    }}
                    id="accountTip"
                  />
                </Tooltip>
              )}
            </Row>
          </RowBetween>
          <Lines />
          <RowBetween>
            <Flex alignItems="center">
              <CurrencyLogo currency={tokens.sdt} size="24px" />
              <Text color={theme.colors.textRemark} fontSize="14px" ml="12px">
                {projectToken.symbol}
              </Text>
            </Flex>
            <Text color={theme.colors.textRemark} fontSize="14px">
              {new BN(SDTBalance.toString()).div(10 ** 18).toFixed(2, 1)}
            </Text>
          </RowBetween>
          <Lines />
          {/* <RowBetween>
            <Text color={theme.colors.textRemark} fontSize="14px">
              NFT
            </Text>
            <Text color={theme.colors.textRemark} fontSize="14px">
              {userNftsInitializationState === UserNftInitializationState.INITIALIZED ? nfts.length : '-'}
            </Text>
          </RowBetween> */}
        </WalletContainer>
        <LinkExternal
          style={{ fontSize: '16px', fontWeight: 500, margin: '0 auto' }}
          small
          href={`${process.env.REACT_APP_EXPLORER_URL}/${account}`}
          mr="16px"
        >
          <span style={{ color: '#FF3093' }}>{t('UIKIT_8')}</span>
        </LinkExternal>
        <Flex justifyContent="center">
          <Button
            scale="md"
            style={{
              width: '100%',
              margin: '30px 0 10px 0',
              color: '#F5455B',
              background: 'white',
              border: '1px solid #F5455B',
              fontWeight: '500',
            }}
            onClick={() => {
              logout()
              window.localStorage.removeItem(connectorLocalStorageKey)
              onDismiss()
            }}
          >
            {t('UIKIT_9')}
          </Button>
        </Flex>
      </>
    )
  }

  const renderTransaction = () => {
    return (
      <>
        {account && chainId && sortedRecentTransactions.length === 0 && (
          <>
            <Text mb="8px" mt="30px">
              {t('UIKIT_10')}
            </Text>
            <Text color={theme.colors.borderDark} mt="40px" mb="100px" textAlign="center">
              {t('UIKIT_11')}
            </Text>
          </>
        )}

        {account && chainId && sortedRecentTransactions.length > 0 && (
          <Flex
            justifyContent="space-between"
            style={{ width: '100%' }}
            flexDirection="row"
            alignItems="center"
            mt="30px"
            mb="10px"
          >
            <Text mb="8px" textAlign="left">
              {t('UIKIT_10')}
            </Text>
            <Flex alignItems="center" mb="8px">
              <ImgTrash src={require('assets/images/icon/trash.svg').default} />
              <ClearAll color="#494E67" textAlign="left" onClick={clearAllTransaction}>
                {t('UIKIT_12')}
              </ClearAll>
            </Flex>
          </Flex>
        )}
        {account &&
          chainId &&
          sortedRecentTransactions.map((sortedRecentTransaction, index) => {
            const { hash, summary } = sortedRecentTransaction
            const { icon, color, url } = getRowStatus(sortedRecentTransaction, chainId)

            return (
              <>
                <Flex key={`${hash}${index + 1}`} alignItems="center" justifyContent="space-between" mb="10px">
                  <Text color={color}>
                    <LinkExternal style={{ fontSize: '14px', fontWeight: '500' }} href={url} color={color}>
                      {summary ?? hash}
                    </LinkExternal>
                  </Text>
                  {icon}
                </Flex>
              </>
            )
          })}
      </>
    )
  }

  return (
    <Modal
      title={t('UIKIT_13')}
      minWidth={isMobile ? '96%' : '440px'}
      onDismiss={onDismiss}
      headerStyle={{ border: 'none' }}
    >
      {/* <Flex justifyContent="center">
        <AccountTab
          tab={tab}
          clickTab={(index) => {
            setTab(index)
          }}
        />
      </Flex> */}
      <Tabs
        defaultActiveKey="Wallet"
        style={{ marginTop: '-20px' }}
        onChange={(val) => {
          if (val === 'Wallet') {
            setTab(0)
          } else {
            setTab(1)
          }
        }}
      >
        {[t('UIKIT_16'), t('UIKIT_17')].map((item) => {
          return <TabPane tab={item} key={item} />
        })}
      </Tabs>
      {tab === 0 ? renderTabWallet() : renderTransaction()}
    </Modal>
  )
}

export default AccountModal
