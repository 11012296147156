import styled from 'styled-components'
import { Text } from '../../uikit'

const Bg = require('assets/images/referral/bg.webp').default

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;

  width: 100%;
  padding: 16px;

  background: url(${Bg}) top center no-repeat, #fff;
  background-size: cover;

  ${({ theme }) => theme.mediaQueries.lg} {
    background-size: cover;
    min-height: 100vh;
  }
`

export const ReferralDecorate = styled.div`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  height: 3px;
  width: 1200px;
  margin-top: 80px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

export const ReferralContent = styled.div`
  width: 1200px;
  background: #000000cc;
  padding: 70px 140px 70px 64px;
  margin: 0 auto 100px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 48px 28px;
  }
`

export const ReferralTitle = styled(Text)`
  background: linear-gradient(91.57deg, #ff3093 1.17%, #5218f4 99.12%);
  color: transparent;
  background-clip: text;
  font-size: 64px;
  font-weight: 700;
  text-fill-color: transparent;
`

export const ReferralDot = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 10px;
  background: #ff3093;
`

export const Line = styled.div`
  border: 0.5px dashed #ff3093;
  height: 443px;
  width: 0px;
  margin: 10px 0;
  @media (max-width: 768px) {
    height: 737px;
  }
`
