import { FarmWithStakedValue } from 'pages/Farms/components/FarmCard/FarmCard'
import React from 'react'
import styled, { useTheme } from 'styled-components'
import { LinkExternal, Text } from 'uikit'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'

import { useTranslation } from 'react-i18next'
import { useBlockNumber } from 'state/application/hooks'
import { CHAIN_BLOCK_TIME } from '../../../../../constants'
import { getMasterChefAddress, getMasterChefV2Address } from '../../../../../utils/addressHelpers'
import Timer from '../../FarmCard/Timer'
import Apr, { AprProps } from '../Apr'
import Liquidity, { LiquidityProps } from '../Liquidity'
import Multiplier, { MultiplierProps } from '../Multiplier'
import HarvestAction from './HarvestAction'
import StakedAction from './StakedAction'

export interface ActionPanelProps {
  apr: AprProps
  liquidity: LiquidityProps
  details: FarmWithStakedValue
  isMobile: boolean
  multiplier?: MultiplierProps
}

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  padding: 0 24px;

  ${({ theme }) => theme.mediaQueries.lg} {
    flex-direction: row;
    padding: 0px 32px;
  }
`

const StyledLinkExternal = styled(LinkExternal)`
  font-weight: 400;
  padding: 4px 0;
`

const StakeContainer = styled.div`
  color: ${({ theme }) => theme.colors.text};
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.mediaQueries.sm} {
    justify-content: flex-start;
  }
`

const ActionContainer = styled.div`
  display: block;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 130px;

  ${({ theme }) => theme.mediaQueries.sm} {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    height: auto;
    flex-basis: 0;
  }
`

const InfoContainer = styled.div`
  min-width: 180px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
`

const ValueContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQueries.lg} {
    display: none;
  }
`

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px 0px;
`

const ActionPanel: React.FunctionComponent<ActionPanelProps> = ({ details, apr, liquidity, isMobile }) => {
  const farm = details
  const { t } = useTranslation()
  const isActive = farm.multiplier !== '0X'
  const { quoteToken, token } = farm
  const lpLabel = farm.lpSymbol && farm.lpSymbol.toUpperCase().replace('MOJITO', '')
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: quoteToken.address,
    tokenAddress: token.address,
  })
  const lpAddress = farm.lpAddresses[process.env.REACT_APP_CHAIN_ID as string]
  const masterChefAddress = getMasterChefAddress()
  const masterChefV2Address = getMasterChefV2Address()
  const masterChefUrl = `${process.env.REACT_APP_EXPLORER_URL}/address/${
    farm?.version === 2 ? masterChefV2Address : masterChefAddress
  }`
  const info = `/info/pool/${lpAddress}`
  const theme: any = useTheme()

  const currentBlock = useBlockNumber()
  const leftSeconds = farm?.dual?.endBlock ? (farm?.dual?.endBlock - currentBlock) * CHAIN_BLOCK_TIME : -1

  return (
    <Container style={{ padding: isMobile ? '12px' : '0 24px' }}>
      <InfoContainer>
        {isActive && (
          <StakeContainer>
            <StyledLinkExternal
              color="#FF3093"
              href={`${process.env.REACT_APP_OFFICIAL_URL}/add/${liquidityUrlPathParts}`}
            >
              {t('FARM_41', { asset: lpLabel })}
            </StyledLinkExternal>
          </StakeContainer>
        )}
        <StyledLinkExternal color="#FF3093" href={masterChefUrl}>
          {t('FARM_42')}
        </StyledLinkExternal>
        <StyledLinkExternal color="#FF3093" href={info}>
          {t('FARM_43')}
        </StyledLinkExternal>
        {/* {farm?.torchesSupport && (
          <StyledLinkExternal color="#FF3093" href="https://www.torches.finance/en/crypto-bank">
            {t('FARM_104')}
          </StyledLinkExternal>
        )} */}

        {/* <TagsContainer>
          {farm.isCommunity ? <CommunityTag /> : <CoreTag />}
          {dual ? <DualTag /> : null}
        </TagsContainer> */}
        {leftSeconds > 0 ? <Timer leftSeconds={leftSeconds} mode="table" /> : null}
      </InfoContainer>
      <ValueContainer>
        {isMobile ? null : (
          <ValueWrapper>
            <Text>{t('FARM_30')}</Text>
            <Apr {...apr} />
          </ValueWrapper>
        )}
        <ValueWrapper style={{ marginTop: isMobile ? '-5px' : '4px' }}>
          <Text color={theme.colors.textRemark}>{t('FARM_44')}</Text>
          <Multiplier multiplier={farm.multiplier as string} />
        </ValueWrapper>
        <ValueWrapper>
          <Text color={theme.colors.textRemark}>{t('FARM_45')}</Text>
          <Liquidity {...liquidity} />
        </ValueWrapper>
      </ValueContainer>
      <ActionContainer>
        <HarvestAction {...farm} />
        <StakedAction {...farm} />
      </ActionContainer>
    </Container>
  )
}

export default ActionPanel
