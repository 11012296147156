import { ChainId, WETH } from 'shardex-sdk'

const tokens = {
  SHM: {
    symbol: 'SHM',
    projectLink: '',
  },
  wchain: {
    symbol: WETH[ChainId.TESTNET].symbol,
    address: {
      [ChainId.MAINNET]: WETH[ChainId.MAINNET].address,
      [ChainId.TESTNET]: WETH[ChainId.TESTNET].address,
    },
    decimals: WETH[ChainId.MAINNET].decimals,
    projectLink: '',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0xE727dC788270e5b897b71CFC28929a9a016355C6',
    },
    decimals: 18,
    projectLink: '',
  },
  sdt: {
    symbol: 'SDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0x656f8DF327fCC9020894e295E8852D308Fc69D88',
    },
    decimals: 18,
    projectLink: '',
  },
  projectToken: {
    symbol: 'SDT',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0x656f8DF327fCC9020894e295E8852D308Fc69D88',
    },
    decimals: 18,
    projectLink: '',
  },
  test: {
    symbol: 'TEST',
    address: {
      [ChainId.MAINNET]: '0x311253Da81A67699B68874fA4Aff39ecAd54A7Db',
      [ChainId.TESTNET]: '0xc6C027f873184EF583443A132F5D04B7E04B1caf',
    },
    decimals: 18,
    projectLink: '',
  },
  lp: {
    symbol: 'LP',
    address: {
      [ChainId.MAINNET]: '0x017fe8e81389c0acf86f26f53e8bf22fc72d09f2',
      [ChainId.TESTNET]: '0x017fe8e81389c0acf86f26f53e8bf22fc72d09f2',
    },
    decimals: 18,
    projectLink: '',
  },
}

export default tokens
