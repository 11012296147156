import React from 'react'
import Text from '../Text/Text'
import { Flex } from '../Box'
import { SwapVertIcon } from '../Svg'
import { StyledBalanceInput, StyledInput, UnitContainer, SwitchUnitsButton } from './styles'
import { BalanceInputProps } from './types'

const BalanceInput: React.FC<BalanceInputProps> = ({
  value,
  placeholder = '0.0',
  onUserInput,
  currencyValue,
  inputProps,
  innerRef,
  isWarning = false,
  decimals = 18,
  unit,
  switchEditingUnits,
  ...props
}) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.validity.valid) {
      onUserInput(e.currentTarget.value.replace(/,/g, '.'))
    }
  }

  const toThousands = (num = 0) => {
    return num.toString().replace(/\d+/, function (n) {
      return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    })
  }

  return (
    <StyledBalanceInput isWarning={isWarning} {...props}>
      <Flex alignItems="center">
        <StyledInput
          pattern={`^[0-9]*[.,]?[0-9]{0,${decimals}}$`}
          inputMode="decimal"
          min="0"
          value={value}
          ref={innerRef}
          onChange={handleOnChange}
          placeholder={placeholder}
          {...inputProps}
        />
        {unit && <UnitContainer>{unit}</UnitContainer>}
      </Flex>

      {currencyValue && Number(currencyValue) ? (
        <Text fontSize="12px" textAlign="right" color="textSubtle">
          ≈${currencyValue !== 'NaN' ? toThousands(Number(currencyValue)) : '--'}
        </Text>
      ) : (
        ''
      )}
      {switchEditingUnits && (
        <Flex alignItems="center" justifyContent="center" pl="12px">
          <SwitchUnitsButton scale="sm" variant="text" onClick={switchEditingUnits}>
            <SwapVertIcon color="textSubtle" />
          </SwitchUnitsButton>
        </Flex>
      )}
    </StyledBalanceInput>
  )
}

export default BalanceInput
